import { Injectable, signal } from '@angular/core';
import chunk from 'lodash-es/chunk';
import { Observable, map, catchError, of, timeout, from, mergeMap, reduce } from 'rxjs';

import { MutationState, UpdateRequestResponse, UpdateResponse, UpdateState } from '@shure/cloud/shared/models/http';
import { ILogger } from '@shure/shared/angular/utils/logging';

import {
	AssociateTagMutationResult,
	CloudDeviceApiService,
	DissociateTagMutationResult
} from '../api/cloud-device-api.service';

import {
	AssociateTagMutationGQL,
	DeviceRebootMutationGQL,
	DeviceStartIdentifyMutationGQL,
	DeviceStopIdentifyMutationGQL,
	DeviceUpdateFirmwareMutationGQL,
	DeviceUpdateMutationGQL,
	DissociateTagMutationGQL,
	FirmwareUpdateRequestState,
	FirmwareUpdateStage,
	FirmwareUpdateStatus,
	UpdateDeviceFirmwareInput
} from './graphql/generated/cloud-sys-api';
import { SysApiDeviceInventoryApolloCache } from './sys-api-device-inventory-apollo-cache.service';

const defaultMutationTimeout = 15_000;

@Injectable({ providedIn: 'root' })
export class SysApiCloudDeviceApiService extends CloudDeviceApiService {
	private readonly logger: ILogger;
	public override readonly lastFWUpdateTime = signal(0);

	constructor(
		logger: ILogger,
		private readonly deviceStopIdentifyMutationGQL: DeviceStopIdentifyMutationGQL,
		private readonly deviceStartIdentifyMutationGQL: DeviceStartIdentifyMutationGQL,
		private readonly deviceRebootMutationGQL: DeviceRebootMutationGQL,
		private readonly deviceUpdateMutationGQL: DeviceUpdateMutationGQL,
		private readonly deviceUpdateFirmwareMutationGQL: DeviceUpdateFirmwareMutationGQL,
		private readonly associateTagMutationGQL: AssociateTagMutationGQL,
		private readonly dissociateTagMutationGQL: DissociateTagMutationGQL,
		private readonly inventoryApolloCache: SysApiDeviceInventoryApolloCache
	) {
		super();

		this.logger = logger.createScopedLogger('DaiCloudDeviceService');
	}

	/**
	 * Toggle identify for a device.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>> {
		this.logger.trace('toggleIdentify', 'Toggling identify', { deviceId, identify });
		/* eslint-disable */
		// disabling eslint for this section because no matter how it was formatted, eslint wasn't happy.
		const mutation$ = identify
			? this.deviceStartIdentifyMutationGQL
					.mutate({ startIdentifyId: deviceId })
					.pipe(map((result) => result.data?.startIdentify.error))
			: this.deviceStopIdentifyMutationGQL
					.mutate({ stopIdentifyId: deviceId })
					.pipe(map((result) => result.data?.stopIdentify.error));
		/* eslint-enable */

		return mutation$.pipe(
			timeout(defaultMutationTimeout),
			map((error) => {
				// If error is null, the request was a success.
				return {
					state: !error ? UpdateState.Done : UpdateState.Error
				};
			}),
			catchError((error: Error) => {
				this.logger.error('toggleIdentify', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	/**
	 * Set mute for a device.
	 * @param deviceId
	 * @param deviceMute
	 * @returns
	 */
	public setMute(deviceId: string, deviceMute: boolean): Observable<UpdateRequestResponse<string>> {
		this.logger.trace('setMute', 'Setting mute', { deviceId, deviceMute });
		return this.deviceUpdateMutationGQL
			.mutate({
				updates: [
					{
						device: {
							features: {
								audioMute: {
									muted: deviceMute
								}
							},
							id: deviceId
						}
					}
				]
			})
			.pipe(
				timeout(defaultMutationTimeout),
				map((result) => {
					if (
						result.data?.updateNodes &&
						'error' in result.data.updateNodes[0] &&
						result.data.updateNodes[0].error !== null
					) {
						this.logger.error('setMute', 'Error', {
							deviceId,
							result
						});
						return { state: UpdateState.Error, error: result.data.updateNodes[0].error?.message };
					}
					this.logger.trace('setMute', 'Done', { deviceId });
					return { state: UpdateState.Done };
				}),
				catchError((error: Error) => {
					this.logger.error('setMute', 'Error', { error });
					return of({ state: UpdateState.Error, error: error.message });
				})
			);
	}

	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setName()', 'Setting name', { deviceId, name });
		return this.deviceUpdateMutationGQL
			.mutate({
				updates: [
					{
						device: {
							features: {
								name: {
									name
								}
							},
							id: deviceId
						}
					}
				]
			})
			.pipe(
				timeout(defaultMutationTimeout),
				map((result) => {
					if (
						result.data?.updateNodes &&
						'error' in result.data.updateNodes[0] &&
						result.data.updateNodes[0].error !== null
					) {
						this.logger.error('setName', 'Error', {
							deviceId,
							result
						});
						return { state: UpdateState.Error, error: result.data.updateNodes[0].error?.message };
					}
					this.logger.trace('setName()', 'Done', { deviceId });
					return { state: UpdateState.Done };
				}),
				catchError((error: Error) => {
					this.logger.error('setName()', 'Error', { error });
					return of({ state: UpdateState.Error, error: error.message });
				})
			);
	}

	/**
	 * Reboot a device
	 * @param deviceId
	 * @returns
	 */
	public rebootDevice$(deviceId: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('rebootDevice()', 'Rebooting device', { deviceId });
		return this.deviceRebootMutationGQL.mutate({ rebootDeviceId: deviceId }).pipe(
			timeout(defaultMutationTimeout),
			map((result) => {
				if (result.data?.rebootDevice.error) {
					this.logger.error('rebootDevice', 'error', result.data?.rebootDevice.error);
					throw new Error(result.data?.rebootDevice.error.message);
				}
				this.logger.trace('rebootDevice', 'Done', { deviceId });
				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('rebootDevice', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	public associateTag(deviceIds: string[], tag: string): Observable<AssociateTagMutationResult> {
		this.logger.trace('associateTag', 'AssociateTag', { deviceIds: deviceIds, tag });
		const concurrency = 10;
		const chunkedDevices = chunk(deviceIds, 50);
		const retVal: AssociateTagMutationResult = {
			state: MutationState.Done,
			errorMessage: undefined,
			resultData: []
		};

		return from(chunkedDevices).pipe(
			mergeMap((chunk) => {
				return this.associateTagMutationGQL
					.mutate({
						tagInput: { devices: chunk, tag: tag }
					})
					.pipe(
						timeout(defaultMutationTimeout),
						map((result) => {
							return {
								state: MutationState.Done,
								errorMessage: undefined,
								resultData: result.data?.associateTag ?? []
							};
						}),
						catchError((error: Error) => {
							return of({
								state: MutationState.Error,
								errorMessage: error.message,
								resultData: []
							});
						})
					);
			}, concurrency),
			reduce((acc, curr) => {
				if (curr.state === MutationState.Error) {
					acc.state = MutationState.Error;
					acc.errorMessage = curr.errorMessage;
				}
				acc.resultData = acc.resultData?.concat(curr.resultData);
				return acc;
			}, retVal)
		);
	}

	public dissociateTag(deviceIds: string[], tag: string): Observable<DissociateTagMutationResult> {
		this.logger.trace('dissociateTag', 'DissociateTag', { deviceIds: deviceIds, tag });
		const concurrency = 10;
		const chunkedDevices = chunk(deviceIds, 50);
		const retVal: DissociateTagMutationResult = {
			state: MutationState.Done,
			errorMessage: undefined,
			resultData: []
		};
		return from(chunkedDevices).pipe(
			mergeMap((chunk) => {
				return this.dissociateTagMutationGQL
					.mutate({
						tagInput: { devices: chunk, tag: tag }
					})
					.pipe(
						timeout(defaultMutationTimeout),
						map((r) => ({
							state: MutationState.Done,
							errorMessage: undefined,
							resultData: r.data?.dissociateTag ?? []
						})),
						catchError((error: Error) => {
							return of({ state: MutationState.Error, errorMessage: error.message, resultData: [] });
						})
					);
			}, concurrency),
			reduce((acc, curr) => {
				if (curr.state === MutationState.Error) {
					acc.state = MutationState.Error;
					acc.errorMessage = curr.errorMessage;
				}
				acc.resultData = acc.resultData?.concat(curr.resultData);
				return acc;
			}, retVal)
		);
	}

	public updateFirmware$(updates: UpdateDeviceFirmwareInput[]): Observable<UpdateResponse<void, string>> {
		this.logger.debug('updateFirmware', 'Requesting updates', updates);

		this.lastFWUpdateTime.set(Date.now());

		return this.deviceUpdateFirmwareMutationGQL.mutate({ input: updates }, { fetchPolicy: 'no-cache' }).pipe(
			timeout(defaultMutationTimeout),
			map((result) => {
				if ('error' in result) {
					throw result.error;
				}

				const { requestId, state: requestState } = result.data?.updateFirmware ?? {
					requestId: null,
					state: null
				};
				if (this.isFirmwareUpdateRequestFailed(requestState)) {
					this.logger.debug('updateFirmware', 'received GraphQL response with error(s)', { result });
					throw new Error(`Update failed with state ${requestState}: ${requestId}`);
				}

				this.logger.debug('updateFirmware', 'Request done', {
					requestId,
					requestState,
					updates
				});

				for (const updateInput of updates) {
					this.inventoryApolloCache.updateFeature(updateInput.id, 'updateProgress', {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						__typename: 'DeviceUpdateProgress',
						updateStage: FirmwareUpdateStage.Pending,
						updateStatus: FirmwareUpdateStatus.Success,
						deviceProgressPercentage: 0,
						stageProgressPercentage: 0
					});
				}

				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('updateFirmware', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	private isFirmwareUpdateRequestFailed(requestState: FirmwareUpdateRequestState | null | undefined): boolean {
		if (requestState === null || requestState === undefined) {
			return true;
		}

		const successStates = [
			FirmwareUpdateRequestState.Pending,
			FirmwareUpdateRequestState.InProgress,
			FirmwareUpdateRequestState.Successful
		];

		return !successStates.includes(requestState);
	}
}
