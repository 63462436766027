import { Inject, Injectable } from '@angular/core';
import { DeepPartial, mergeDeep } from '@apollo/client/utilities';
import { asyncScheduler } from 'rxjs';

import { ApolloCacheFactory, ApolloCacheManager } from '@shure/cloud/shared/apollo';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

import {
	InventoryDeviceByIdQueryDocument,
	InventoryDeviceByIdQueryOpResult,
	InventoryDeviceByIdQueryVariables,
	InventoryDeviceFragment
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceInventoryApolloCache {
	constructor(
		private readonly apolloCacheManager: ApolloCacheManager,
		private readonly apolloCache: ApolloCacheFactory,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {}

	public seedEntry(device: InventoryDeviceFragment): void {
		const variables: InventoryDeviceByIdQueryVariables = {
			nodeId: device.id,
			requestLicenseV3: true
		};
		this.apolloCache.cache?.writeQuery({
			query: InventoryDeviceByIdQueryDocument,
			data: { node: device },
			variables,
			broadcast: false, // significant perf cost if this is TRUE.
			overwrite: true
		});
	}

	public removeEntry(id: string): void {
		// Schedule the cache removal for a short amount of time in the future
		// This allows any watchQueries to get removed w/out reporting errors.
		asyncScheduler.schedule(
			() => this.apolloCacheManager.getApolloCache('sysApi')?.evict({ id: `Device:${id}`, broadcast: false }),
			1000
		);
	}

	public updateFeature<TFeatureKey extends keyof InventoryDeviceFragment['features']>(
		id: string,
		featureKey: TFeatureKey,
		newData: DeepPartial<InventoryDeviceFragment['features'][TFeatureKey]>
	): void {
		const queryVars: InventoryDeviceByIdQueryVariables = {
			nodeId: id,
			requestLicenseV3: this.appEnv.cdmFeatureFlags?.licenseV3 ?? true
		};

		const cachedData = this.apolloCache.cache?.readQuery<
			InventoryDeviceByIdQueryOpResult,
			InventoryDeviceByIdQueryVariables
		>({
			query: InventoryDeviceByIdQueryDocument,
			variables: queryVars
		});

		const cachedDevice = <InventoryDeviceFragment>cachedData?.node;
		if (!cachedDevice) {
			return;
		}

		this.apolloCache.cache?.writeQuery<InventoryDeviceByIdQueryOpResult, InventoryDeviceByIdQueryVariables>({
			query: InventoryDeviceByIdQueryDocument,
			variables: queryVars,
			data: {
				node: mergeDeep(cachedDevice, {
					features: {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						__typename: 'DeviceFeatures',
						[featureKey]: newData
					}
				})
			}
		});
	}
}
