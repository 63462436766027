/* eslint-disable */
/* istanbul ignore file */
/* THIS FILE IS AUTOGENERATED - DO NOT EDIT */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AudioChannelFeatureChange: [
			'AudioChannelAcousticEchoCancellationChange',
			'AudioChannelAcousticEchoCancellationMonoReferenceChange',
			'AudioChannelAcousticEchoCancellationStereoReferenceChange',
			'AudioChannelAnalogInputUseChange',
			'AudioChannelAnalogLevelChange',
			'AudioChannelAutoLevelChange',
			'AudioChannelAutoMuteChange',
			'AudioChannelAutoPositionChange',
			'AudioChannelAutomaticGainControlChange',
			'AudioChannelAutomixSourceSelectorChange',
			'AudioChannelAutomixerChange',
			'AudioChannelAutomixerInputAlwaysOnChange',
			'AudioChannelAutomixerInputChange',
			'AudioChannelAutomixerInputPriorityChange',
			'AudioChannelAutomixerInputSendToMonoMixChange',
			'AudioChannelAutomixerInputSendToStereoMixChange',
			'AudioChannelAutomixerVoiceActivityDetectionChange',
			'AudioChannelCarriedByRfChannelsChange',
			'AudioChannelChannelConfigurationChange',
			'AudioChannelCompressorChange',
			'AudioChannelConnectionDetectionChange',
			'AudioChannelDanteEncryptionStatusChange',
			'AudioChannelDanteRouteReceiverChange',
			'AudioChannelDanteRouteTransmitterChange',
			'AudioChannelDelayChange',
			'AudioChannelDenoiserChange',
			'AudioChannelDeverbChange',
			'AudioChannelDeviceSpecificEqualizerContourChange',
			'AudioChannelDirectOutputTapPointChange',
			'AudioChannelEqualizerChange',
			'AudioChannelEqualizerContourChange',
			'AudioChannelEqualizerPresetChange',
			'AudioChannelFaderGroupChange',
			'AudioChannelGainChange',
			'AudioChannelGainLockChange',
			'AudioChannelIdentifyChange',
			'AudioChannelInputSourceChange',
			'AudioChannelLightingBehaviorChange',
			'AudioChannelLimiterChange',
			'AudioChannelLobeConfigurationChange',
			'AudioChannelLobePositionChange',
			'AudioChannelLobeTalkerHeightChange',
			'AudioChannelLobeWidthChange',
			'AudioChannelLogicReceiverChange',
			'AudioChannelMicOptimizationChange',
			'AudioChannelMicPolarPatternChange',
			'AudioChannelMicrophoneAngleChange',
			'AudioChannelMonitorMixChange',
			'AudioChannelMonitorMixReverbChange',
			'AudioChannelMuteChange',
			'AudioChannelMuteGroupChange',
			'AudioChannelMuteLockChange',
			'AudioChannelNameChange',
			'AudioChannelNoiseReductionChange',
			'AudioChannelOutputPathChange',
			'AudioChannelPanChange',
			'AudioChannelPcPeripheralChange',
			'AudioChannelPhantomPowerChange',
			'AudioChannelPlosiveReductionChange',
			'AudioChannelPostGateGainChange',
			'AudioChannelPostGateMuteChange',
			'AudioChannelPostGateSoloChange',
			'AudioChannelPreFadeListenChange',
			'AudioChannelReverbChange',
			'AudioChannelSelectedLoudspeakerChange',
			'AudioChannelSignalGeneratorChange',
			'AudioChannelSoloChange',
			'AudioChannelStereoGainChange',
			'AudioChannelStereoReverseChange',
			'AudioChannelStereoWidthChange',
			'AudioChannelTalkerHeightChange',
			'AudioChannelTransmitterClipChange',
			'AudioChannelUsbOutputModeChange'
		],
		AudioCoverageAreaFeatureChange: [
			'AudioCoverageAreaConfigurationChange',
			'AudioCoverageAreaGainChange',
			'AudioCoverageAreaMuteChange'
		],
		ChargerBayFeatureChange: [
			'ChargerBayAssociationChange',
			'ChargerBayBatteryCapacityChange',
			'ChargerBayBatteryHealthChange',
			'ChargerBayBatteryPresentChange',
			'ChargerBayChargingModeChange',
			'ChargerBayDockedDeviceChange'
		],
		ChargerSlotFeatureChange: ['ChargerSlotChargerBaysChange', 'ChargerSlotChargingSlotTypeIdChange'],
		ClaimedDevicesEvent: ['DeviceClaimed', 'DeviceUnclaimed'],
		ClearedAssociation: ['ChargerBay', 'RfChannel'],
		CloneRoomEvent: [
			'CloneRoomAudioRouteUpdateEvent',
			'CloneRoomControlRouteUpdateEvent',
			'CloneRoomDeviceUpdateEvent',
			'CloneRoomResultEvent',
			'CloneRoomStartedEvent'
		],
		CrossPointFeatureChange: ['CrossPointConfigurationChange', 'CrossPointGainChange'],
		CrossPointInputAudioChannel: ['AudioChannel', 'StereoAudioChannel'],
		DeviceFeatureChange: [
			'ConnectedDeviceLabelChange',
			'DeviceActivePresetChange',
			'DeviceAllEqualizersBypassChange',
			'DeviceAllIntellimixBypassChange',
			'DeviceAnalogAudioOutputModeChange',
			'DeviceAnalogLogicChange',
			'DeviceAnalogLogicPresetSwitchChange',
			'DeviceAnalogLogicPresetSwitchStatusChange',
			'DeviceAntennaBiasVoltageChange',
			'DeviceAudioAutoFocusChange',
			'DeviceAudioChannelCountChange',
			'DeviceAudioChannelsChange',
			'DeviceAudioMuteChange',
			'DeviceAudioMuteControlChange',
			'DeviceAudioMuteControlGroupChange',
			'DeviceAudioMuteDefaultChange',
			'DeviceAudioMuteLockChange',
			'DeviceAudioNetworkChange',
			'DeviceAudioNetworkInterfaceChange',
			'DeviceAuthenticationChange',
			'DeviceAutomixGainMeteringChange',
			'DeviceAvailablePackagesChange',
			'DeviceBatteryHealthChange',
			'DeviceBatteryLevelChange',
			'DeviceBatteryStorageModeChange',
			'DeviceBluetoothDeviceInformationServiceChange',
			'DeviceBodypackMicrophoneModeChange',
			'DeviceCallStatusChange',
			'DeviceCommandStringsServiceChange',
			'DeviceConferencingOperationModeButtonLockChange',
			'DeviceConferencingOperationModeChange',
			'DeviceConfigurationLockChange',
			'DeviceControlNetworkChange',
			'DeviceControlNetworkInterfaceChange',
			'DeviceCoverageLockChange',
			'DeviceCoverageModeChange',
			'DeviceCoveragePositionChange',
			'DeviceDanteAudioEncryptionChange',
			'DeviceDanteAudioNetworkAccessChange',
			'DeviceDanteAudioNetworkChange',
			'DeviceDectRfSpectrumScanChange',
			'DeviceDeploymentCandidatesChange',
			'DeviceDnsClientConfigurationChange',
			'DeviceDoubleStuffProxiedTransmittersChange',
			'DeviceEqualizerContourChange',
			'DeviceErrorIndicatorChange',
			'DeviceEthernetLightsChange',
			'DeviceFirmwareUpdatePolicyChange',
			'DeviceGatewayDeviceChange',
			'DeviceGlobalMicStatusChange',
			'DeviceHeightChange',
			'DeviceIdentifyChange',
			'DeviceInternetOfThingsChange',
			'DeviceLicenseChange',
			'DeviceLicenseChangeV2',
			'DeviceLicenseChangeV3',
			'DeviceLightingAudioActivityChange',
			'DeviceLightingAudioMetersChange',
			'DeviceLightingAudioMuteIndicatorOverrideChange',
			'DeviceLightingAutomixGatingChange',
			'DeviceLightingBehaviorChange',
			'DeviceLightingBrightnessChange',
			'DeviceLightingLiveAudioMetersChange',
			'DeviceLightingMuteBehaviorChange',
			'DeviceLightingRingChange',
			'DeviceLightingSplitModeBreathingMeterColorChange',
			'DeviceLightingSplitModeSolidMeterColorChange',
			'DeviceLightingTouchPanelModeChange',
			'DeviceLightingUnmuteBehaviorChange',
			'DeviceLinkButtonLockChange',
			'DeviceLinkedAccessPointChange',
			'DeviceListenerHeightChange',
			'DeviceLogicMuteChange',
			'DeviceLogicReceiverChange',
			'DeviceLogicTransmitterChange',
			'DeviceLoudspeakerChange',
			'DeviceMdnsServiceChange',
			'DeviceMeteringModesChange',
			'DeviceMicStatusChange',
			'DeviceNameChange',
			'DeviceOnChargerChange',
			'DevicePlacementChange',
			'DeviceProxiedDevicesChange',
			'DeviceRedundantAudioNetworkChange',
			'DeviceRfDensityModeChange',
			'DeviceRfPowerChange',
			'DeviceRoomChange',
			'DeviceRotationChange',
			'DeviceRoutingPositionChange',
			'DeviceSecurity8021XChange',
			'DeviceSmartGateChange',
			'DeviceStereoAudioChange',
			'DeviceSwitchConfigurationChange',
			'DeviceTagsChange',
			'DeviceTemperatureChange',
			'DeviceTransmitterIdentifyBehaviorChange',
			'DeviceTransmitterInitialStateFromChargerChange',
			'DeviceTransmitterLightingBehaviorChange',
			'DeviceTransmitterMuteBehaviorChange',
			'DeviceTransmitterOutOfRangeBehaviorChange',
			'DeviceTransmitterRejoinBehaviorChange',
			'DeviceTransmitterSwitchBehaviorChange',
			'DeviceUpdateProgressChange',
			'DeviceUptimeChange',
			'DeviceUsbMuteSyncChange',
			'DeviceUsbTerminalTypeChange',
			'DeviceUserPresetsChange',
			'DeviceVirtualAcousticBoundaryChange',
			'DeviceWebServiceChange'
		],
		DeviceUnlinkingDetailResult: ['DeviceUnlinkingChargerBayResult', 'DeviceUnlinkingRfChannelResult'],
		DiscoveredDevicesEvent: ['DeviceAdded', 'DeviceRemoved'],
		DiscoveredRoomEvent: ['RoomAddedEvent', 'RoomRemovedEvent'],
		EqualizerFilterFeatureChange: [
			'EqualizerFilterBandwidthChange',
			'EqualizerFilterConfigurationChange',
			'EqualizerFilterFrequencyChange',
			'EqualizerFilterGainChange',
			'EqualizerFilterQFactorChange'
		],
		Fragment: [
			'AudioChannel',
			'AudioCoverageArea',
			'ChargerBay',
			'ChargerSlot',
			'CrossPoint',
			'EqualizerFilter',
			'RfChannel',
			'UserPreset'
		],
		IDurationRangeConstraints: ['AudioChannelDelayFieldConstraints', 'AutomixHoldTimeConstraints'],
		IFieldSupportInfo: [
			'AudioChannelCompressorLevelConstraints',
			'AudioChannelCompressorRatioConstraints',
			'AudioChannelCompressorThresholdConstraints',
			'AudioChannelLimiterThresholdConstraints',
			'AudioChannelLobePositionAutoFocusConstraints',
			'AudioChannelMicrophoneAngleAutoFocusConstraints',
			'AudioChannelTalkerHeightAutoFocusConstraints',
			'AutoLevelConfigurationConstraints',
			'AutoLevelModeConstraints',
			'AutoLevelPositionConstraints',
			'AutoLevelToneConstraints',
			'EqualizerEnabledConstraints',
			'EqualizerFiltersConstraints',
			'EqualizerToneConstraints',
			'FieldSupport',
			'NoiseReductionLevelConstraints',
			'TransmitterInitialStateFromChargerOptionalFormFactorConstraints'
		],
		IFloatRangeConstraints: [
			'AudioChannelAcousticEchoCancellationErleLevelConstraints',
			'AudioChannelAcousticEchoCancellationPeakMeterLevelConstraints',
			'AudioChannelAcousticEchoCancellationRmsMeterLevelConstraints',
			'AudioChannelAutomaticGainControlPeakMeterLevelConstraints',
			'AudioChannelAutomaticGainControlRmsMeterLevelConstraints',
			'AudioChannelAutomaticGainMaxBoostConstraints',
			'AudioChannelAutomaticGainMaxCutConstraints',
			'AudioChannelAutomaticGainTargetLevelConstraints',
			'AudioChannelAutomixGainConstraints',
			'AudioChannelAutomixerInputPeakLevelConstraints',
			'AudioChannelAutomixerInputRmsLevelConstraints',
			'AudioChannelGainFieldConstraints',
			'AudioChannelLobePositionAutoFocusFieldConstraints',
			'AudioChannelLobePositionFixedFieldConstraints',
			'AudioChannelMatrixMixerInputPeakLevelConstraints',
			'AudioChannelMatrixMixerInputRmsLevelConstraints',
			'AudioChannelMatrixMixerOutputPeakLevelConstraints',
			'AudioChannelMatrixMixerOutputRmsLevelConstraints',
			'AudioChannelMatrixMixerStereoInputPeakLevelConstraints',
			'AudioChannelMatrixMixerStereoInputRmsLevelConstraints',
			'AudioChannelPeakMeterLevelConstraints',
			'AudioChannelPostGateFieldConstraints',
			'AudioChannelPostGateGainStereoPeakLevelConstraints',
			'AudioChannelPostGateGainStereoRmsLevelConstraints',
			'AudioChannelRmsLevelConstraints',
			'AudioChannelStereoAutomixGainConstraints',
			'AudioChannelStereoGainFieldConstraints',
			'AudioChannelStereoPeakLevelConstraints',
			'AudioChannelStereoRmsLevelConstraints',
			'AudioChannelTalkerHeightAutoFocusFieldConstraints',
			'AudioChannelTalkerHeightFieldConstraints',
			'AudioChannelTalkerHeightFixedFieldConstraints',
			'AudioCoverageAreaGainFieldConstraints',
			'AudioCoverageAreaPeakMeterLevelConstraints',
			'AudioCoverageRmsMeterLevelConstraints',
			'AutomixOffAttenuationConstraints',
			'CrossPointGainFieldConstraints',
			'DeviceHeightFieldConstraints',
			'DeviceListenerHeightFieldConstraints',
			'EqualizerFilterFrequencyFieldConstraints',
			'EqualizerFilterGainFieldConstraints',
			'RectangularAreaFieldConstraints'
		],
		IIntRangeConstraints: [
			'AudioChannelAutomaticGainConstraints',
			'AudioChannelMicrophoneAngleAutoFocusFieldConstraints',
			'AudioChannelMicrophoneAngleFixedFieldConstraints',
			'AudioChannelMonitorMixTypeConstraints',
			'AudioChannelPanFieldConstraints',
			'AudioChannelReverbIntensityConstraints',
			'AudioChannelStereoWidthAngleConstraints',
			'AutomixGatingSensitivityConstraints',
			'AutomixMaximumOpenChannelsConstraints',
			'ChargerBayBatteryHealthPercentageConstraints',
			'DeviceBatteryHealthPercentageConstraints',
			'DeviceBatteryLevelPercentageConstraints',
			'DeviceCommandStringsServicePortConstraints',
			'DeviceLightingBrightnessFieldConstraints',
			'DeviceRotationAxisConstraints',
			'DeviceVirtualAcousticBoundarySensitivityConstraints',
			'RgbColorMixFieldConstraints'
		],
		INullableDurationRangeConstraints: [],
		INullableFloatRangeConstraints: ['AudioChannelLimiterThresholdConstraints'],
		INullableIntRangeConstraints: ['EqualizerToneConstraints'],
		IStringPatternRangeConstraints: [
			'AudioChannelDanteRouteReceiverDanteChannelNameConstraints',
			'AudioChannelDanteRouteTransmitterDanteChannelNameConstraints',
			'AudioChannelNameFieldConstraints',
			'DeviceDanteAudioNetworkNameConstraints',
			'DeviceNameFieldConstraints',
			'DeviceSecurity8021XAnonymousIdConstraints',
			'DeviceSecurity8021XPasswordConstraints',
			'DeviceSecurity8021XUserNameConstraints',
			'UserPresetCoreNameConstraints'
		],
		IUtf8StringMaxBytesConstraints: ['ConnectedDeviceLabelFieldConstraints', 'DeviceNameFieldConstraints'],
		MeterChange: ['AudioChannelMeterChange', 'AudioCoverageAreaMeterChange', 'RfChannelMeterChange'],
		Node: [
			'AudioChannel',
			'AudioCoverageArea',
			'ChargerBay',
			'ChargerSlot',
			'CrossPoint',
			'Device',
			'EqualizerFilter',
			'FirmwareUpdateProgress',
			'FirmwareUpdateRequestProgress',
			'RfChannel',
			'Room',
			'RoomManifestDevice',
			'StereoAudioChannel',
			'UserPreset'
		],
		NodeChange: [
			'AudioChannel',
			'AudioChannelChange',
			'AudioCoverageArea',
			'AudioCoverageAreaChange',
			'ChargerBay',
			'ChargerBayChange',
			'ChargerSlot',
			'ChargerSlotChange',
			'CrossPoint',
			'CrossPointChange',
			'Device',
			'DeviceChange',
			'DeviceStatusChange',
			'EqualizerFilter',
			'EqualizerFilterChange',
			'FirmwareUpdateProgress',
			'FirmwareUpdateRequestProgress',
			'RfChannel',
			'RfChannelChange',
			'Room',
			'UserPreset',
			'UserPresetChange'
		],
		NodeOperationResult: [
			'AudioChannelOperationResult',
			'AudioCoverageAreaOperationResult',
			'ChargerBayOperationResult',
			'ChargerSlotOperationResult',
			'ClearAssociationResult',
			'CreateRouteOperationResult',
			'CrossPointOperationResult',
			'DeleteRouteOperationResult',
			'DeviceOperationResult',
			'EqualizerFilterOperationResult',
			'MacAndSerialClaimOperationResult',
			'PendingDeviceClaimRemovalResult',
			'RfChannelOperationResult',
			'RoomOperationResult',
			'UserPresetOperationResult'
		],
		OptimizeRoomProgress: ['OptimizeRoomProgressEvent', 'OptimizeRoomResult'],
		QFactorConstraint: ['EqualizerFilterValidQFactors', 'FloatRange'],
		ResetDeviceResult: ['DeviceOperationResult', 'OperationResult'],
		RfChannelFeatureChange: [
			'RfChannelAssociationChange',
			'RfChannelCarriedAudioChannelsChange',
			'RfChannelInterferenceDetectionChange',
			'RfChannelInterferenceDetectorChange',
			'RfChannelLinkedTransmitterChange',
			'RfChannelMuteChange',
			'RfChannelTuningChange'
		],
		RoomDeploymentProgress: ['RoomDeploymentProgressEvent', 'RoomDeploymentResult'],
		TagEvent: ['TagAddedEvent', 'TagRemovedEvent'],
		UserPresetFeatureChange: ['UserPresetCoreChange']
	}
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/**
	 * The `AudioChannelGroupName` scalar represents the type of an audio channel. Use the device 'constraints' field to
	 * get a list of possible values supported for a specific device. Note that this list is open to expansion in the future as more devices
	 * and thus newer channel groups are supported over time.
	 *
	 * For applications integrating with this API, it is recommended to use an object defining a collection of constants (like an Enumeration class)
	 * to support this future expansion.
	 *
	 * Here are the currently supported list of possible values:
	 *
	 * AEC_REFERENCE
	 *
	 * AEC_REFERENCE_MIX_OUTPUT
	 *
	 * ANALOG_70V_OUTPUT
	 *
	 * ANALOG_AUX_INPUT
	 *
	 * ANALOG_INPUT
	 *
	 * ANALOG_OUTPUT
	 *
	 * AUTOMIX
	 *
	 * AUTOMIX_STEREO
	 *
	 * AUX_INPUT
	 *
	 * AUX_OUTPUT
	 *
	 * BACK
	 *
	 * BLUETOOTH_INPUT
	 *
	 * BLUETOOTH_OUTPUT
	 *
	 * DANTE_ANALOG_INPUT
	 *
	 * DANTE_AUX_INPUT
	 *
	 * DANTE_INPUT
	 *
	 * DANTE_LINE_INPUT
	 *
	 * DANTE_LINE_OUTPUT
	 *
	 * DANTE_MIC_INPUT
	 *
	 * DANTE_OUTPUT
	 *
	 * DIRECT_OUTPUT
	 *
	 * DIRECT_OUTPUT_ANALOG_INPUT
	 *
	 * DIRECT_OUTPUT_DANTE_MIC_INPUT
	 *
	 * GENERIC
	 *
	 * HEADPHONE
	 *
	 * LOBE
	 *
	 * LOUDSPEAKER
	 *
	 * MIC
	 *
	 * MIX_OUTPUT
	 *
	 * MOBILE_INPUT
	 *
	 * MOBILE_OUTPUT
	 *
	 * PC_INPUT
	 *
	 * PC_OUTPUT
	 *
	 * PRE_FADE_LISTEN
	 *
	 * SIGNAL_GENERATOR_INPUT
	 *
	 * SUM
	 *
	 * TRS_INPUT
	 *
	 * USB_INPUT
	 *
	 * USB_OUTPUT
	 *
	 * VIRTUAL_AUDIO_INPUT
	 *
	 * VIRTUAL_AUDIO_OUTPUT
	 *
	 * XLR_INPUT
	 */
	AudioChannelGroupName: { input: string; output: string };
	/** A DateTime in ISO-8601 format. (https://wikipedia.org/wiki/ISO_8601) */
	DateTime: { input: string; output: string };
	/**
	 * Corresponds to a period of time, as specified by https://en.wikipedia.org/wiki/ISO_8601#Durations
	 * E.g. PT1S is one second. PT0.1S is 100 milliseconds.
	 */
	Duration: { input: string; output: string };
	/**
	 * Guid stands for Globally Unique Identifier. Also referred to as UUIDs or Universaly Unique Identifiers.
	 * - Technically they are 128-bit unique reference numbers which are highly unlikely to repeat.
	 * - A Guid follows a specific structure defined in RFC `4122` and come in a few different
	 * versions and variants. All variants follow the same structure xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
	 * where M represents the version and the most significant bits of N represent the variant.
	 */
	Guid: { input: string; output: string };
	/**
	 * The `IpAddress` scalar represents a dotted quad string notation of
	 * an IPv4 address.
	 */
	IpAddress: { input: string; output: string };
	Regex: { input: string; output: string };
	SemanticVersion: { input: string; output: string };
	Upload: { input: string; output: string };
};

export type ActivateLicenseInput = {
	activationKey: Scalars['String']['input'];
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export enum AnalogAudioOutputMode {
	/**
	 * For use with low impedance speakers (a.k.a Low Z), such as 8-ohm or 16-ohm speakers.
	 * Speakers will be wired individually to each audio channel.
	 */
	LowImpedance = 'LOW_IMPEDANCE',
	/**
	 * For use with 70V speakers with step-up / step-down transformers inside.
	 * Speakers are wired in a daisy-chained way to a single audio channel.
	 */
	SeventyVolt = 'SEVENTY_VOLT'
}

export enum AnalogInputUseMode {
	/** The channel is an Acoustic Echo Cancellation reference input */
	AcousticEchoCancellation = 'ACOUSTIC_ECHO_CANCELLATION',
	/** The channel is an audio input */
	MicIn = 'MIC_IN'
}

export enum AnalogLevel {
	Aux = 'AUX',
	Line = 'LINE',
	Mic = 'MIC'
}

export enum AnalogLogicPresetSwitch {
	/** Mode A: This is the default selection. When there is no signal, the device loads Preset 1. When there is signal, the device switches to Preset 2. */
	ModeA = 'MODE_A',
	/** Mode B: When there is no signal, the device loads Preset 2. When there is signal, the device switches to Preset 1. */
	ModeB = 'MODE_B'
}

export enum AnalogLogicPresetSwitchStatus {
	NoSignal = 'NO_SIGNAL',
	Signal = 'SIGNAL'
}

export enum AssociationStatus {
	/** Access point is not discoverable */
	AccessPointDeviceNotFound = 'ACCESS_POINT_DEVICE_NOT_FOUND',
	/** Charger device is not discoverable */
	ChargerDeviceNotFound = 'CHARGER_DEVICE_NOT_FOUND',
	/** Failure occured on pre-processing a Charger Bay. This include failure in clearing existing association duing re-association */
	FailurePreProcessingChargerBay = 'FAILURE_PRE_PROCESSING_CHARGER_BAY',
	/** Failure occured on pre-processing a RF Channel. This include failure in clearing existing association duing re-association */
	FailurePreProcessingRfChannel = 'FAILURE_PRE_PROCESSING_RF_CHANNEL',
	/** Failure occured when processing association request on Charger Bay. This includes failed opeartions on Charger Bay */
	FailureProcessingChargerBay = 'FAILURE_PROCESSING_CHARGER_BAY',
	/** Failure occured when processing association request on RF Channel. This includes failed opeartions on RF Channel */
	FailureProcessingRfChannel = 'FAILURE_PROCESSING_RF_CHANNEL',
	/** Input node id(s) provided in association request is not valid */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support association feature or not a valid input for this operation */
	InvalidOperation = 'INVALID_OPERATION',
	/** Successfully associated RF channel and Charger bay */
	Success = 'SUCCESS'
}

export enum AudioActivityLevel {
	Clipping = 'CLIPPING',
	Good = 'GOOD',
	Low = 'LOW'
}

export type AudioChannelAcousticEchoCancellationInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	nonLinearProcessingLevel?: InputMaybe<NonLinearProcessingLevel | `${NonLinearProcessingLevel}`>;
};

export type AudioChannelAcousticEchoCancellationMonoReferenceInput = {
	/** Audio channel which can configure the 'acousticEchoCancellationMonoReference' feature */
	id: Scalars['ID']['input'];
	/** Audio channel to be selected as acoustic echo cancellation mono reference source */
	source: Scalars['ID']['input'];
};

export type AudioChannelAcousticEchoCancellationStereoReferenceInput = {
	/** Audio channel which can congfigure the 'acousticEchoCancellationStereoReference' feature */
	id: Scalars['ID']['input'];
	/** Audio channel to be selected as acoustic echo cancellation stereo reference source */
	source: Scalars['ID']['input'];
	/** Audio channel spatial separation information which can configure the 'acousticEchoCancellationStereoReference' feature */
	stereoAudioChannelSpatialSeparation: StereoAudioChannelSpatialSeparation | `${StereoAudioChannelSpatialSeparation}`;
};

export type AudioChannelAnalogInputUseUpdateInput = {
	mode: AnalogInputUseMode | `${AnalogInputUseMode}`;
};

export type AudioChannelAnalogLevelInput = {
	level: AnalogLevel | `${AnalogLevel}`;
};

export type AudioChannelAutoLevelUpdateInput = {
	configuration?: InputMaybe<AutoLevelConfiguration | `${AutoLevelConfiguration}`>;
	mode?: InputMaybe<AutoLevelMode | `${AutoLevelMode}`>;
	position?: InputMaybe<AutoLevelPosition | `${AutoLevelPosition}`>;
	tone?: InputMaybe<AutoLevelTone | `${AutoLevelTone}`>;
};

export type AudioChannelAutoMuteUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomaticGainControlUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	maxBoost?: InputMaybe<Scalars['Float']['input']>;
	maxCut?: InputMaybe<Scalars['Float']['input']>;
	targetLevel?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelAutomixerInputAlwaysOnUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerInputPriorityUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerInputSendToMonoMixUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerInputSendToStereoMixUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerInputUpdateInput = {
	alwaysOn?: InputMaybe<Scalars['Boolean']['input']>;
	priority?: InputMaybe<Scalars['Boolean']['input']>;
	sendToMix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	gatingSensitivity?: InputMaybe<Scalars['Int']['input']>;
	holdTime?: InputMaybe<Scalars['Duration']['input']>;
	leaveLastChannelOn?: InputMaybe<Scalars['Boolean']['input']>;
	maximumOpenChannels?: InputMaybe<Scalars['Int']['input']>;
	mode?: InputMaybe<AutomixMode | `${AutomixMode}`>;
	offAttenuation?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelAutomixerVoiceActivityDetectionUpdateInput = {
	noiseDetectionSensitivity?: InputMaybe<NoiseDetectionSensitivity | `${NoiseDetectionSensitivity}`>;
	noiseRemovalLevel?: InputMaybe<NoiseRemovalLevel | `${NoiseRemovalLevel}`>;
};

export type AudioChannelCompressorUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<CompressorLevel | `${CompressorLevel}`>;
	ratio?: InputMaybe<Scalars['Float']['input']>;
	threshold?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelCountInput = {
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export type AudioChannelDanteRouteReceiverUpdateInput = {
	danteChannelName: Scalars['String']['input'];
};

export type AudioChannelDanteRouteTransmitterUpdateInput = {
	danteChannelName: Scalars['String']['input'];
};

export type AudioChannelDelayUpdateInput = {
	delay?: InputMaybe<Scalars['Duration']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelDenoiserUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<DenoiserLevel | `${DenoiserLevel}`>;
};

export type AudioChannelDeverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	intensity?: InputMaybe<DeverbIntensity | `${DeverbIntensity}`>;
};

export type AudioChannelDeviceSpecificEqualizerContourUpdateInput = {
	deviceSpecificEqualizerContour: DeviceSpecificEqualizerContour | `${DeviceSpecificEqualizerContour}`;
};

export type AudioChannelDirectOutputTapPointUpdateInput = {
	tapPoint: DirectOutputTapPoint | `${DirectOutputTapPoint}`;
};

export type AudioChannelEqualizerContourUpdateInput = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type AudioChannelEqualizerPresetUpdateInput = {
	equalizerPreset: EqualizerPreset | `${EqualizerPreset}`;
	/** Audio channel node ID */
	id: Scalars['ID']['input'];
};

export type AudioChannelEqualizerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	tone?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelFaderGroupUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelFeatureUpdateInput =
	| {
			acousticEchoCancellation: AudioChannelAcousticEchoCancellationInput;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse: AudioChannelAnalogInputUseUpdateInput;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel: AudioChannelAnalogLevelInput;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel: AudioChannelAutoLevelUpdateInput;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute: AudioChannelAutoMuteUpdateInput;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl: AudioChannelAutomaticGainControlUpdateInput;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer: AudioChannelAutomixerUpdateInput;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput: AudioChannelAutomixerInputUpdateInput;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn: AudioChannelAutomixerInputAlwaysOnUpdateInput;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority: AudioChannelAutomixerInputPriorityUpdateInput;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix: AudioChannelAutomixerInputSendToMonoMixUpdateInput;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix: AudioChannelAutomixerInputSendToStereoMixUpdateInput;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection: AudioChannelAutomixerVoiceActivityDetectionUpdateInput;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor: AudioChannelCompressorUpdateInput;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver: AudioChannelDanteRouteReceiverUpdateInput;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter: AudioChannelDanteRouteTransmitterUpdateInput;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay: AudioChannelDelayUpdateInput;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser: AudioChannelDenoiserUpdateInput;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb: AudioChannelDeverbUpdateInput;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour: AudioChannelDeviceSpecificEqualizerContourUpdateInput;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint: AudioChannelDirectOutputTapPointUpdateInput;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer: AudioChannelEqualizerUpdateInput;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour: AudioChannelEqualizerContourUpdateInput;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup: AudioChannelFaderGroupUpdateInput;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain: AudioChannelGainUpdateInput;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock: AudioChannelGainLockUpdateInput;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource: AudioChannelInputSourceUpdateInput;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter: AudioChannelLimiterUpdateInput;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration: AudioChannelLobeConfigurationUpdateInput;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition: AudioChannelLobePositionUpdateInput;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight: AudioChannelFixedLobeTalkerHeightUpdateInput;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth: AudioChannelLobeWidthUpdateInput;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization: AudioChannelMicOptimizationUpdateInput;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern: AudioChannelMicPolarPatternUpdateInput;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle: AudioChannelFixedMicrophoneAngleUpdateInput;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix: AudioChannelMonitorMixUpdateInput;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb: AudioChannelMonitorMixReverbUpdateInput;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute: AudioChannelMuteUpdateInput;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup: AudioChannelMuteGroupUpdateInput;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock: AudioChannelMuteLockUpdateInput;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name: AudioChannelNameUpdateInput;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction: AudioChannelNoiseReductionUpdateInput;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath: AudioChannelOutputPathUpdateInput;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan: AudioChannelPanUpdateInput;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral: AudioChannelPcPeripheralUpdateInput;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower: AudioChannelPhantomPowerUpdateInput;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction: AudioChannelPlosiveReductionUpdateInput;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain: AudioChannelPostGateGainUpdateInput;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute: AudioChannelPostGateMuteUpdateInput;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo: AudioChannelPostGateSoloUpdateInput;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen: AudioChannelPreFadeListenUpdateInput;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb: AudioChannelReverbUpdateInput;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker: AudioChannelSelectedLoudspeakerInput;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator: AudioChannelSignalGeneratorUpdateInput;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo: AudioChannelSoloUpdateInput;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain: AudioChannelStereoGainUpdateInput;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse: AudioChannelStereoReverseUpdateInput;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth: AudioChannelStereoWidthUpdateInput;
			talkerHeight?: never;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight: AudioChannelTalkerHeightUpdateInput;
			usbOutputMode?: never;
	  }
	| {
			acousticEchoCancellation?: never;
			analogInputUse?: never;
			analogLevel?: never;
			autoLevel?: never;
			autoMute?: never;
			automaticGainControl?: never;
			automixer?: never;
			automixerInput?: never;
			automixerInputAlwaysOn?: never;
			automixerInputPriority?: never;
			automixerInputSendToMonoMix?: never;
			automixerInputSendToStereoMix?: never;
			automixerVoiceActivityDetection?: never;
			compressor?: never;
			danteRouteReceiver?: never;
			danteRouteTransmitter?: never;
			delay?: never;
			denoiser?: never;
			deverb?: never;
			deviceSpecificEqualizerContour?: never;
			directOutputTapPoint?: never;
			equalizer?: never;
			equalizerContour?: never;
			faderGroup?: never;
			gain?: never;
			gainLock?: never;
			inputSource?: never;
			limiter?: never;
			lobeConfiguration?: never;
			lobePosition?: never;
			lobeTalkerHeight?: never;
			lobeWidth?: never;
			micOptimization?: never;
			micPolarPattern?: never;
			microphoneAngle?: never;
			monitorMix?: never;
			monitorMixReverb?: never;
			mute?: never;
			muteGroup?: never;
			muteLock?: never;
			name?: never;
			noiseReduction?: never;
			outputPath?: never;
			pan?: never;
			pcPeripheral?: never;
			phantomPower?: never;
			plosiveReduction?: never;
			postGateGain?: never;
			postGateMute?: never;
			postGateSolo?: never;
			preFadeListen?: never;
			reverb?: never;
			selectedLoudspeaker?: never;
			signalGenerator?: never;
			solo?: never;
			stereoGain?: never;
			stereoReverse?: never;
			stereoWidth?: never;
			talkerHeight?: never;
			usbOutputMode: AudioChannelUsbOutputModeUpdateInput;
	  };

export type AudioChannelFixedLobeTalkerHeightUpdateInput = {
	fixed: AudioChannelLobeTalkerHeightUpdateInput;
};

export type AudioChannelFixedMicrophoneAngleUpdateInput = {
	fixed: AudioChannelMicrophoneAngleUpdateInput;
};

export type AudioChannelGainLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export enum AudioChannelGroup {
	AecReference = 'AEC_REFERENCE',
	Analog_70VOutput = 'ANALOG_70V_OUTPUT',
	AnalogAuxInput = 'ANALOG_AUX_INPUT',
	AnalogOutput = 'ANALOG_OUTPUT',
	Automix = 'AUTOMIX',
	AuxInput = 'AUX_INPUT',
	AuxOutput = 'AUX_OUTPUT',
	Back = 'BACK',
	BluetoothInput = 'BLUETOOTH_INPUT',
	BluetoothOutput = 'BLUETOOTH_OUTPUT',
	DanteAnalogInput = 'DANTE_ANALOG_INPUT',
	DanteAuxInput = 'DANTE_AUX_INPUT',
	DanteInput = 'DANTE_INPUT',
	DanteLineInput = 'DANTE_LINE_INPUT',
	DanteLineOutput = 'DANTE_LINE_OUTPUT',
	DanteMicInput = 'DANTE_MIC_INPUT',
	DanteOutput = 'DANTE_OUTPUT',
	DirectOutput = 'DIRECT_OUTPUT',
	Generic = 'GENERIC',
	Headphone = 'HEADPHONE',
	Lobe = 'LOBE',
	Loudspeaker = 'LOUDSPEAKER',
	Mic = 'MIC',
	MixOutput = 'MIX_OUTPUT',
	MobileInput = 'MOBILE_INPUT',
	MobileOutput = 'MOBILE_OUTPUT',
	PcInput = 'PC_INPUT',
	PcOutput = 'PC_OUTPUT',
	SignalGeneratorInput = 'SIGNAL_GENERATOR_INPUT',
	Sum = 'SUM',
	UsbInput = 'USB_INPUT',
	UsbOutput = 'USB_OUTPUT',
	VirtualAudioInput = 'VIRTUAL_AUDIO_INPUT',
	VirtualAudioOutput = 'VIRTUAL_AUDIO_OUTPUT'
}

export enum AudioChannelInputSourceType {
	Analog = 'ANALOG',
	Digital = 'DIGITAL'
}

export type AudioChannelInputSourceUpdateInput = {
	source?: InputMaybe<AudioChannelInputSourceType | `${AudioChannelInputSourceType}`>;
};

export type AudioChannelLimiterUpdateInput = {
	bypassed?: InputMaybe<Scalars['Boolean']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	threshold?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelLobeConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelLobePositionUpdateInput = {
	fixed?: InputMaybe<FloatPositionUpdateInput>;
};

export type AudioChannelLobeTalkerHeightUpdateInput = {
	height?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelLobeWidthUpdateInput = {
	width?: InputMaybe<LobeWidth | `${LobeWidth}`>;
};

export type AudioChannelMicOptimizationUpdateInput = {
	mode: MicOptimizationMode | `${MicOptimizationMode}`;
};

export type AudioChannelMicPolarPatternUpdateInput = {
	pattern: MicPolarPattern | `${MicPolarPattern}`;
};

export type AudioChannelMicrophoneAngleUpdateInput = {
	angle?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelMonitorMixReverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelMonitorMixUpdateInput = {
	percentage: Scalars['Int']['input'];
};

export type AudioChannelMuteGroupUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelMuteLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type AudioChannelNameUpdateInput = {
	name: Scalars['String']['input'];
};

export type AudioChannelNoiseReductionUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<NoiseReductionLevel | `${NoiseReductionLevel}`>;
};

export type AudioChannelOutputPathUpdateInput = {
	path: OutputSignalPath | `${OutputSignalPath}`;
};

export type AudioChannelPanUpdateInput = {
	percentage: Scalars['Int']['input'];
};

export type AudioChannelPcPeripheralIdUpdateInput = {
	id: Scalars['String']['input'];
};

export type AudioChannelPcPeripheralUpdateInput = {
	selectedPeripheral: AudioChannelPcPeripheralIdUpdateInput;
};

export type AudioChannelPhantomPowerUpdateInput = {
	volts: Scalars['Int']['input'];
};

export type AudioChannelPlosiveReductionUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelPostGateGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioChannelPostGateMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type AudioChannelPostGateSoloUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelPreFadeListenUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export enum AudioChannelReassignmentOperation {
	Replace = 'Replace',
	Swap = 'Swap'
}

export type AudioChannelReverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	intensity?: InputMaybe<Scalars['Int']['input']>;
	type?: InputMaybe<ReverbType | `${ReverbType}`>;
};

export enum AudioChannelRole {
	Filter = 'FILTER',
	Sink = 'SINK',
	Source = 'SOURCE'
}

export type AudioChannelSelectedLoudspeakerInput = {
	loudspeaker: Loudspeaker | `${Loudspeaker}`;
};

export type AudioChannelSignalGeneratorUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	frequency?: InputMaybe<Scalars['Float']['input']>;
	gain?: InputMaybe<Scalars['Float']['input']>;
	signalType?: InputMaybe<SignalType | `${SignalType}`>;
};

export type AudioChannelSoloUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelStereoGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioChannelStereoReverseUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelStereoWidthUpdateInput = {
	angle: Scalars['Int']['input'];
};

export type AudioChannelTalkerHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type AudioChannelUpdateInput = {
	features?: InputMaybe<AudioChannelFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type AudioChannelUsbOutputModeUpdateInput = {
	mode: UsbOutputMode | `${UsbOutputMode}`;
};

export type AudioCoverageAreaFeatureUpdateInput =
	| { gain: AudioCoverageAreaGainUpdateInput; mute?: never }
	| { gain?: never; mute: AudioCoverageAreaMuteUpdateInput };

export type AudioCoverageAreaGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioCoverageAreaMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export enum AudioCoverageAreaType {
	Dedicated = 'DEDICATED',
	Dynamic = 'DYNAMIC'
}

export type AudioCoverageAreaUpdateInput = {
	features?: InputMaybe<AudioCoverageAreaFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type AudioEncryptionSettingsInput = {
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export enum AudioMetersLightingMode {
	Breathing = 'BREATHING',
	Live = 'LIVE',
	Solid = 'SOLID'
}

export type AudioNetworkConfiguration = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type AudioNetworkNameSyncInput = {
	id: Scalars['ID']['input'];
	/**
	 * By default, prior to setting Audio Network Channel Names, Shure (generic) channel names will be checked for conflicts.
	 * This validation can be skipped (if, perhaps, it has already been performed prior to invoking
	 * synchronization).
	 */
	validateChannelNameConflicts?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * By default, prior to setting Audio Network Device Name, discovered devices will be checked for conflicts.
	 * This validation can be skipped (if, perhaps, it has already been performed prior to invoking synchronization).
	 */
	validateDeviceNameConflicts?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AutoLevelConfiguration {
	Loud = 'LOUD',
	Normal = 'NORMAL',
	Quiet = 'QUIET'
}

export enum AutoLevelMode {
	Auto = 'AUTO',
	Manual = 'MANUAL'
}

export enum AutoLevelPosition {
	Far = 'FAR',
	Near = 'NEAR'
}

export enum AutoLevelTone {
	Bright = 'BRIGHT',
	Dark = 'DARK',
	Natural = 'NATURAL'
}

export enum AutoPositionStatus {
	Failed = 'FAILED',
	Inactive = 'INACTIVE',
	Processing = 'PROCESSING',
	Recording = 'RECORDING'
}

export enum AutomixMode {
	/**
	 * Emulates the Shure SCM820 automixer default settings. It is renowned for fast-acting, seamless channel gating and consistent perceived ambient sound levels.
	 * Off-attenuation in this mode is fixed at -12dB per channel, regardless of the number of open channels.
	 */
	Classic = 'CLASSIC',
	/** Provides manual control over all automixing parameters. This mode is useful when adjustments must be made to one of the preset modes to fit a particular application. */
	Custom = 'CUSTOM',
	/**
	 * Dynamically balances system gain between open and closed audio channels.
	 * The system gain remains consistent by distributing gain across audio channels to equal one open audio channel.
	 * The scaled gain structure helps to reduce noise when there is a high audio channel count.
	 * When fewer audio channels are used, the off attenuation setting is lower and provides transparent gating.
	 */
	GainSharing = 'GAIN_SHARING',
	/**
	 * Delivers fast-acting, seamless audio channel gating and consistent perceived ambient sound levels.
	 * Off attenuation  is fixed at -20 dB, regardless of the number of open audio channels.
	 */
	Gating = 'GATING',
	/**
	 * Sums all active audio channels and sends the summed signal over a single Dante output.
	 * The gain of all audio channels apply to the summed output.
	 */
	Manual = 'MANUAL',
	/**
	 * Off-attenuation settings for each channel are scaled, depending on the number of open channels.
	 * The scaled gain structure helps to reduce noise when there is a high channel count.
	 * When fewer channels are used, the lower off-attenuation provides transparent gating.
	 */
	Smooth = 'SMOOTH'
}

export type AutomixSourceSelectorInput = {
	/** Node Id of the channel where automixing is performed on a source channel */
	automix: Scalars['ID']['input'];
	/** Node Id of the source channel to be used as selector for automixing. Optional/Null represents a behavior to deselect a source channel if selected */
	source?: InputMaybe<Scalars['ID']['input']>;
};

export enum AutomixerInputDisengagedBehavior {
	FollowMuteColor = 'FOLLOW_MUTE_COLOR',
	Off = 'OFF'
}

/** The state machine describing the lifecycle of a battery-powered device. */
export enum BatteryState {
	Calculating = 'CALCULATING',
	Charging = 'CHARGING',
	Discharging = 'DISCHARGING',
	Empty = 'EMPTY',
	Full = 'FULL',
	OptimalStorage = 'OPTIMAL_STORAGE'
}

export type BetaDeviceIdentitity = {
	category: Scalars['String']['input'];
	versions: Array<Scalars['SemanticVersion']['input']>;
};

export enum BodypackMicrophoneMode {
	External = 'EXTERNAL',
	Internal = 'INTERNAL'
}

export type CancelPendingActivationLicenseInput = {
	activationKey: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export type CertificateInput = {
	anonymousId?: InputMaybe<Scalars['String']['input']>;
	clientCertificate?: InputMaybe<Scalars['String']['input']>;
	passphrase: Scalars['String']['input'];
	rootCertificate: Scalars['String']['input'];
	username: Scalars['String']['input'];
};

export enum ChargingMode {
	Calculating = 'CALCULATING',
	Full = 'FULL',
	Normal = 'NORMAL',
	Warm = 'WARM',
	Warmandfull = 'WARMANDFULL'
}

export enum ChargingSlotTypeIds {
	Blackhawkbodypack = 'BLACKHAWKBODYPACK',
	Blackhawkhandheld = 'BLACKHAWKHANDHELD',
	Blackhawkmicrobodypack = 'BLACKHAWKMICROBODYPACK',
	Cylindrical = 'CYLINDRICAL',
	Line2Viva = 'LINE2VIVA',
	None = 'None',
	Odincasecharger = 'ODINCASECHARGER',
	Odintabletopcharger = 'ODINTABLETOPCHARGER',
	Prismatic = 'PRISMATIC',
	Sbc220Sb900Bodypackganged = 'SBC220SB900BODYPACKGANGED',
	Sbc240Blackhawkganged = 'SBC240BLACKHAWKGANGED',
	Sbc441 = 'SBC441',
	Sbc840Blackhawktray = 'SBC840BLACKHAWKTRAY',
	Sbc840Mblackhawkmicrobodypack = 'SBC840MBLACKHAWKMICROBODYPACK',
	Unrecognized = 'UNRECOGNIZED'
}

export type CloneRoomInput = {
	cloningStrategy?: InputMaybe<RoomCloningStrategy | `${RoomCloningStrategy}`>;
	description?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	sourceRoom: Scalars['ID']['input'];
};

export enum CloneRoomStatus {
	AudioRouteCloningFailed = 'AUDIO_ROUTE_CLONING_FAILED',
	Canceled = 'CANCELED',
	ControlRouteCloningFailed = 'CONTROL_ROUTE_CLONING_FAILED',
	DeviceCloningFailed = 'DEVICE_CLONING_FAILED',
	InternalPrecondFailed = 'INTERNAL_PRECOND_FAILED',
	OriginRoomCompletePrecondFailed = 'ORIGIN_ROOM_COMPLETE_PRECOND_FAILED',
	OriginRoomConflictPrecondFailed = 'ORIGIN_ROOM_CONFLICT_PRECOND_FAILED',
	OriginRoomExistsprecondFailed = 'ORIGIN_ROOM_EXISTSPRECOND_FAILED',
	OriginRoomUnavailablePrecondFailed = 'ORIGIN_ROOM_UNAVAILABLE_PRECOND_FAILED',
	OrigRoomNonEmptyPrecondFailed = 'ORIG_ROOM_NON_EMPTY_PRECOND_FAILED',
	RoomCloningTimeout = 'ROOM_CLONING_TIMEOUT',
	RoomCreationFailed = 'ROOM_CREATION_FAILED',
	Success = 'SUCCESS'
}

export enum ColorTheme {
	Custom = 'CUSTOM',
	Default = 'DEFAULT',
	Fruity = 'FRUITY',
	Seaside = 'SEASIDE',
	Shure = 'SHURE',
	Space = 'SPACE'
}

/**
 * Describes the state of compatibility between this software, and the discovered device.
 * Different states may indicate a need to upgrade either the device firmware, or this software,
 * in order to use the latest available features.
 */
export enum CompatibilityLevel {
	/** The device firmware is the latest version tested with this software version. */
	CompatibleLatest = 'COMPATIBLE_LATEST',
	/**
	 * The device firmware is ahead of the latest version this software was tested with, but can be completely interacted with.
	 * There may be newer versions of this software which may interact with new features of this device.
	 */
	CompatibleNew = 'COMPATIBLE_NEW',
	/**
	 * The device firmware is behind the latest available, but can be completely interacted with.
	 * There are likely newer versions of firmware available for this device which may have additional features which could be interacted with.
	 * Updating the device's firmware is recommended.
	 */
	CompatibleOld = 'COMPATIBLE_OLD',
	/**
	 * The device firmware is not supported, and therefore no capability interaction is supported.
	 * Update this software to interact with the device.
	 */
	IncompatibleTooNew = 'INCOMPATIBLE_TOO_NEW',
	/**
	 * The device firmware is not supported, and therefore no capability interaction is supported.
	 * Update the device's firmware to interact with the device.
	 */
	IncompatibleTooOld = 'INCOMPATIBLE_TOO_OLD'
}

export enum CompressorLevel {
	Heavy = 'HEAVY',
	Light = 'LIGHT',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export enum ConferencingOperationMode {
	Conference = 'CONFERENCE',
	Custom = 'CUSTOM',
	Direct = 'DIRECT',
	Presentation = 'PRESENTATION'
}

export type ConfigInput8021X =
	| {
			eapMd5: UsernamePassphraseInput;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc: CertificateInput;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2: CertificateInput;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd: UsernamePassphraseInput;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls: TlsCertificateInput;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc: CertificateInput;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5: CertificateInput;
			ttlsMschapv2?: never;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2: CertificateInput;
			ttlsPap?: never;
	  }
	| {
			eapMd5?: never;
			peapGtc?: never;
			peapMschapv2?: never;
			pwd?: never;
			tls?: never;
			ttlsGtc?: never;
			ttlsMd5?: never;
			ttlsMschapv2?: never;
			ttlsPap: CertificateInput;
	  };

export type ConnectedDeviceLabelUpdateInput = {
	label: Scalars['String']['input'];
};

export enum CoverageMode {
	Automatic = 'AUTOMATIC',
	Manual = 'MANUAL'
}

export type CreateCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export type CreateRoomInput = {
	created?: InputMaybe<Scalars['DateTime']['input']>;
	description: Scalars['String']['input'];
	devices: Array<Scalars['ID']['input']>;
	dimensions?: InputMaybe<RoomDimensionsInput>;
	name: Scalars['String']['input'];
};

export enum CredentialsState {
	InitializedNotSet = 'INITIALIZED_NOT_SET',
	Set = 'SET',
	Uninitialized = 'UNINITIALIZED'
}

export type CrossPointConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrossPointFeatureUpdateInput = {
	configuration?: InputMaybe<CrossPointConfigurationUpdateInput>;
	gain?: InputMaybe<CrossPointGainUpdateInput>;
};

export type CrossPointGainUpdateInput = {
	gain?: InputMaybe<Scalars['Float']['input']>;
};

export type CrossPointUpdateInput = {
	features?: InputMaybe<CrossPointFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export enum DanteAudioEncryptionStatus {
	/** Audio encryption is enabled between the routed devices, and the symmetric encryption/decryiption key matches between the routed devices. */
	Active = 'ACTIVE',
	/**
	 * Audio encryption is inactive because of one or more of the following:
	 * 1) It is not enabled on this device, and/or the routed device
	 * 2) There is no route between this audio channel and another Shure device which supports Dante audio encryption.
	 */
	Inactive = 'INACTIVE',
	/**
	 * Audio encryption is enabled on this audio channel, but the symmetric encryption/decryiption does not match between the devices. No audio shall pass.
	 * This state will be represented on the audio receive channel, and not on the transmit audio channel (as the transmit audio channel can go to multiple receive channels, and the key may be a match for other receivers).
	 */
	KeyMismatch = 'KEY_MISMATCH'
}

export type DeactivateLicenseInput = {
	activationKey: Scalars['String']['input'];
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export enum DenoiserLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export enum DeverbIntensity {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export type DeviceAllEqualizersBypassUpdateInput = {
	bypassed: Scalars['Boolean']['input'];
};

export type DeviceAllIntellimixBypassUpdateInput = {
	bypassed: Scalars['Boolean']['input'];
};

export type DeviceAnalogAudioOutputModeUpdateInput = {
	mode: AnalogAudioOutputMode | `${AnalogAudioOutputMode}`;
};

export type DeviceAnalogLogicPresetSwitchUpdateInput = {
	mode: AnalogLogicPresetSwitch | `${AnalogLogicPresetSwitch}`;
};

export type DeviceAnalogLogicUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAntennaBiasVoltageUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAssociationInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

export type DeviceAudioAutoFocusUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAudioChannelReassignmentInput = {
	/** The reassignment operation to execute (Replace, Swap) */
	operation: AudioChannelReassignmentOperation | `${AudioChannelReassignmentOperation}`;
	/** Audio channel which will act as the source for the reassignment */
	sourceId: Scalars['ID']['input'];
	/** Audio channel which will act as the target for the reassignment */
	targetId: Scalars['ID']['input'];
};

export type DeviceAudioMuteControlUpdateInput = {
	function?: InputMaybe<MuteControlFunction | `${MuteControlFunction}`>;
	mode?: InputMaybe<MuteButtonMode | `${MuteButtonMode}`>;
};

export type DeviceAudioMuteDefaultUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceAudioMuteLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAudioMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceAutomixGainMeteringUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceBatteryStorageModeUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceBluetoothDeviceInformationServiceUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceBodypackMicrophoneModeUpdateInput = {
	mode: BodypackMicrophoneMode | `${BodypackMicrophoneMode}`;
};

export type DeviceCallStatusUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceCommandStringsServiceUpdateInput = {
	enabled: Scalars['Boolean']['input'];
	port?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceConferencingOperationModeButtonLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceConferencingOperationModeUpdateInput = {
	mode: ConferencingOperationMode | `${ConferencingOperationMode}`;
};

export type DeviceConfigurationLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceCoverageLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceCoverageModeUpdateInput = {
	coverageMode: CoverageMode | `${CoverageMode}`;
};

export type DeviceCoveragePositionInput = {
	xPosition: Scalars['Float']['input'];
	yPosition: Scalars['Float']['input'];
};

export type DeviceDanteAudioNetworkUpdateInput = {
	name: Scalars['String']['input'];
};

export type DeviceDeploymentInput = {
	/** Determines the name of the target device after deployment. If not set, the target device name will not change. */
	deviceName?: InputMaybe<Scalars['String']['input']>;
	sourceDevice: Scalars['ID']['input'];
	/**
	 * Syncs the Shure device and Channel names with the Dante device and channel names.
	 * If not set, Dante names on the target device will remain the same.
	 */
	syncDanteNamesWithShureNames?: InputMaybe<Scalars['Boolean']['input']>;
	targetDevice?: InputMaybe<Scalars['ID']['input']>;
};

export enum DeviceDeploymentStage {
	/** Inter-device deployment(Eg: Routing) */
	AssociationDeployment = 'ASSOCIATION_DEPLOYMENT',
	/** Generic handling of a device(Eg: Name) */
	GenericDeployment = 'GENERIC_DEPLOYMENT',
	/** Specialized handling of a device */
	SpecificDeployment = 'SPECIFIC_DEPLOYMENT'
}

export type DeviceDisassociationInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

export type DeviceDnsClientConfigurationInput = {
	/**
	 * The first array item is the primary server IP address
	 * and the second array item is the secondary server IP address
	 * Missing any array item or providing more than 2 items will cause INVALID_INPUT error.
	 * This field is required for MANUAL mode
	 */
	manualConfiguration?: InputMaybe<Array<Scalars['IpAddress']['input']>>;
	mode: DeviceDnsClientConfigurationMode | `${DeviceDnsClientConfigurationMode}`;
};

export enum DeviceDnsClientConfigurationMode {
	Automatic = 'AUTOMATIC',
	Manual = 'MANUAL'
}

export type DeviceEqualizerContourUpdateInput = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type DeviceEthernetLightsUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceFeatureUpdateInput =
	| {
			allEqualizersBypass: DeviceAllEqualizersBypassUpdateInput;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass: DeviceAllIntellimixBypassUpdateInput;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode: DeviceAnalogAudioOutputModeUpdateInput;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic: DeviceAnalogLogicUpdateInput;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch: DeviceAnalogLogicPresetSwitchUpdateInput;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage: DeviceAntennaBiasVoltageUpdateInput;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus: DeviceAudioAutoFocusUpdateInput;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute: DeviceAudioMuteUpdateInput;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl: DeviceAudioMuteControlUpdateInput;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault: DeviceAudioMuteDefaultUpdateInput;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock: DeviceAudioMuteLockUpdateInput;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering: DeviceAutomixGainMeteringUpdateInput;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode: DeviceBatteryStorageModeUpdateInput;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService: DeviceBluetoothDeviceInformationServiceUpdateInput;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode: DeviceBodypackMicrophoneModeUpdateInput;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus: DeviceCallStatusUpdateInput;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService: DeviceCommandStringsServiceUpdateInput;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode: DeviceConferencingOperationModeUpdateInput;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock: DeviceConferencingOperationModeButtonLockUpdateInput;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock: DeviceConfigurationLockUpdateInput;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel: ConnectedDeviceLabelUpdateInput;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock: DeviceCoverageLockUpdateInput;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode: DeviceCoverageModeUpdateInput;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition: DeviceCoveragePositionInput;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork: DeviceDanteAudioNetworkUpdateInput;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration: DeviceDnsClientConfigurationInput;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour: DeviceEqualizerContourUpdateInput;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights: DeviceEthernetLightsUpdateInput;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy: DeviceFirmwareUpdatePolicyUpdateInput;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height: DeviceHeightUpdateInput;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings: DeviceInternetOfThingsUpdateInput;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity: DeviceLightingAudioActivityUpdateInput;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters: DeviceLightingAudioMetersUpdateInput;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride: DeviceLightingAudioMuteIndicatorOverrideUpdateInput;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating: DeviceLightingAutomixGatingUpdateInput;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters: DeviceLightingLiveAudioMetersUpdateInput;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior: DeviceLightingBehaviorUpdateInput;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing: DeviceLightingRingUpdateInput;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor: DeviceLightingSplitModeMeterColorUpdateInput;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor: DeviceLightingSplitModeMeterColorUpdateInput;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode: DeviceLightingTouchPanelModeUpdateInput;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior: DeviceLightingBehaviorUpdateInput;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock: DeviceLinkButtonLockUpdateInput;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight: DeviceListenerHeightUpdateInput;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute: DeviceLogicMuteUpdateInput;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver: DeviceLogicReceiverUpdateInput;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker: DeviceLoudspeakerUpdateInput;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService: DeviceMdnsServiceUpdateInput;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes: DeviceMeteringModesUpdateInput;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus: DeviceMicStatusUpdateInput;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name: DeviceNameUpdateInput;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement: DevicePlacementUpdateInput;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode: DeviceRfDensityModeUpdateInput;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower: DeviceRfPowerUpdateInput;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation: DeviceRotationUpdateInput;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition: DeviceRoutingPositionInput;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate: DeviceSmartGateUpdateInput;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio: DeviceStereoAudioUpdateInput;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior: DeviceTransmitterIdentifyBehaviorInput;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger: DeviceTransmitterInitialStateFromChargerInput;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior: DeviceTransmitterLightingBehaviorUpdateInput;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior: DeviceTransmitterMuteBehaviorUpdateInput;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior: DeviceTransmitterOutOfRangeBehaviorInput;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior: DeviceTransmitterRejoinBehaviorInput;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior: DeviceTransmitterSwitchBehaviorUpdateInput;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync: DeviceUsbMuteSyncUpdateInput;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType: DeviceUsbTerminalTypeUpdateInput;
			virtualAcousticBoundary?: never;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary: DeviceVirtualAcousticBoundaryUpdateInput;
			webService?: never;
	  }
	| {
			allEqualizersBypass?: never;
			allIntellimixBypass?: never;
			analogAudioOutputMode?: never;
			analogLogic?: never;
			analogLogicPresetSwitch?: never;
			antennaBiasVoltage?: never;
			audioAutoFocus?: never;
			audioMute?: never;
			audioMuteControl?: never;
			audioMuteDefault?: never;
			audioMuteLock?: never;
			automixGainMetering?: never;
			batteryStorageMode?: never;
			bluetoothDeviceInformationService?: never;
			bodypackMicrophoneMode?: never;
			callStatus?: never;
			commandStringsService?: never;
			conferencingOperationMode?: never;
			conferencingOperationModeButtonLock?: never;
			configurationLock?: never;
			connectedDeviceLabel?: never;
			coverageLock?: never;
			coverageMode?: never;
			coveragePosition?: never;
			danteAudioNetwork?: never;
			dnsClientConfiguration?: never;
			equalizerContour?: never;
			ethernetLights?: never;
			firmwareUpdatePolicy?: never;
			height?: never;
			internetOfThings?: never;
			lightingAudioActivity?: never;
			lightingAudioMeters?: never;
			lightingAudioMuteIndicatorOverride?: never;
			lightingAutomixGating?: never;
			lightingLiveAudioMeters?: never;
			lightingMuteBehavior?: never;
			lightingRing?: never;
			lightingSplitModeBreathingMeterColor?: never;
			lightingSplitModeSolidMeterColor?: never;
			lightingTouchPanelMode?: never;
			lightingUnmuteBehavior?: never;
			linkButtonLock?: never;
			listenerHeight?: never;
			logicMute?: never;
			logicReceiver?: never;
			loudspeaker?: never;
			mdnsService?: never;
			meteringModes?: never;
			micStatus?: never;
			name?: never;
			placement?: never;
			rfDensityMode?: never;
			rfPower?: never;
			rotation?: never;
			routingPosition?: never;
			smartGate?: never;
			stereoAudio?: never;
			transmitterIdentifyBehavior?: never;
			transmitterInitialStateFromCharger?: never;
			transmitterLightingBehavior?: never;
			transmitterMuteBehavior?: never;
			transmitterOutOfRangeBehavior?: never;
			transmitterRejoinBehavior?: never;
			transmitterSwitchBehavior?: never;
			usbMuteSync?: never;
			usbTerminalType?: never;
			virtualAcousticBoundary?: never;
			webService: DeviceWebServiceUpdateInput;
	  };

export type DeviceFirmwareUpdatePolicyUpdateInput = {
	downgradeAllowed: Scalars['Boolean']['input'];
};

export type DeviceGlobalMicStatusUpdateInput = {
	id: Scalars['ID']['input'];
	status: GlobalMicStatusInput | `${GlobalMicStatusInput}`;
};

export type DeviceHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type DeviceInternetOfThingsUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingAudioActivityUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingAudioMetersUpdateInput = {
	breathingMeterColor?: InputMaybe<MeterColorThemeInfoInput>;
	liveMeterColor?: InputMaybe<LiveMeterColorThemeInfoInput>;
	mode?: InputMaybe<AudioMetersLightingMode | `${AudioMetersLightingMode}`>;
	solidMeterColor?: InputMaybe<MeterColorThemeInfoInput>;
};

export type DeviceLightingAudioMuteIndicatorOverrideUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingAutomixGatingUpdateInput = {
	automixerInputDisengagedBehavior?: InputMaybe<
		AutomixerInputDisengagedBehavior | `${AutomixerInputDisengagedBehavior}`
	>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceLightingBehaviorUpdateInput = {
	color?: InputMaybe<LightingColor | `${LightingColor}`>;
	mode?: InputMaybe<LightingMode | `${LightingMode}`>;
};

export type DeviceLightingBrightnessUpdateInput = {
	id: Scalars['ID']['input'];
	level: Scalars['Int']['input'];
};

export type DeviceLightingLiveAudioMetersUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingRingUpdateInput = {
	style?: InputMaybe<LightingStyle | `${LightingStyle}`>;
};

export type DeviceLightingSplitModeMeterColorUpdateInput = {
	auxiliaryCustomThemeLightColor?: InputMaybe<RgbColorMixInput>;
	primaryCustomThemeLightColor?: InputMaybe<RgbColorMixInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export type DeviceLightingTouchPanelModeUpdateInput = {
	automaticModeChange?: InputMaybe<Scalars['Boolean']['input']>;
	mode?: InputMaybe<LightingTouchPanelMode | `${LightingTouchPanelMode}`>;
};

export type DeviceLinkButtonLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLinkingInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

export type DeviceListenerHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type DeviceLogicMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceLogicReceiverUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLoudspeakerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceMdnsServiceUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceMeteringModesUpdateInput = {
	inputMode?: InputMaybe<MeteringMode | `${MeteringMode}`>;
	outputMode?: InputMaybe<MeteringMode | `${MeteringMode}`>;
};

export type DeviceMicStatusUpdateInput = {
	status: MicStatusInput | `${MicStatusInput}`;
};

export type DeviceNameUpdateInput = {
	name: Scalars['String']['input'];
};

export enum DeviceOptimizationStatus {
	Conflict = 'CONFLICT',
	DeviceUnavailable = 'DEVICE_UNAVAILABLE',
	Failure = 'FAILURE',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS',
	Unsupported = 'UNSUPPORTED'
}

export type DevicePlacementUpdateInput = {
	placement: Placement | `${Placement}`;
};

export type DevicePropertyFilter = {
	get?: InputMaybe<Scalars['Boolean']['input']>;
	propertyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
	public?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DeviceResetScope {
	/** use audio channel id as request id for resetDevice mutation */
	Automixer = 'AUTOMIXER',
	/** use device id as request id for resetDevice mutation */
	CoverageAreas = 'COVERAGE_AREAS',
	/** use audio channel id as request id for resetDevice mutation */
	EqualizerFilters = 'EQUALIZER_FILTERS',
	/** use device id as request id for resetDevice mutation */
	Factory = 'FACTORY',
	/** use device id as request id for resetDevice mutation */
	Networks = 'NETWORKS',
	/** use device id as request id for resetDevice mutation */
	PreFadeListen = 'PRE_FADE_LISTEN',
	/** use device id as request id for resetDevice mutation */
	UserPresetable = 'USER_PRESETABLE'
}

export type DeviceRfDensityModeUpdateInput = {
	mode: RfDensityMode | `${RfDensityMode}`;
};

export type DeviceRfPowerUpdateInput = {
	level: RfPower | `${RfPower}`;
};

export type DeviceRotationUpdateInput = {
	xAxis?: InputMaybe<Scalars['Int']['input']>;
	yAxis?: InputMaybe<Scalars['Int']['input']>;
	zAxis?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceRoutingPositionInput = {
	xPosition: Scalars['Float']['input'];
	yPosition: Scalars['Float']['input'];
};

export type DeviceSmartGateUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export enum DeviceSpecificEqualizerContour {
	Mxa310 = 'MXA310',
	Mxa710LowShelf = 'MXA710_LOW_SHELF',
	Mxa901HighPass = 'MXA901_HIGH_PASS',
	Mxa902HighPass = 'MXA902_HIGH_PASS',
	Mxa910 = 'MXA910',
	Mxa910HighPass = 'MXA910_HIGH_PASS',
	Mxa910MultiBand = 'MXA910_MULTI_BAND',
	Mxa920HighPass = 'MXA920_HIGH_PASS',
	Off = 'OFF'
}

export enum DeviceStatus {
	/**
	 * The device was discovered by a particular communication protocol,
	 * but is yet not ready to be interacted with. This state is normally transient.
	 *
	 * Factory reset and reboot operations are allowed if applicable, but with no guarantees of success.
	 */
	Discovered = 'DISCOVERED',
	/**
	 * The device was discovered, but is in an unusable state for further feature interaction.
	 *
	 * Factory reset and reboot operations are allowed if applicable, but with no guarantees of success.
	 */
	Error = 'ERROR',
	/**
	 * A connection can no logner be established with the device.
	 *
	 * This state may be transient when performing an operation which reboots the device.
	 * (e.g. factory reset, rebooting the ethernet switch a device is powered by, etc)
	 */
	Offline = 'OFFLINE',
	/** The device and all of its features can be interacted with */
	Online = 'ONLINE'
}

export type DeviceStereoAudioUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceSwitchConfigurationUpdateInput = {
	id: Scalars['ID']['input'];
	mode: NetworkMode | `${NetworkMode}`;
};

export type DeviceTransmitterIdentifyAlarmInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterIdentifyBehaviorInput = {
	alarm?: InputMaybe<DeviceTransmitterIdentifyAlarmInput>;
};

export type DeviceTransmitterInitialStateFromChargerInput = {
	bodypack?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	boundary?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	gooseneck?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	handheld?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
};

export type DeviceTransmitterLightingBehaviorUpdateInput = {
	behavior: TransmitterLightingBehavior | `${TransmitterLightingBehavior}`;
};

export type DeviceTransmitterMuteBehaviorUpdateInput = {
	muteBehavior?: InputMaybe<TransmitterMuteBehavior | `${TransmitterMuteBehavior}`>;
	usbHostDisconnectMuteBehavior?: InputMaybe<
		TransmitterUsbHostDisconnectMuteBehavior | `${TransmitterUsbHostDisconnectMuteBehavior}`
	>;
};

export type DeviceTransmitterOutOfRangeAlarmInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterOutOfRangeBehaviorInput = {
	alarm?: InputMaybe<DeviceTransmitterOutOfRangeAlarmInput>;
};

export type DeviceTransmitterRejoinAudioMuteInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterRejoinBehaviorInput = {
	audioMute?: InputMaybe<DeviceTransmitterRejoinAudioMuteInput>;
};

export type DeviceTransmitterSwitchBehaviorUpdateInput = {
	bodypack?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	boundary?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	gooseneck?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	handheld?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
};

export type DeviceUpdateInput = {
	features?: InputMaybe<DeviceFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type DeviceUsbMuteSyncUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceUsbTerminalTypeUpdateInput = {
	terminalType: UsbTerminalType | `${UsbTerminalType}`;
};

export type DeviceVirtualAcousticBoundaryUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	sensitivity?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceWebServiceUpdateInput = {
	/** When the Web Service is disabled, the device api services are also disabled */
	deviceRestApi?: InputMaybe<Scalars['Boolean']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DirectOutputTapPoint {
	/** Sends a signal with automixer gating, AEC, and noise reduction, but without AGC, to the matrix mixer. */
	PostProcessingPostGate = 'POST_PROCESSING_POST_GATE',
	/** Sends a signal with AEC and noise reduction, but without automixer gating or AGC, to the matrix mixer. */
	PostProcessingPreGate = 'POST_PROCESSING_PRE_GATE',
	/** Sends a signal with automixer gating, but without AEC, noise reduction, or AGC, to the matrix mixer. */
	PreProcessingPostGate = 'PRE_PROCESSING_POST_GATE',
	/** Sends a signal without AEC, noise reduction, or AGC to the matrix mixer. */
	PreProcessingPreGate = 'PRE_PROCESSING_PRE_GATE'
}

export enum DisassociationStatus {
	/** Failed to disassociate RF Channel and Charger Bay */
	Failure = 'FAILURE',
	/** Input node id(s) provided in association request is not valid */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input device(s) either does not support association feature or not a valid input for this operation */
	InvalidOperation = 'INVALID_OPERATION',
	/** Association is cleared successfully on Charger Bay */
	PartialSuccessChargerBay = 'PARTIAL_SUCCESS_CHARGER_BAY',
	/** Association is cleared successfully on RF Channel */
	PartialSuccessRfChannel = 'PARTIAL_SUCCESS_RF_CHANNEL',
	/** Successfully disassociated RF Channel and Charger Bay */
	Success = 'SUCCESS'
}

export enum DoubleStuffProxiedTransmitterStatus {
	Active = 'ACTIVE',
	Charging = 'CHARGING',
	Unknown = 'UNKNOWN'
}

export enum EapMethod8021X {
	Disabled = 'DISABLED',
	EapMd5 = 'EAP_MD5',
	PeapGtc = 'PEAP_GTC',
	PeapMschapv2 = 'PEAP_MSCHAPV2',
	Pwd = 'PWD',
	Tls = 'TLS',
	TtlsGtc = 'TTLS_GTC',
	TtlsMd5 = 'TTLS_MD5',
	TtlsMschapv2 = 'TTLS_MSCHAPV2',
	TtlsPap = 'TTLS_PAP'
}

export enum EqFilterType {
	/** Rolls off the audio signal above the selected frequency */
	HighCut = 'HIGH_CUT',
	/** Attenuates or boosts the audio signal above the selected frequency */
	HighShelf = 'HIGH_SHELF',
	/** Rolls off the audio signal below the selected frequency */
	LowCut = 'LOW_CUT',
	/** Attenuates or boost the audio signal below the selected frequency */
	LowShelf = 'LOW_SHELF',
	/** Attenuates or boost the audio signal within a customizable frequency range */
	Parametric = 'PARAMETRIC'
}

export enum EqualizerContour {
	HighPass = 'HIGH_PASS',
	HighPassAndPresenceBoost = 'HIGH_PASS_AND_PRESENCE_BOOST',
	Hpf_75Hz = 'HPF_75_HZ',
	Hpf_150Hz = 'HPF_150_HZ',
	LowShelf = 'LOW_SHELF',
	MultiBand = 'MULTI_BAND',
	Off = 'OFF',
	PresenceBoost = 'PRESENCE_BOOST'
}

export type EqualizerFilterBandwidthUpdateInput = {
	denominator: Scalars['Int']['input'];
	numerator: Scalars['Int']['input'];
};

export type EqualizerFilterConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	filterType?: InputMaybe<EqFilterType | `${EqFilterType}`>;
};

export type EqualizerFilterFeatureUpdateInput =
	| {
			bandwidth: EqualizerFilterBandwidthUpdateInput;
			configuration?: never;
			frequency?: never;
			gain?: never;
			qFactor?: never;
	  }
	| {
			bandwidth?: never;
			configuration: EqualizerFilterConfigurationUpdateInput;
			frequency?: never;
			gain?: never;
			qFactor?: never;
	  }
	| {
			bandwidth?: never;
			configuration?: never;
			frequency: EqualizerFilterFrequencyUpdateInput;
			gain?: never;
			qFactor?: never;
	  }
	| {
			bandwidth?: never;
			configuration?: never;
			frequency?: never;
			gain: EqualizerFilterGainUpdateInput;
			qFactor?: never;
	  }
	| {
			bandwidth?: never;
			configuration?: never;
			frequency?: never;
			gain?: never;
			qFactor: EqualizerFilterQFactorUpdateInput;
	  };

export type EqualizerFilterFrequencyUpdateInput = {
	frequency?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterGainUpdateInput = {
	gain?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterQFactorUpdateInput = {
	qFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterUpdateInput = {
	features?: InputMaybe<EqualizerFilterFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export enum EqualizerPreset {
	Acoustic = 'ACOUSTIC',
	Band = 'BAND',
	Flat = 'FLAT',
	Singing = 'SINGING',
	Speech = 'SPEECH'
}

export enum ErrorCode {
	Conflict = 'CONFLICT',
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	DeviceUnauthenticated = 'DEVICE_UNAUTHENTICATED',
	InvalidInput = 'INVALID_INPUT',
	InvalidOperation = 'INVALID_OPERATION',
	NotImplemented = 'NOT_IMPLEMENTED',
	TimeoutExceeded = 'TIMEOUT_EXCEEDED',
	Unknown = 'UNKNOWN'
}

export enum ErrorIndicator {
	ClearableError = 'CLEARABLE_ERROR',
	NonClearableError = 'NON_CLEARABLE_ERROR',
	NoError = 'NO_ERROR'
}

export enum EthernetPowerSourceType {
	Poe = 'POE',
	PoePlus = 'POE_PLUS',
	PoePlusPlus = 'POE_PLUS_PLUS',
	Unknown = 'UNKNOWN'
}

export enum FirmwareUpdateRequestState {
	/** The firmware update request was canceled. */
	Canceled = 'CANCELED',
	/** The firmware update request failed. */
	Failed = 'FAILED',
	/** The firmware update request is in progress. */
	InProgress = 'IN_PROGRESS',
	/** The firmware update request was received, but has not started. */
	Pending = 'PENDING',
	/** The firmware update request is complete. */
	Successful = 'SUCCESSFUL'
}

export enum FirmwareUpdateStage {
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** The firmware update is complete. */
	Complete = 'COMPLETE',
	/** The device is being reserved for a firmware update. */
	DeviceReserved = 'DEVICE_RESERVED',
	/** An error occurred during the firmware update. */
	Error = 'ERROR',
	/** The device is available for a firmware update. */
	Idle = 'IDLE',
	/** The device is installing the firmware package. */
	Installing = 'INSTALLING',
	/** The request to update the device was received, but has not started yet. */
	Pending = 'PENDING',
	/** The firmware package is being validated by the device before installation. */
	PreInstallValidating = 'PRE_INSTALL_VALIDATING',
	/** The device is rebooting. */
	Rebooting = 'REBOOTING',
	/** The firmware package is being transferred to the device. */
	TransferringFiles = 'TRANSFERRING_FILES',
	/** The device is verifying that the firmware package was installed correctly. */
	Verifying = 'VERIFYING'
}

export enum FirmwareUpdateStatus {
	/** The device aborted the firmware update. */
	Aborted = 'ABORTED',
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** There was a connectivity issue. */
	ConnectivityLoss = 'CONNECTIVITY_LOSS',
	/** The firmware package was corrupted locally or in transfer to the device. */
	CorruptPackage = 'CORRUPT_PACKAGE',
	/** The device is unavailable for a firmware update. */
	DeviceBusy = 'DEVICE_BUSY',
	/** The device cannot be found. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	DeviceRejectedPackage = 'DEVICE_REJECTED_PACKAGE',
	/** An error occurred while transferring the firmware package to the device. */
	FileTransferError = 'FILE_TRANSFER_ERROR',
	/** An error occurred while the device was installing the firmware package. */
	InstallationError = 'INSTALLATION_ERROR',
	/** The device does not support the specified firmware package. */
	InvalidPackage = 'INVALID_PACKAGE',
	/** The firmware update is complete. */
	Success = 'SUCCESS',
	/** An unknown error occurred. */
	UnknownError = 'UNKNOWN_ERROR',
	/** An error occurred while the device was verifying the installation. */
	VerificationError = 'VERIFICATION_ERROR'
}

export type FloatPositionUpdateInput = {
	xPosition?: InputMaybe<Scalars['Float']['input']>;
	yPosition?: InputMaybe<Scalars['Float']['input']>;
	zPosition?: InputMaybe<Scalars['Float']['input']>;
};

export enum GlobalMicStatus {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	NonUniform = 'NON_UNIFORM',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export enum GlobalMicStatusInput {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export enum GroupAssociationStatus {
	AllAssociationsUnsuccessful = 'ALL_ASSOCIATIONS_UNSUCCESSFUL',
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	IncompatibleDevice = 'INCOMPATIBLE_DEVICE',
	NoApplicableRoutesForStereoAecProcessor = 'NO_APPLICABLE_ROUTES_FOR_STEREO_AEC_PROCESSOR',
	Success = 'SUCCESS',
	SuccessWithWarnings = 'SUCCESS_WITH_WARNINGS',
	Timeout = 'TIMEOUT'
}

export enum GroupAssociationWarning {
	AllNetworkAudioAssociationsUnsuccessful = 'ALL_NETWORK_AUDIO_ASSOCIATIONS_UNSUCCESSFUL',
	NonImxMixOutputsUsedToFitDevices = 'NON_IMX_MIX_OUTPUTS_USED_TO_FIT_DEVICES',
	NoApplicableRoutesForStereoAecProcessor = 'NO_APPLICABLE_ROUTES_FOR_STEREO_AEC_PROCESSOR',
	SomeLinkingUnsuccessful = 'SOME_LINKING_UNSUCCESSFUL',
	SomeMxwnAssociationsUnsuccessful = 'SOME_MXWN_ASSOCIATIONS_UNSUCCESSFUL',
	SomeNetworkAudioAssociationsUnsuccessful = 'SOME_NETWORK_AUDIO_ASSOCIATIONS_UNSUCCESSFUL',
	TooManyAccessPoints = 'TOO_MANY_ACCESS_POINTS',
	TooManyAmplifiers = 'TOO_MANY_AMPLIFIERS',
	TooManyChargers = 'TOO_MANY_CHARGERS',
	TooManyMicrophones = 'TOO_MANY_MICROPHONES',
	TooManyMicrophonesForStereoAecProcessor = 'TOO_MANY_MICROPHONES_FOR_STEREO_AEC_PROCESSOR',
	TooManyProcessors = 'TOO_MANY_PROCESSORS'
}

export enum GroupOptimizationStatus {
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	NoDevicesToOptimize = 'NO_DEVICES_TO_OPTIMIZE',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS'
}

export enum IpMode {
	Dhcp = 'DHCP',
	Static = 'STATIC'
}

export type KeyringInput = {
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export enum LastAuthenticationStatus8021X {
	Failure = 'FAILURE',
	NeverAttempted = 'NEVER_ATTEMPTED',
	Success = 'SUCCESS'
}

export enum LicenseActivationState {
	Activated = 'ACTIVATED',
	Canceled = 'CANCELED',
	HostMismatchError = 'HOST_MISMATCH_ERROR',
	InsufficientFailure = 'INSUFFICIENT_FAILURE',
	InternalError = 'INTERNAL_ERROR',
	InvalidConfigurationFailure = 'INVALID_CONFIGURATION_FAILURE',
	KeyNotfoundFailure = 'KEY_NOTFOUND_FAILURE',
	NetworkFailure = 'NETWORK_FAILURE',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING',
	PendingInsufficient = 'PENDING_INSUFFICIENT',
	PendingNetwork = 'PENDING_NETWORK'
}

export enum LicenseCancelationState {
	Canceled = 'CANCELED',
	Failed = 'FAILED',
	InternalError = 'INTERNAL_ERROR',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING'
}

export enum LicenseDeactivationState {
	Deactivated = 'DEACTIVATED',
	InternalError = 'INTERNAL_ERROR',
	NetworkFailure = 'NETWORK_FAILURE',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING'
}

export enum LicenseState {
	Clockwindback = 'CLOCKWINDBACK',
	Expired = 'EXPIRED',
	FailedActivationKeyError = 'FAILED_ACTIVATION_KEY_ERROR',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	PendingActivationInsufficient = 'PENDING_ACTIVATION_INSUFFICIENT',
	PendingActivationNetwork = 'PENDING_ACTIVATION_NETWORK',
	PendingActivationOffline = 'PENDING_ACTIVATION_OFFLINE',
	PendingDeactivation = 'PENDING_DEACTIVATION',
	PendingExpiration = 'PENDING_EXPIRATION',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}

export enum LicenseStateV2 {
	Clockwindback = 'CLOCKWINDBACK',
	Expired = 'EXPIRED',
	FailedActivationKeyError = 'FAILED_ACTIVATION_KEY_ERROR',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	PendingActivationInsufficient = 'PENDING_ACTIVATION_INSUFFICIENT',
	PendingActivationNetwork = 'PENDING_ACTIVATION_NETWORK',
	PendingActivationOffline = 'PENDING_ACTIVATION_OFFLINE',
	PendingDeactivation = 'PENDING_DEACTIVATION',
	PendingExpiration = 'PENDING_EXPIRATION',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}

export enum LicenseStateV3 {
	Expired = 'EXPIRED',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	Valid = 'VALID'
}

export enum LicenseSystemCommunicationState {
	Failure_4XxClientError = 'FAILURE_4XX_CLIENT_ERROR',
	Failure_5XxServerError = 'FAILURE_5XX_SERVER_ERROR',
	FailureTimeout = 'FAILURE_TIMEOUT',
	FailureUnknown = 'FAILURE_UNKNOWN',
	NotAttempted = 'NOT_ATTEMPTED',
	Success = 'SUCCESS'
}

export enum LicenseSystemIntegrityState {
	ClockWindback = 'CLOCK_WINDBACK',
	HostIdentityMismatch = 'HOST_IDENTITY_MISMATCH',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}

export enum LicenseType {
	Demo = 'DEMO',
	Paid = 'PAID',
	Trial = 'TRIAL'
}

export enum LicenseTypeV2 {
	Demo = 'DEMO',
	Paid = 'PAID',
	Trial = 'TRIAL'
}

export enum LicenseTypeV3 {
	Demo = 'DEMO',
	Paid = 'PAID',
	Trial = 'TRIAL'
}

export enum LightingColor {
	Blue = 'BLUE',
	Cyan = 'CYAN',
	Gold = 'GOLD',
	Green = 'GREEN',
	LightPurple = 'LIGHT_PURPLE',
	Orange = 'ORANGE',
	Orchid = 'ORCHID',
	Pink = 'PINK',
	PowderBlue = 'POWDER_BLUE',
	Purple = 'PURPLE',
	Red = 'RED',
	SkyBlue = 'SKY_BLUE',
	Turquoise = 'TURQUOISE',
	Violet = 'VIOLET',
	White = 'WHITE',
	Yellow = 'YELLOW',
	YellowGreen = 'YELLOW_GREEN'
}

export enum LightingMode {
	Flashing = 'FLASHING',
	Off = 'OFF',
	On = 'ON'
}

export enum LightingState {
	Muted = 'MUTED',
	Unmuted = 'UNMUTED'
}

export enum LightingStyle {
	Ring = 'RING',
	Segment = 'SEGMENT'
}

export enum LightingTouchPanelMode {
	Combined = 'COMBINED',
	Split = 'SPLIT'
}

export enum LinkingOperationResult {
	/** Device is not discoverable. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	/** Failed to link RF channel(s) and transmitter(s) */
	Failure = 'FAILURE',
	/** Input node id(s) provided in linking request is not valid. */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support linking feature or not a valid input for this operation. */
	InvalidOperation = 'INVALID_OPERATION',
	/** No associations exist on RF channel(s) or charger bay(s). */
	NoAssociationsExist = 'NO_ASSOCIATIONS_EXIST',
	/** No transmitter is discovered for the charger bay. */
	NoTransmittersOnCharger = 'NO_TRANSMITTERS_ON_CHARGER',
	/** Successfully linked RF channel(s) and transmitter(s) */
	Success = 'SUCCESS'
}

export enum LinkingStatus {
	/**
	 * An unknown reason caused the failure.
	 * This means the middleware receive a linking result that is not expected such as BAN_SECONDARY_MIC or INVALID_APT_CHANNEL
	 */
	Failure = 'FAILURE',
	/** Linking failed due to audio protocol mismatch */
	FailureAudioProtocolMismatch = 'FAILURE_AUDIO_PROTOCOL_MISMATCH',
	/**
	 * Linking failed due to mismatched access point/charger/transmitter firmware version
	 * Major and minor version of firmware for devices should be matched to perform linking operation
	 */
	FailureFirmwareVersionMismatch = 'FAILURE_FIRMWARE_VERSION_MISMATCH',
	/** Linking failed due to RF band mismatch */
	FailureRfBandMismatch = 'FAILURE_RF_BAND_MISMATCH',
	/** Successfully linked RF channel and transmitter */
	Success = 'SUCCESS',
	/** Failed to receive linking result within the timeout */
	TimeoutExceeded = 'TIMEOUT_EXCEEDED'
}

export type LiveMeterColorThemeInfoInput = {
	customThemeLightColorGradient?: InputMaybe<LiveMeterCustomThemeLightColorGradientInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export type LiveMeterCustomThemeLightColorGradientInput = {
	centerLightColor?: InputMaybe<RgbColorMixInput>;
	clippingLightColor?: InputMaybe<RgbColorMixInput>;
	outermostLightColor?: InputMaybe<RgbColorMixInput>;
};

export enum LobeWidth {
	Medium = 'MEDIUM',
	Narrow = 'NARROW',
	Wide = 'WIDE'
}

export enum Loudspeaker {
	Generic = 'GENERIC',
	Mxp_1 = 'MXP_1',
	Mxp_3 = 'MXP_3',
	Mxp_5 = 'MXP_5',
	Mxp_6 = 'MXP_6'
}

export type MacAndSerialClaimInput = {
	macAddress: Scalars['String']['input'];
	serialNumber: Scalars['String']['input'];
};

export enum MeterChangeType {
	AudioChannelAcousticEchoCancellationErle = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_ERLE',
	AudioChannelAcousticEchoCancellationPeak = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_PEAK',
	AudioChannelAcousticEchoCancellationRms = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_RMS',
	AudioChannelActivity = 'AUDIO_CHANNEL_ACTIVITY',
	AudioChannelAutomaticGain = 'AUDIO_CHANNEL_AUTOMATIC_GAIN',
	AudioChannelAutomaticGainControlPeak = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL_PEAK',
	AudioChannelAutomaticGainControlRms = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL_RMS',
	AudioChannelAutomixerGate = 'AUDIO_CHANNEL_AUTOMIXER_GATE',
	AudioChannelAutomixerInputPeak = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_PEAK',
	AudioChannelAutomixerInputRms = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_RMS',
	AudioChannelAutomixGain = 'AUDIO_CHANNEL_AUTOMIX_GAIN',
	AudioChannelClip = 'AUDIO_CHANNEL_CLIP',
	AudioChannelCompressorEngaged = 'AUDIO_CHANNEL_COMPRESSOR_ENGAGED',
	AudioChannelDenoiserNoiseReduction = 'AUDIO_CHANNEL_DENOISER_NOISE_REDUCTION',
	AudioChannelLimiterEngaged = 'AUDIO_CHANNEL_LIMITER_ENGAGED',
	AudioChannelMatrixMixerInputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_INPUT_PEAK',
	AudioChannelMatrixMixerInputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_INPUT_RMS',
	AudioChannelMatrixMixerOutputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_OUTPUT_PEAK',
	AudioChannelMatrixMixerOutputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_OUTPUT_RMS',
	AudioChannelMatrixMixerStereoInputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_STEREO_INPUT_PEAK',
	AudioChannelMatrixMixerStereoInputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_STEREO_INPUT_RMS',
	AudioChannelPeak = 'AUDIO_CHANNEL_PEAK',
	AudioChannelPostGateGainStereoPeak = 'AUDIO_CHANNEL_POST_GATE_GAIN_STEREO_PEAK',
	AudioChannelPostGateGainStereoRms = 'AUDIO_CHANNEL_POST_GATE_GAIN_STEREO_RMS',
	AudioChannelRms = 'AUDIO_CHANNEL_RMS',
	AudioChannelStereoAutomixerGate = 'AUDIO_CHANNEL_STEREO_AUTOMIXER_GATE',
	AudioChannelStereoAutomixGain = 'AUDIO_CHANNEL_STEREO_AUTOMIX_GAIN',
	AudioChannelStereoPeak = 'AUDIO_CHANNEL_STEREO_PEAK',
	AudioChannelStereoRms = 'AUDIO_CHANNEL_STEREO_RMS',
	AudioCoverageAreaAutomixerGate = 'AUDIO_COVERAGE_AREA_AUTOMIXER_GATE',
	AudioCoverageAreaPeak = 'AUDIO_COVERAGE_AREA_PEAK',
	AudioCoverageAreaRms = 'AUDIO_COVERAGE_AREA_RMS',
	RfChannelActivity = 'RF_CHANNEL_ACTIVITY',
	RfChannelAntennaDiversity = 'RF_CHANNEL_ANTENNA_DIVERSITY',
	RfChannelSignalStrength = 'RF_CHANNEL_SIGNAL_STRENGTH'
}

export type MeterColorThemeInfoInput = {
	customThemeLightColor?: InputMaybe<RgbColorMixInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export enum MeteringMode {
	PostFader = 'POST_FADER',
	PreFader = 'PRE_FADER'
}

export enum MicOptimizationMode {
	Mxa310 = 'MXA310',
	Mxa710 = 'MXA710',
	Mxa901 = 'MXA901',
	Mxa902 = 'MXA902',
	Mxa910 = 'MXA910',
	Mxa920 = 'MXA920',
	Normal = 'NORMAL'
}

/**
 * The microphone polar pattern determines how much audio signal is picked up from the direction from which the signal originates from.
 * RAW_MID_SIDE: Combines Cardioid and Bi-directional polar pattern. Refer to the following youtube video for more information on its usage: https://youtu.be/HXpHcO8RG1M
 */
export enum MicPolarPattern {
	Bidirectional = 'BIDIRECTIONAL',
	Cardioid = 'CARDIOID',
	Hypercardioid = 'HYPERCARDIOID',
	Omnidirectional = 'OMNIDIRECTIONAL',
	RawMidSide = 'RAW_MID_SIDE',
	Stereo = 'STEREO',
	Supercardioid = 'SUPERCARDIOID',
	Toroid = 'TOROID'
}

export enum MicStatus {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	OnCharger = 'ON_CHARGER',
	OutOfRange = 'OUT_OF_RANGE',
	Standby = 'STANDBY',
	Unknown = 'UNKNOWN'
}

export enum MicStatusInput {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export enum MuteButtonMode {
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK',
	Toggle = 'TOGGLE'
}

export enum MuteControlFunction {
	Disabled = 'DISABLED',
	Local = 'LOCAL',
	LogicOut = 'LOGIC_OUT'
}

export enum NetworkMode {
	Redundant = 'REDUNDANT',
	Split = 'SPLIT',
	Switched = 'SWITCHED',
	Uplink = 'UPLINK'
}

export enum NodeChangeType {
	AudioChannelAcousticEchoCancellation = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION',
	AudioChannelAcousticEchoCancellationMonoReference = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_MONO_REFERENCE',
	AudioChannelAcousticEchoCancellationStereoReference = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_STEREO_REFERENCE',
	AudioChannelAnalogInputUse = 'AUDIO_CHANNEL_ANALOG_INPUT_USE',
	AudioChannelAnalogLevel = 'AUDIO_CHANNEL_ANALOG_LEVEL',
	AudioChannelAutomaticGainControl = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL',
	AudioChannelAutomixer = 'AUDIO_CHANNEL_AUTOMIXER',
	AudioChannelAutomixerInput = 'AUDIO_CHANNEL_AUTOMIXER_INPUT',
	AudioChannelAutomixerInputAlwaysOn = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_ALWAYS_ON',
	AudioChannelAutomixerInputPriority = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_PRIORITY',
	AudioChannelAutomixerInputSendToMonoMix = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_SEND_TO_MONO_MIX',
	AudioChannelAutomixerInputSendToStereoMix = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_SEND_TO_STEREO_MIX',
	AudioChannelAutomixerVoiceActivityDetection = 'AUDIO_CHANNEL_AUTOMIXER_VOICE_ACTIVITY_DETECTION',
	AudioChannelAutomixSourceSelector = 'AUDIO_CHANNEL_AUTOMIX_SOURCE_SELECTOR',
	AudioChannelAutoLevel = 'AUDIO_CHANNEL_AUTO_LEVEL',
	AudioChannelAutoMute = 'AUDIO_CHANNEL_AUTO_MUTE',
	AudioChannelAutoPosition = 'AUDIO_CHANNEL_AUTO_POSITION',
	AudioChannelCarriedByRfChannels = 'AUDIO_CHANNEL_CARRIED_BY_RF_CHANNELS',
	AudioChannelChannelConfiguration = 'AUDIO_CHANNEL_CHANNEL_CONFIGURATION',
	AudioChannelCompressor = 'AUDIO_CHANNEL_COMPRESSOR',
	AudioChannelConnectionDetection = 'AUDIO_CHANNEL_CONNECTION_DETECTION',
	AudioChannelDanteEncryptionStatus = 'AUDIO_CHANNEL_DANTE_ENCRYPTION_STATUS',
	AudioChannelDanteRouteReceiver = 'AUDIO_CHANNEL_DANTE_ROUTE_RECEIVER',
	AudioChannelDanteRouteTransmitter = 'AUDIO_CHANNEL_DANTE_ROUTE_TRANSMITTER',
	AudioChannelDelay = 'AUDIO_CHANNEL_DELAY',
	AudioChannelDenoiser = 'AUDIO_CHANNEL_DENOISER',
	AudioChannelDeverb = 'AUDIO_CHANNEL_DEVERB',
	AudioChannelDeviceSpecificEqualizerContour = 'AUDIO_CHANNEL_DEVICE_SPECIFIC_EQUALIZER_CONTOUR',
	AudioChannelDirectOutputTapPoint = 'AUDIO_CHANNEL_DIRECT_OUTPUT_TAP_POINT',
	AudioChannelEqualizer = 'AUDIO_CHANNEL_EQUALIZER',
	AudioChannelEqualizerContour = 'AUDIO_CHANNEL_EQUALIZER_CONTOUR',
	AudioChannelEqualizerPreset = 'AUDIO_CHANNEL_EQUALIZER_PRESET',
	AudioChannelFaderGroup = 'AUDIO_CHANNEL_FADER_GROUP',
	AudioChannelGain = 'AUDIO_CHANNEL_GAIN',
	AudioChannelGainLock = 'AUDIO_CHANNEL_GAIN_LOCK',
	AudioChannelIdentify = 'AUDIO_CHANNEL_IDENTIFY',
	AudioChannelInputSource = 'AUDIO_CHANNEL_INPUT_SOURCE',
	AudioChannelLightingBehavior = 'AUDIO_CHANNEL_LIGHTING_BEHAVIOR',
	AudioChannelLimiter = 'AUDIO_CHANNEL_LIMITER',
	AudioChannelLobeConfiguration = 'AUDIO_CHANNEL_LOBE_CONFIGURATION',
	AudioChannelLobePosition = 'AUDIO_CHANNEL_LOBE_POSITION',
	AudioChannelLobeTalkerHeight = 'AUDIO_CHANNEL_LOBE_TALKER_HEIGHT',
	AudioChannelLobeWidth = 'AUDIO_CHANNEL_LOBE_WIDTH',
	AudioChannelLogicReceiver = 'AUDIO_CHANNEL_LOGIC_RECEIVER',
	AudioChannelMicrophoneAngle = 'AUDIO_CHANNEL_MICROPHONE_ANGLE',
	AudioChannelMicOptimization = 'AUDIO_CHANNEL_MIC_OPTIMIZATION',
	AudioChannelMicPolarPattern = 'AUDIO_CHANNEL_MIC_POLAR_PATTERN',
	AudioChannelMonitorMix = 'AUDIO_CHANNEL_MONITOR_MIX',
	AudioChannelMonitorMixReverb = 'AUDIO_CHANNEL_MONITOR_MIX_REVERB',
	AudioChannelMute = 'AUDIO_CHANNEL_MUTE',
	AudioChannelMuteGroup = 'AUDIO_CHANNEL_MUTE_GROUP',
	AudioChannelMuteLock = 'AUDIO_CHANNEL_MUTE_LOCK',
	AudioChannelName = 'AUDIO_CHANNEL_NAME',
	AudioChannelNoiseReduction = 'AUDIO_CHANNEL_NOISE_REDUCTION',
	AudioChannelOutputPath = 'AUDIO_CHANNEL_OUTPUT_PATH',
	AudioChannelPan = 'AUDIO_CHANNEL_PAN',
	AudioChannelPcPeripheral = 'AUDIO_CHANNEL_PC_PERIPHERAL',
	AudioChannelPhantomPower = 'AUDIO_CHANNEL_PHANTOM_POWER',
	AudioChannelPlosiveReduction = 'AUDIO_CHANNEL_PLOSIVE_REDUCTION',
	AudioChannelPostGateGain = 'AUDIO_CHANNEL_POST_GATE_GAIN',
	AudioChannelPostGateMute = 'AUDIO_CHANNEL_POST_GATE_MUTE',
	AudioChannelPostGateSolo = 'AUDIO_CHANNEL_POST_GATE_SOLO',
	AudioChannelPreFadeListen = 'AUDIO_CHANNEL_PRE_FADE_LISTEN',
	AudioChannelReverb = 'AUDIO_CHANNEL_REVERB',
	AudioChannelSelectedLoudspeaker = 'AUDIO_CHANNEL_SELECTED_LOUDSPEAKER',
	AudioChannelSignalGenerator = 'AUDIO_CHANNEL_SIGNAL_GENERATOR',
	AudioChannelSolo = 'AUDIO_CHANNEL_SOLO',
	AudioChannelStereoGain = 'AUDIO_CHANNEL_STEREO_GAIN',
	AudioChannelStereoReverse = 'AUDIO_CHANNEL_STEREO_REVERSE',
	AudioChannelStereoWidth = 'AUDIO_CHANNEL_STEREO_WIDTH',
	AudioChannelTalkerHeight = 'AUDIO_CHANNEL_TALKER_HEIGHT',
	AudioChannelTransmitterClip = 'AUDIO_CHANNEL_TRANSMITTER_CLIP',
	AudioChannelUsbOutputMode = 'AUDIO_CHANNEL_USB_OUTPUT_MODE',
	AudioCoverageAreaConfiguration = 'AUDIO_COVERAGE_AREA_CONFIGURATION',
	AudioCoverageAreaGain = 'AUDIO_COVERAGE_AREA_GAIN',
	AudioCoverageAreaMute = 'AUDIO_COVERAGE_AREA_MUTE',
	ChargerBayAssociation = 'CHARGER_BAY_ASSOCIATION',
	ChargerBayBatteryCapacity = 'CHARGER_BAY_BATTERY_CAPACITY',
	ChargerBayBatteryHealth = 'CHARGER_BAY_BATTERY_HEALTH',
	ChargerBayBatteryPresent = 'CHARGER_BAY_BATTERY_PRESENT',
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	ChargerBayChargingMode = 'CHARGER_BAY_CHARGING_MODE',
	ChargerBayDockedDevice = 'CHARGER_BAY_DOCKED_DEVICE',
	ChargerSlotChargerBays = 'CHARGER_SLOT_CHARGER_BAYS',
	/** @deprecated Proof of concept of charger slot on device.  Do not use. */
	ChargerSlotTypeId = 'CHARGER_SLOT_TYPE_ID',
	CrossPointConfiguration = 'CROSS_POINT_CONFIGURATION',
	CrossPointGain = 'CROSS_POINT_GAIN',
	DeviceActivePreset = 'DEVICE_ACTIVE_PRESET',
	DeviceAllEqualizersBypass = 'DEVICE_ALL_EQUALIZERS_BYPASS',
	DeviceAllIntellimixBypass = 'DEVICE_ALL_INTELLIMIX_BYPASS',
	DeviceAnalogAudioOutputMode = 'DEVICE_ANALOG_AUDIO_OUTPUT_MODE',
	DeviceAnalogLogic = 'DEVICE_ANALOG_LOGIC',
	DeviceAnalogLogicPresetSwitch = 'DEVICE_ANALOG_LOGIC_PRESET_SWITCH',
	DeviceAnalogLogicPresetSwitchStatus = 'DEVICE_ANALOG_LOGIC_PRESET_SWITCH_STATUS',
	DeviceAntennaBiasVoltage = 'DEVICE_ANTENNA_BIAS_VOLTAGE',
	DeviceAudioAutoFocus = 'DEVICE_AUDIO_AUTO_FOCUS',
	DeviceAudioChannels = 'DEVICE_AUDIO_CHANNELS',
	DeviceAudioChannelCount = 'DEVICE_AUDIO_CHANNEL_COUNT',
	DeviceAudioMute = 'DEVICE_AUDIO_MUTE',
	DeviceAudioMuteControl = 'DEVICE_AUDIO_MUTE_CONTROL',
	DeviceAudioMuteControlGroup = 'DEVICE_AUDIO_MUTE_CONTROL_GROUP',
	DeviceAudioMuteDefault = 'DEVICE_AUDIO_MUTE_DEFAULT',
	DeviceAudioMuteLock = 'DEVICE_AUDIO_MUTE_LOCK',
	DeviceAudioNetwork = 'DEVICE_AUDIO_NETWORK',
	DeviceAudioNetworkInterface = 'DEVICE_AUDIO_NETWORK_INTERFACE',
	DeviceAuthentication = 'DEVICE_AUTHENTICATION',
	DeviceAutomixGainMetering = 'DEVICE_AUTOMIX_GAIN_METERING',
	DeviceAvailablePackages = 'DEVICE_AVAILABLE_PACKAGES',
	DeviceBatteryHealth = 'DEVICE_BATTERY_HEALTH',
	DeviceBatteryLevel = 'DEVICE_BATTERY_LEVEL',
	DeviceBatteryStorageMode = 'DEVICE_BATTERY_STORAGE_MODE',
	DeviceBluetoothDeviceInformationService = 'DEVICE_BLUETOOTH_DEVICE_INFORMATION_SERVICE',
	DeviceBodypackMicrophoneMode = 'DEVICE_BODYPACK_MICROPHONE_MODE',
	DeviceCallStatus = 'DEVICE_CALL_STATUS',
	DeviceCommandStringsService = 'DEVICE_COMMAND_STRINGS_SERVICE',
	DeviceConferencingOperationMode = 'DEVICE_CONFERENCING_OPERATION_MODE',
	DeviceConferencingOperationModeButtonLock = 'DEVICE_CONFERENCING_OPERATION_MODE_BUTTON_LOCK',
	DeviceConfigurationLock = 'DEVICE_CONFIGURATION_LOCK',
	DeviceConnectedDeviceLabel = 'DEVICE_CONNECTED_DEVICE_LABEL',
	DeviceControlNetwork = 'DEVICE_CONTROL_NETWORK',
	DeviceControlNetworkInterface = 'DEVICE_CONTROL_NETWORK_INTERFACE',
	DeviceCoverageLock = 'DEVICE_COVERAGE_LOCK',
	DeviceCoverageMode = 'DEVICE_COVERAGE_MODE',
	DeviceCoveragePosition = 'DEVICE_COVERAGE_POSITION',
	DeviceDanteAudioEncryption = 'DEVICE_DANTE_AUDIO_ENCRYPTION',
	DeviceDanteAudioNetwork = 'DEVICE_DANTE_AUDIO_NETWORK',
	DeviceDanteAudioNetworkAccess = 'DEVICE_DANTE_AUDIO_NETWORK_ACCESS',
	DeviceDectRfSpectrumScan = 'DEVICE_DECT_RF_SPECTRUM_SCAN',
	DeviceDeploymentCandidates = 'DEVICE_DEPLOYMENT_CANDIDATES',
	DeviceDnsClientConfiguration = 'DEVICE_DNS_CLIENT_CONFIGURATION',
	/** @deprecated For Cloud Managed Rooms Use Only. */
	DeviceDoubleStuffProxiedTransmitters = 'DEVICE_DOUBLE_STUFF_PROXIED_TRANSMITTERS',
	DeviceEqualizerContour = 'DEVICE_EQUALIZER_CONTOUR',
	DeviceErrorIndicator = 'DEVICE_ERROR_INDICATOR',
	DeviceEthernetLights = 'DEVICE_ETHERNET_LIGHTS',
	DeviceFirmwareUpdatePolicy = 'DEVICE_FIRMWARE_UPDATE_POLICY',
	DeviceGatewayDevice = 'DEVICE_GATEWAY_DEVICE',
	DeviceGlobalMicStatus = 'DEVICE_GLOBAL_MIC_STATUS',
	DeviceHeight = 'DEVICE_HEIGHT',
	DeviceIdentify = 'DEVICE_IDENTIFY',
	DeviceInternetOfThings = 'DEVICE_INTERNET_OF_THINGS',
	DeviceLicense = 'DEVICE_LICENSE',
	DeviceLicenseV2 = 'DEVICE_LICENSE_V2',
	DeviceLicenseV3 = 'DEVICE_LICENSE_V3',
	DeviceLightingAudioActivity = 'DEVICE_LIGHTING_AUDIO_ACTIVITY',
	DeviceLightingAudioMeters = 'DEVICE_LIGHTING_AUDIO_METERS',
	DeviceLightingAudioMuteIndicatorOverride = 'DEVICE_LIGHTING_AUDIO_MUTE_INDICATOR_OVERRIDE',
	DeviceLightingAutomixGating = 'DEVICE_LIGHTING_AUTOMIX_GATING',
	DeviceLightingBehavior = 'DEVICE_LIGHTING_BEHAVIOR',
	DeviceLightingBrightness = 'DEVICE_LIGHTING_BRIGHTNESS',
	DeviceLightingLiveAudioMeters = 'DEVICE_LIGHTING_LIVE_AUDIO_METERS',
	DeviceLightingMuteBehavior = 'DEVICE_LIGHTING_MUTE_BEHAVIOR',
	DeviceLightingRing = 'DEVICE_LIGHTING_RING',
	DeviceLightingSplitModeBreathingMeterColor = 'DEVICE_LIGHTING_SPLIT_MODE_BREATHING_METER_COLOR',
	DeviceLightingSplitModeSolidMeterColor = 'DEVICE_LIGHTING_SPLIT_MODE_SOLID_METER_COLOR',
	DeviceLightingTouchPanelMode = 'DEVICE_LIGHTING_TOUCH_PANEL_MODE',
	DeviceLightingUnmuteBehavior = 'DEVICE_LIGHTING_UNMUTE_BEHAVIOR',
	DeviceLinkedAccessPoint = 'DEVICE_LINKED_ACCESS_POINT',
	DeviceLinkButtonLock = 'DEVICE_LINK_BUTTON_LOCK',
	DeviceListenerHeight = 'DEVICE_LISTENER_HEIGHT',
	DeviceLogicMute = 'DEVICE_LOGIC_MUTE',
	DeviceLogicReceiver = 'DEVICE_LOGIC_RECEIVER',
	DeviceLogicTransmitter = 'DEVICE_LOGIC_TRANSMITTER',
	DeviceLoudspeaker = 'DEVICE_LOUDSPEAKER',
	DeviceMdnsService = 'DEVICE_MDNS_SERVICE',
	DeviceMeteringModes = 'DEVICE_METERING_MODES',
	DeviceMicStatus = 'DEVICE_MIC_STATUS',
	DeviceName = 'DEVICE_NAME',
	DeviceOnCharger = 'DEVICE_ON_CHARGER',
	DevicePlacement = 'DEVICE_PLACEMENT',
	DeviceProxiedDevices = 'DEVICE_PROXIED_DEVICES',
	DeviceRedundantAudioNetwork = 'DEVICE_REDUNDANT_AUDIO_NETWORK',
	DeviceRfDensityMode = 'DEVICE_RF_DENSITY_MODE',
	DeviceRfPower = 'DEVICE_RF_POWER',
	DeviceRoom = 'DEVICE_ROOM',
	DeviceRotation = 'DEVICE_ROTATION',
	DeviceRoutingPosition = 'DEVICE_ROUTING_POSITION',
	DeviceSecurity_8021X = 'DEVICE_SECURITY_8021X',
	DeviceSmartGate = 'DEVICE_SMART_GATE',
	DeviceStereoAudio = 'DEVICE_STEREO_AUDIO',
	DeviceSwitchConfiguration = 'DEVICE_SWITCH_CONFIGURATION',
	DeviceTags = 'DEVICE_TAGS',
	DeviceTemperature = 'DEVICE_TEMPERATURE',
	DeviceTransmitterIdentifyBehavior = 'DEVICE_TRANSMITTER_IDENTIFY_BEHAVIOR',
	DeviceTransmitterInitialStateFromCharger = 'DEVICE_TRANSMITTER_INITIAL_STATE_FROM_CHARGER',
	DeviceTransmitterLightingBehavior = 'DEVICE_TRANSMITTER_LIGHTING_BEHAVIOR',
	DeviceTransmitterMuteBehavior = 'DEVICE_TRANSMITTER_MUTE_BEHAVIOR',
	DeviceTransmitterOutOfRangeBehavior = 'DEVICE_TRANSMITTER_OUT_OF_RANGE_BEHAVIOR',
	DeviceTransmitterRejoinBehavior = 'DEVICE_TRANSMITTER_REJOIN_BEHAVIOR',
	DeviceTransmitterSwitchBehavior = 'DEVICE_TRANSMITTER_SWITCH_BEHAVIOR',
	DeviceUpdateProgress = 'DEVICE_UPDATE_PROGRESS',
	DeviceUptime = 'DEVICE_UPTIME',
	DeviceUsbMuteSync = 'DEVICE_USB_MUTE_SYNC',
	DeviceUsbTerminalType = 'DEVICE_USB_TERMINAL_TYPE',
	DeviceUserPreset = 'DEVICE_USER_PRESET',
	DeviceVirtualAcousticBoundary = 'DEVICE_VIRTUAL_ACOUSTIC_BOUNDARY',
	DeviceWebService = 'DEVICE_WEB_SERVICE',
	EqualizerFilterBandwidth = 'EQUALIZER_FILTER_BANDWIDTH',
	EqualizerFilterConfiguration = 'EQUALIZER_FILTER_CONFIGURATION',
	EqualizerFilterFrequency = 'EQUALIZER_FILTER_FREQUENCY',
	EqualizerFilterGain = 'EQUALIZER_FILTER_GAIN',
	EqualizerFilterQFactor = 'EQUALIZER_FILTER_Q_FACTOR',
	FirmwareUpdateProgress = 'FIRMWARE_UPDATE_PROGRESS',
	FirmwareUpdateRequestProgress = 'FIRMWARE_UPDATE_REQUEST_PROGRESS',
	RfChannelAssociation = 'RF_CHANNEL_ASSOCIATION',
	RfChannelCarriedAudioChannels = 'RF_CHANNEL_CARRIED_AUDIO_CHANNELS',
	RfChannelInterferenceDetection = 'RF_CHANNEL_INTERFERENCE_DETECTION',
	RfChannelInterferenceDetector = 'RF_CHANNEL_INTERFERENCE_DETECTOR',
	RfChannelLinkedTransmitter = 'RF_CHANNEL_LINKED_TRANSMITTER',
	RfChannelMute = 'RF_CHANNEL_MUTE',
	RfChannelTuning = 'RF_CHANNEL_TUNING',
	Room = 'ROOM',
	UserPreset = 'USER_PRESET'
}

export type NodeUpdateInput =
	| {
			audioChannel: AudioChannelUpdateInput;
			audioCoverageArea?: never;
			crossPoint?: never;
			device?: never;
			equalizerFilters?: never;
			rfChannel?: never;
	  }
	| {
			audioChannel?: never;
			audioCoverageArea: AudioCoverageAreaUpdateInput;
			crossPoint?: never;
			device?: never;
			equalizerFilters?: never;
			rfChannel?: never;
	  }
	| {
			audioChannel?: never;
			audioCoverageArea?: never;
			crossPoint: CrossPointUpdateInput;
			device?: never;
			equalizerFilters?: never;
			rfChannel?: never;
	  }
	| {
			audioChannel?: never;
			audioCoverageArea?: never;
			crossPoint?: never;
			device: DeviceUpdateInput;
			equalizerFilters?: never;
			rfChannel?: never;
	  }
	| {
			audioChannel?: never;
			audioCoverageArea?: never;
			crossPoint?: never;
			device?: never;
			equalizerFilters: EqualizerFilterUpdateInput;
			rfChannel?: never;
	  }
	| {
			audioChannel?: never;
			audioCoverageArea?: never;
			crossPoint?: never;
			device?: never;
			equalizerFilters?: never;
			rfChannel: RfChannelUpdateInput;
	  };

export enum NoiseDetectionSensitivity {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export enum NoiseReductionLevel {
	Auto = 'AUTO',
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export enum NoiseRemovalLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export enum NonLinearProcessingLevel {
	Auto = 'AUTO',
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export type OptimizeRoomInput = {
	/**
	 * Indicates whether audio setting should be applied to room devices.
	 * If not provided, true is assumed (audio settings will be applied to associated room devices).
	 */
	adjustAudioSettings?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Indicates whether optimizing the room should happen even if one or more room devices are offline.
	 * If not provided, false is assumed (room optimization would fail if one or more devices are offline).
	 */
	allowUnavailableDevices?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Indicates whether mute synchronization should be enabled on processor while optimizaing room.
	 * If not provided, true is assumed (mute synchronization will be enabled on the processor).
	 */
	enableMuteSynchronization?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Indicates whether or not linking of wireless microphones should occur.
	 * If not provided, true is assumed (linking of wireless microphones will occur).
	 */
	linkWirelessMicrophones?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OptimizeRoomProgressType {
	OptimizeRoomProgress = 'OPTIMIZE_ROOM_PROGRESS',
	OptimizeRoomResult = 'OPTIMIZE_ROOM_RESULT'
}

export enum OutputSignalPath {
	PostDigitalSignalProcessing = 'POST_DIGITAL_SIGNAL_PROCESSING',
	PreDigitalSignalProcessing = 'PRE_DIGITAL_SIGNAL_PROCESSING'
}

export enum PcPeripheralStatus {
	Available = 'AVAILABLE',
	Begin = 'BEGIN',
	End = 'END',
	InProgress = 'IN_PROGRESS',
	None = 'NONE',
	Unavailable = 'UNAVAILABLE'
}

export enum PendingClaimStatus {
	Created = 'CREATED',
	Deleted = 'DELETED'
}

export enum Placement {
	Ceiling = 'CEILING',
	Table = 'TABLE',
	WallHorizontal = 'WALL_HORIZONTAL',
	WallVertical = 'WALL_VERTICAL'
}

export type PresetExportToFilePathInput = {
	/**
	 * Full file path, where preset file should be exported to, including file name;
	 * file name is expected in path to ensure naming conventions, which might be different
	 * for different clients, are satisfied
	 */
	filePath: Scalars['String']['input'];
	/** Preset Id to be exported */
	presetId: Scalars['ID']['input'];
};

/** This input is intended for REST interface */
export type PresetImportFromFileContentsInput = {
	/** Full path to JSON preset file to be imported */
	fileContents: Scalars['Upload']['input'];
	/** Preset Id, to which imported preset should be applied */
	presetId: Scalars['ID']['input'];
};

export type PresetImportFromFilePathInput = {
	/** Provided path to a JSON preset file to be imported */
	filePath: Scalars['String']['input'];
	/** Preset Id, to which imported preset should be applied */
	presetId: Scalars['ID']['input'];
};

export type PresetUpdateInput = {
	name: Scalars['String']['input'];
	presetId: Scalars['ID']['input'];
};

export type ProductionDeviceIdentity = {
	category: Scalars['String']['input'];
	minVersions: Array<Scalars['SemanticVersion']['input']>;
};

export type RectangularCoordinatesInput = {
	/** Maximum x-coordinate of the rectangular bounds, in millimeters */
	xMax: Scalars['Float']['input'];
	/** Minimum x-coordinate of the rectangular bounds, in millimeters */
	xMin: Scalars['Float']['input'];
	/** Maximum y-coordinate of the rectangular bounds, in millimeters */
	yMax: Scalars['Float']['input'];
	/** Minimum y-coordinate of the rectangular bounds, in millimeters */
	yMin: Scalars['Float']['input'];
};

export type RefreshDeviceLicenseInput = {
	/** The node ID of the device to refresh the license for. */
	id: Scalars['ID']['input'];
	/** The client-generated, uuid-formatted, correlation token to use for the license refresh operation. */
	transactionId: Scalars['String']['input'];
};

export type RemoveCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export enum ReverbType {
	Hall = 'HALL',
	Plate = 'PLATE',
	Studio = 'STUDIO'
}

export enum RfActivityLevel {
	Adequate = 'ADEQUATE',
	Good = 'GOOD',
	None = 'NONE',
	Overload = 'OVERLOAD',
	Poor = 'POOR'
}

export type RfChannelFeatureUpdateInput =
	| { interferenceDetector: RfChannelInterferenceDetectorUpdateInput; mute?: never }
	| { interferenceDetector?: never; mute: RfChannelMuteUpdateInput };

export enum RfChannelInterferenceDetectionSeverity {
	/** Interference is detected; audio will be silenced */
	Critical = 'CRITICAL',
	/** No interference is detected */
	None = 'NONE'
}

/**
 * The level of sensitivity of the interference detector.
 * Sensitivity only applies when the detector is enabled.
 */
export enum RfChannelInterferenceDetectorSensitivity {
	Aggressive = 'AGGRESSIVE',
	Conservative = 'CONSERVATIVE'
}

export type RfChannelInterferenceDetectorUpdateInput = {
	sensitivity?: InputMaybe<RfChannelInterferenceDetectorSensitivity | `${RfChannelInterferenceDetectorSensitivity}`>;
};

export type RfChannelMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type RfChannelUpdateInput = {
	features?: InputMaybe<RfChannelFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export enum RfDensityMode {
	High = 'HIGH',
	Standard = 'STANDARD'
}

export enum RfPower {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	MediumHigh = 'MEDIUM_HIGH'
}

export type RgbColorMixInput = {
	blue: Scalars['Int']['input'];
	green: Scalars['Int']['input'];
	red: Scalars['Int']['input'];
};

export enum RoomCloningStrategy {
	/**
	 * Cloned device has origin ID set to the ID of the device from which it is cloned
	 * Cloning of virtual device with CLONE_AS_CHILD strategy would always result
	 * in the clone with nill origin ID (identical to CLONE_AS_ORPHAN) due to the rule
	 * of "clone of a clone is pure virtual with no origin"
	 */
	CloneAsChild = 'CLONE_AS_CHILD',
	/**
	 * Cloned device has no origin ID (null UUID)
	 * Cloning of virtual device with CLONE_AS_CHILD strategy would always result
	 * in the clone with nill origin ID (identical to CLONE_AS_ORPHAN)
	 * due to the rule of "clone of a clone is pure virtual with no origin"
	 */
	CloneAsOrphan = 'CLONE_AS_ORPHAN',
	/**
	 * Cloned device has origin ID set to the origin ID of the original device
	 * Notice that for real devices this will result in the same originId as in
	 * CLONE_AS_CHILD strategy. Real devices have origin ID equal their ID
	 */
	CloneAsSibling = 'CLONE_AS_SIBLING'
}

export type RoomDeploymentInput = {
	destinationRoom?: InputMaybe<Scalars['ID']['input']>;
	destinationRoomDescription?: InputMaybe<Scalars['String']['input']>;
	destinationRoomName?: InputMaybe<Scalars['String']['input']>;
	devices: Array<DeviceDeploymentInput>;
	sourceRoom: Scalars['ID']['input'];
};

export enum RoomDeploymentOverallResult {
	/**
	 * Room deployment failed, device deployment failed and no room placement was attempted
	 * @deprecated Use the more specific failure modes instead
	 */
	DeploymentFailed = 'DEPLOYMENT_FAILED',
	/** Room deployment succeeded, devices were deployed and placed into a room */
	DeploymentSuccess = 'DEPLOYMENT_SUCCESS',
	/** Failed at creating the destination room for the target devices */
	FailedCreatingDestinationRoom = 'FAILED_CREATING_DESTINATION_ROOM',
	/** Failed at deploying audio routes between devices in room */
	FailedDeployingAudioRoutes = 'FAILED_DEPLOYING_AUDIO_ROUTES',
	/** Failed at deploying control routes (i.e. mute routes) between devices in room */
	FailedDeployingControlRoutes = 'FAILED_DEPLOYING_CONTROL_ROUTES',
	/** Failed at deploying device state from sources to targets */
	FailedDeployingDeviceState = 'FAILED_DEPLOYING_DEVICE_STATE',
	/** Failed at cleaning up and validating the room deployment */
	FailedFinalizing = 'FAILED_FINALIZING',
	/** Failed at preparing the devices and rooms for room deployment */
	FailedPreparing = 'FAILED_PREPARING',
	/** Failed for unknown reasons */
	FailedUnknown = 'FAILED_UNKNOWN',
	/**
	 * Room deployment failed, devices were successfully deployed, but could not be placed in a room
	 * @deprecated Use the more specific failure modes instead
	 */
	PlacementFailed = 'PLACEMENT_FAILED'
}

export enum RoomDeploymentProgressType {
	RoomDeploymentResult = 'ROOM_DEPLOYMENT_RESULT'
}

export enum RoomDeploymentStage {
	/** Creating the destination room for the target devices */
	CreatingDestinationRoom = 'CREATING_DESTINATION_ROOM',
	/** Deploying audio routes between devices in room */
	DeployingAudioRoutes = 'DEPLOYING_AUDIO_ROUTES',
	/** Deploying control routes (i.e. mute routes) between devices in room */
	DeployingControlRoutes = 'DEPLOYING_CONTROL_ROUTES',
	/** Deploying device state from sources to targets */
	DeployingDeviceState = 'DEPLOYING_DEVICE_STATE',
	/** Cleaning up and validating the room deployment */
	Finalizing = 'FINALIZING',
	/** The deployment request has been accepted and is pending execution */
	Pending = 'PENDING',
	/** Preparing the devices and rooms for room deployment */
	Preparing = 'PREPARING'
}

export type RoomDimensionsInput = {
	/** height values are scaled to represent in millimeters (mm) */
	height: Scalars['Float']['input'];
	/** length values are scaled to represent in millimeters (mm) */
	length: Scalars['Float']['input'];
	/** width values are scaled to represent in millimeters (mm) */
	width: Scalars['Float']['input'];
};

export enum RoomOptimizationStage {
	/** Creating audio/control routes between devices */
	CreatingAssociations = 'CREATING_ASSOCIATIONS',
	/** Applying device settings for optimal audio */
	OptimizingDeviceSettings = 'OPTIMIZING_DEVICE_SETTINGS'
}

export enum RoomOptimizationStatus {
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	IncompatibleDevice = 'INCOMPATIBLE_DEVICE',
	MissingMicrophone = 'MISSING_MICROPHONE',
	MissingProcessor = 'MISSING_PROCESSOR',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS'
}

export enum RoomVersionCompatibility {
	/** The version of room is equal to our latest known version */
	CompatibleLatest = 'COMPATIBLE_LATEST',
	/** The version of room is less than our latest known version */
	CompatibleOld = 'COMPATIBLE_OLD',
	/** The version of room is greater than our latest known version */
	IncompatibleTooNew = 'INCOMPATIBLE_TOO_NEW',
	/** Unable to understand the version of the room (version is 0 for some reason) */
	Unknown = 'UNKNOWN'
}

/** The power source type available to the device, which ultimately determines the gain range the signal generator can use. */
export enum SignalGeneratorPowerSourceType {
	Poe = 'POE',
	PoePlus = 'POE_PLUS',
	PoePlusPlus = 'POE_PLUS_PLUS'
}

export enum SignalType {
	PinkNoise = 'PINK_NOISE',
	SineWave = 'SINE_WAVE',
	Sweep = 'SWEEP',
	WhiteNoise = 'WHITE_NOISE'
}

export enum StereoAudioChannelSpatialSeparation {
	Left = 'LEFT',
	Right = 'RIGHT'
}

export type TagAssociationInput = {
	devices: Array<Scalars['ID']['input']>;
	tag: Scalars['String']['input'];
};

export enum TagAssociationStatus {
	FailureDeviceNotFound = 'FAILURE_DEVICE_NOT_FOUND',
	FailureTagLimitReached = 'FAILURE_TAG_LIMIT_REACHED',
	FailureUnknown = 'FAILURE_UNKNOWN',
	Success = 'SUCCESS'
}

export type TagDissociationInput = {
	devices: Array<Scalars['ID']['input']>;
	tag: Scalars['String']['input'];
};

export enum TagDissociationStatus {
	FailureDeviceNotFound = 'FAILURE_DEVICE_NOT_FOUND',
	FailureUnknown = 'FAILURE_UNKNOWN',
	Success = 'SUCCESS'
}

export type TlsCertificateInput = {
	anonymousId?: InputMaybe<Scalars['String']['input']>;
	clientCertificate: Scalars['String']['input'];
	passphrase?: InputMaybe<Scalars['String']['input']>;
	rootCertificate: Scalars['String']['input'];
	username?: InputMaybe<Scalars['String']['input']>;
};

export enum TransmitterInitialStateFromCharger {
	Active = 'ACTIVE',
	Off = 'OFF'
}

export enum TransmitterLightingBehavior {
	/** External LED control */
	ExternalControl = 'EXTERNAL_CONTROL',
	/** Solid green when the mic is unmuted and solid red when the mic is muted */
	UnmuteSolidGreenMuteSolidRed = 'UNMUTE_SOLID_GREEN_MUTE_SOLID_RED',
	/** Solid red when the mic is unmuted and flashing red when the mic is muted */
	UnmuteSolidRedMuteFlashingRed = 'UNMUTE_SOLID_RED_MUTE_FLASHING_RED',
	/** Solid red when the mic is unmuted and off when the mic is muted */
	UnmuteSolidRedMuteOff = 'UNMUTE_SOLID_RED_MUTE_OFF'
}

export enum TransmitterMuteBehavior {
	ExternalMute = 'EXTERNAL_MUTE',
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL',
	LogicMute = 'LOGIC_MUTE',
	UsbHostSync = 'USB_HOST_SYNC'
}

export enum TransmitterSwitchBehavior {
	Disabled = 'DISABLED',
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK',
	Toggle = 'TOGGLE'
}

export enum TransmitterUsbHostDisconnectMuteBehavior {
	AlwaysMute = 'ALWAYS_MUTE',
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL'
}

export enum UnlinkingOperationResult {
	/** Device is not discoverable. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	/** Failed to unlink RF channel(s) and transmitter(s). */
	Failure = 'FAILURE',
	/** Input node id(s) provided in linking request is not valid. */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support linking feature or not a valid input for this operation. */
	InvalidOperation = 'INVALID_OPERATION',
	/** No transmitter is linked to RF channel. */
	NoLinkedTransmitterOnRfChannel = 'NO_LINKED_TRANSMITTER_ON_RF_CHANNEL',
	/** No transmitter is discovered for the charger bay. */
	NoTransmitterOnChargerBay = 'NO_TRANSMITTER_ON_CHARGER_BAY',
	/** Successfully unlinked RF channel(s) and transmitter(s) */
	Success = 'SUCCESS',
	/** Failed to receive unlinking result within the timeout */
	TimeoutExceeded = 'TIMEOUT_EXCEEDED'
}

export type UpdateAudioNetworkInput = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	id: Scalars['ID']['input'];
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type UpdateAudioNetworkInterfaceInput = {
	id: Scalars['ID']['input'];
	networkInterfaceId: Scalars['String']['input'];
};

export type UpdateAudioNetworksInput = {
	id: Scalars['ID']['input'];
	primary?: InputMaybe<AudioNetworkConfiguration>;
	redundant?: InputMaybe<AudioNetworkConfiguration>;
};

export type UpdateControlNetworkInput = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	id: Scalars['ID']['input'];
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type UpdateControlNetworkInterfaceInput = {
	id: Scalars['ID']['input'];
	networkInterfaceId: Scalars['String']['input'];
};

export type UpdateCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	newPassphrase: Scalars['String']['input'];
};

export type UpdateDeviceFirmwareInput = {
	firmwarePackageKey: Scalars['String']['input'];
	firmwarePackageVersion: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export type UpdateRoomInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	dimensions?: InputMaybe<RoomDimensionsInput>;
	hold?: InputMaybe<Scalars['Boolean']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	room: Scalars['ID']['input'];
};

export enum UsbOutputMode {
	Mixdown = 'MIXDOWN',
	Stereo = 'STEREO',
	Tracking = 'TRACKING'
}

export enum UsbTerminalType {
	EchoCancelingSpeakerphone = 'ECHO_CANCELING_SPEAKERPHONE',
	SpeakerphoneNoEchoReduction = 'SPEAKERPHONE_NO_ECHO_REDUCTION'
}

export type UsernamePassphraseInput = {
	passphrase: Scalars['String']['input'];
	username: Scalars['String']['input'];
};

export type VerifyCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export type VirtualDeviceAdditionFromStateFileContentsInput = {
	/** Device ID to assign to the virtual device (optional) */
	deviceId?: InputMaybe<Scalars['Guid']['input']>;
	/** Uploaded contents of a JSON state file */
	fileContents: Scalars['Upload']['input'];
	/** Device ID of the original device represented by the state file (optional) */
	originDeviceId?: InputMaybe<Scalars['Guid']['input']>;
};

export type VirtualDeviceAdditionFromStateFileUriInput = {
	/** Device ID to assign to the virtual device (optional) */
	deviceId?: InputMaybe<Scalars['Guid']['input']>;
	/** URI of a JSON state file */
	fileUri: Scalars['String']['input'];
	/** Device ID of the original device represented by the state file (optional) */
	originDeviceId?: InputMaybe<Scalars['Guid']['input']>;
};

export type VirtualDeviceAdditionInput = {
	/** Number of identical virtual devices to add */
	count: Scalars['Int']['input'];
	/** Device category */
	deviceCategory: Scalars['String']['input'];
	/** Device type ID */
	deviceType: Scalars['Guid']['input'];
	/**
	 * Property interface ID (optional)
	 *
	 * If one is not provided, the latest supported interface will be used
	 */
	interfaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type VirtualDeviceRemovalInput = {
	/** Node IDs of the virtual devices to remove */
	ids: Array<Scalars['ID']['input']>;
};

export type ClaimDeviceVariables = Exact<{
	deviceNodeId: Scalars['ID']['input'];
	tokenId: Scalars['Guid']['input'];
}>;

export type ClaimDeviceOpResult = { claimDevice: boolean };

export type UnclaimDeviceVariables = Exact<{
	deviceNodeId: Scalars['ID']['input'];
}>;

export type UnclaimDeviceOpResult = { unclaimDevice: boolean };

export type ClaimDeviceByMacSerialNumberVariables = Exact<{
	claimRequests: Array<MacAndSerialClaimInput> | MacAndSerialClaimInput;
}>;

export type ClaimDeviceByMacSerialNumberOpResult = {
	claimDeviceUsingMacAndSerial: Array<{
		macAndSerialClaim: { macAddress: string; serialNumber: string };
		error: { code: ErrorCode; message: string } | null;
	}>;
};

export type ClaimedDeviceFragment = {
	id: string;
	hardwareId: string;
	status: DeviceStatus;
	isDevice: 'Device';
	description: { model: string };
};

export type ClaimedDevicesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ClaimedDevicesSubscriptionOpResult = {
	claimedDevices: { claimed: ClaimedDeviceFragment } | { unclaimed: string };
};

export type DeletePendingClaimVariables = Exact<{
	pendingClaimId: Scalars['ID']['input'];
}>;

export type DeletePendingClaimOpResult = {
	deletePendingDeviceClaim: { id: string; error: { code: ErrorCode; message: string } | null };
};

export type ListPendingClaimsVariables = Exact<{ [key: string]: never }>;

export type ListPendingClaimsOpResult = {
	pendingDeviceClaims: Array<{ id: string; macAddress: string; serialNumber: string; createdAt: string }>;
};

export type PendingClaimStatusVariables = Exact<{ [key: string]: never }>;

export type PendingClaimStatusOpResult = {
	pendingClaims: { id: string; serialNumber: string; macAddress: string; status: PendingClaimStatus };
};

export type OnDetectedDevicesVariables = Exact<{
	tokenId: Scalars['Guid']['input'];
}>;

export type OnDetectedDevicesOpResult = {
	detectedDevices: {
		detectionToken: string;
		detectedAt: string;
		expiresAt: string;
		claimable: boolean;
		device: {
			id: string;
			hardwareId: string;
			category: string;
			model: string;
			serialNumber: string;
			macAddress: string;
			firmwareVersion: string;
			firmwareValid: boolean;
			name: string;
			ipAddress: string;
			interface: { interfaceId: number; version: string; beta: boolean };
		};
	};
};

export type DeviceLicenseV3Fragment = {
	info: Array<{
		count: number;
		expirationDate: string | null;
		featureName: string | null;
		perpetual: boolean | null;
		state: LicenseStateV3;
	}>;
	systemStatus: { ownershipMatch: boolean; communication: { state: LicenseSystemCommunicationState } };
};

export type OrgTagsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgTagsQueryOpResult = { tags: Array<string> };

export type OrgTagsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrgTagsSubscriptionOpResult = {
	tags: { __typename: 'TagAddedEvent'; added: string } | { __typename: 'TagRemovedEvent'; removed: string };
};

export type AssociateTagMutationVariables = Exact<{
	tagInput: TagAssociationInput;
}>;

export type AssociateTagMutationOpResult = { associateTag: Array<{ device: string; status: TagAssociationStatus }> };

export type DissociateTagMutationVariables = Exact<{
	tagInput: TagDissociationInput;
}>;

export type DissociateTagMutationOpResult = { dissociateTag: Array<{ device: string; status: TagDissociationStatus }> };

export type CloudPropertyPanelRfChannelFragment = {
	__typename: 'RfChannel';
	id: string;
	description: { features: { linkedTransmitter: { supported: boolean } } };
	features: { linkedTransmitter: { linkage: { device: { id: string } | null } | null } | null };
};

export type CloudPropertyPanelRfChannelUpdateFragment = {
	__typename: 'RfChannelChange';
	id: string;
	features: { linkedTransmitter: { linkage: { device: { id: string } | null } | null } | null } | {};
};

export type CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionVariables = Exact<{
	rfChannelNodeId: Scalars['ID']['input'];
	rfChannelNodeChangeType: Array<NodeChangeType> | NodeChangeType;
}>;

export type CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionOpResult = {
	nodeChanges: CloudPropertyPanelRfChannelFragment | CloudPropertyPanelRfChannelUpdateFragment | {};
};

export type DeviceLicenseInfoFragment = {
	count: number;
	expirationDate: string | null;
	featureName: string | null;
	perpetual: boolean | null;
	state: LicenseState;
};

export type DeviceLicenseInfoV2Fragment = {
	count: number;
	expirationDate: string | null;
	featureName: string | null;
	perpetual: boolean | null;
	state: LicenseState;
};

export type PropertyPanelRfChannelLinkedDeviceFragment = {
	id: string;
	status: DeviceStatus;
	isDeviceNode: 'Device';
	interface: { category: string; model: string | null };
	description: {
		features: {
			audioMute: { supported: boolean };
			batteryLevel: { supported: boolean };
			identify: { supported: boolean };
			micStatus: { supported: boolean };
			name: { supported: boolean };
		};
	};
	features: {
		audioMute: { muted: boolean } | null;
		batteryLevel: {
			percentage: number;
			status: { currentState: BatteryState; targetState: BatteryState | null; timeToTargetState: string | null };
		} | null;
		identify: { identifying: boolean } | null;
		micStatus: { status: MicStatus } | null;
		name: { name: string } | null;
	};
};

export type PropertyPanelRfChannelFragment = {
	id: string;
	description: { features: { linkedTransmitter: { supported: boolean } } };
	features: {
		linkedTransmitter: { linkage: { device: PropertyPanelRfChannelLinkedDeviceFragment | null } | null } | null;
	};
};

export type PropertyPanelDeviceFragment = {
	id: string;
	virtual: boolean;
	status: DeviceStatus;
	compatibility: CompatibilityLevel;
	isDeviceNode: 'Device';
	protocol: { dmp: { ipAddress: string } | null };
	interface: { category: string; model: string | null };
	description: {
		features: {
			audioChannelCount: { supported: boolean };
			audioMute: { supported: boolean };
			audioNetwork: { supported: boolean };
			availablePackages: { supported: boolean };
			batteryLevel: { supported: boolean };
			controlNetwork: { supported: boolean };
			danteAudioNetwork: { supported: boolean };
			firmware: { supported: boolean };
			identify: { supported: boolean };
			license: { supported: boolean };
			licenseV2: { supported: boolean };
			licenseV3?: { supported: boolean };
			micStatus: { supported: boolean };
			reboot: { supported: boolean };
			rfChannels: { supported: boolean };
			serialNumber: { supported: boolean };
			updateProgress: { supported: boolean };
			uptime: { supported: boolean };
			tags: { supported: boolean };
		};
		constraints: { name: { name: { pattern: string | null } } | null };
	};
	features: {
		audioChannelCount: { count: number } | null;
		audioMute: { muted: boolean } | null;
		audioNetwork: {
			interface: {
				gateway: string | null;
				ipAddress: string | null;
				ipMode: IpMode | null;
				macAddress: string;
				subnetMask: string | null;
			};
		} | null;
		availablePackages: { primaryPackages: Array<{ key: string; version: string }> } | null;
		batteryLevel: {
			percentage: number;
			status: { currentState: BatteryState; targetState: BatteryState | null; timeToTargetState: string | null };
		} | null;
		controlNetwork: {
			interface: {
				gateway: string | null;
				ipAddress: string | null;
				ipMode: IpMode | null;
				macAddress: string;
				subnetMask: string | null;
			};
		} | null;
		danteAudioNetwork: { name: string } | null;
		firmware: { valid: boolean; version: string } | null;
		identify: { identifying: boolean } | null;
		license: { info: Array<DeviceLicenseInfoFragment> } | null;
		licenseV2: { info: Array<DeviceLicenseInfoV2Fragment> } | null;
		licenseV3?: DeviceLicenseV3Fragment | null;
		micStatus: { status: MicStatus } | null;
		name: { name: string } | null;
		rfChannels: { rfChannels: Array<PropertyPanelRfChannelFragment> } | null;
		serialNumber: { serialNumber: string } | null;
		updateProgress: { updateStage: FirmwareUpdateStage; updateStatus: FirmwareUpdateStatus } | null;
		uptime: { uptime: string } | null;
		tags: { tags: Array<string> } | null;
	};
};

export type PropertyPanelDeviceUpdateFragment = {
	id: string;
	features:
		| AudioChannelCountChangeFragment
		| AudioMuteFeatureChangeFragment
		| AudioNetworkChangeFragment
		| AvailablePackagesFeatureChangeFragment
		| BatteryLevelFeatureChangeFragment
		| PropPanelControlNetworkChangeFragment
		| DanteAudioNetworkChangeFragment
		| IdentifyFeatureChangeFragment
		| LicenseFeatureChangeFragment
		| LicenseV2FeatureChangeFragment
		| LicenseV3FeatureChangeFragment
		| MicStatusFeatureChangeFragment
		| NameFeatureChangeFragment
		| TagsFeatureChangeFragment
		| UptimeChangeFragment
		| {};
};

export type PropertyPanelDeviceQueryVariables = Exact<{
	nodeId: Scalars['ID']['input'];
	requestLicenseV3: Scalars['Boolean']['input'];
}>;

export type PropertyPanelDeviceQueryOpResult = { node: PropertyPanelDeviceFragment | {} };

export type PropertyPanelDeviceSubscriptionVariables = Exact<{
	id: Scalars['ID']['input'];
	types: Array<NodeChangeType> | NodeChangeType;
	requestLicenseV3: Scalars['Boolean']['input'];
}>;

export type PropertyPanelDeviceSubscriptionOpResult = {
	nodeChanges:
		| PropertyPanelDeviceFragment
		| PropertyPanelDeviceUpdateFragment
		| { id: string; status: DeviceStatus }
		| {};
};

export type DeviceSupportedFeaturesFragment = {
	audioMute: { supported: boolean };
	audioNetwork: { supported: boolean };
	availablePackages: { supported: boolean };
	batteryLevel: { supported: boolean };
	controlNetwork: { supported: boolean };
	danteAudioNetwork: { supported: boolean };
	firmware: { supported: boolean };
	identify: { supported: boolean };
	licenseV3?: { supported: boolean };
	micStatus: { supported: boolean };
	name: { supported: boolean };
	proxiedDevices: { supported: boolean };
	rfChannels: { supported: boolean };
	serialNumber: { supported: boolean };
	uptime: { supported: boolean };
	updateProgress: { supported: boolean };
	room: { supported: boolean };
	tags: { supported: boolean };
};

export type DeviceStartIdentifyMutationVariables = Exact<{
	startIdentifyId: Scalars['ID']['input'];
}>;

export type DeviceStartIdentifyMutationOpResult = {
	startIdentify: { error: { code: ErrorCode; message: string } | null };
};

export type DeviceStopIdentifyMutationVariables = Exact<{
	stopIdentifyId: Scalars['ID']['input'];
}>;

export type DeviceStopIdentifyMutationOpResult = {
	stopIdentify: { error: { code: ErrorCode; message: string } | null };
};

export type DeviceRebootMutationVariables = Exact<{
	rebootDeviceId: Scalars['ID']['input'];
}>;

export type DeviceRebootMutationOpResult = { rebootDevice: { error: { code: ErrorCode; message: string } | null } };

export type DeviceUpdateMutationVariables = Exact<{
	updates: Array<NodeUpdateInput> | NodeUpdateInput;
}>;

export type DeviceUpdateMutationOpResult = {
	updateNodes: Array<{ error: { code: ErrorCode; message: string } | null } | {}>;
};

export type DeviceUpdateFirmwareMutationVariables = Exact<{
	input: Array<UpdateDeviceFirmwareInput> | UpdateDeviceFirmwareInput;
}>;

export type DeviceUpdateFirmwareMutationOpResult = {
	updateFirmware: { requestId: string; state: FirmwareUpdateRequestState };
};

export type DiscoveryDeviceFragment = { id: string; isDevice: 'Device' };

export type DiscoveredDevicesQueryVariables = Exact<{
	first: InputMaybe<Scalars['Int']['input']>;
	after: InputMaybe<Scalars['String']['input']>;
	deviceModels: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type DiscoveredDevicesQueryOpResult = {
	discoveredDevicesConnection: {
		pageInfo: { hasNextPage: boolean; endCursor: string };
		edges: Array<{ node: DiscoveryDeviceFragment | null }>;
	};
};

export type DiscoveredDevicesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type DiscoveredDevicesSubscriptionOpResult = {
	discoveredDevices: { added: DiscoveryDeviceFragment } | { removed: string };
};

export type DiscoveredDeviceSubscriptionVariables = Exact<{
	id: Scalars['ID']['input'];
	types: Array<NodeChangeType> | NodeChangeType;
}>;

export type DiscoveredDeviceSubscriptionOpResult = { nodeChanges: DiscoveryDeviceFragment | {} };

export type AudioMuteFeatureFragment = { audioMute: { __typename: 'DeviceAudioMute'; muted: boolean } | null };

export type AudioMuteFeatureChangeFragment = {
	__typename: 'DeviceAudioMuteChange';
	audioMute: { __typename: 'DeviceAudioMute'; muted: boolean } | null;
};

export type FirmwareValidFeatureFragment = {
	firmware: { __typename: 'DeviceFirmware'; valid: boolean; version: string } | null;
};

export type AvailablePackagesFeatureFragment = {
	availablePackages: {
		__typename: 'DeviceAvailablePackages';
		primaryPackages: Array<{ key: string; version: string }>;
	} | null;
};

export type AvailablePackagesFeatureChangeFragment = {
	__typename: 'DeviceAvailablePackagesChange';
	availablePackages: {
		__typename: 'DeviceAvailablePackages';
		primaryPackages: Array<{ key: string; version: string }>;
	} | null;
};

export type BatteryLevelFeatureFragment = {
	batteryLevel: {
		__typename: 'DeviceBatteryLevel';
		percentage: number;
		status: { currentState: BatteryState; targetState: BatteryState | null; timeToTargetState: string | null };
	} | null;
};

export type BatteryLevelFeatureChangeFragment = {
	__typename: 'DeviceBatteryLevelChange';
	batteryLevel: {
		__typename: 'DeviceBatteryLevel';
		percentage: number;
		status: { currentState: BatteryState; targetState: BatteryState | null; timeToTargetState: string | null };
	} | null;
};

export type ControlNetworkFeatureFragment = {
	controlNetwork: {
		__typename: 'DeviceControlNetwork';
		interface: { ipAddress: string | null; macAddress: string };
	} | null;
};

export type ControlNetworkFeatureChageFragment = {
	__typename: 'DeviceControlNetworkChange';
	controlNetwork: {
		__typename: 'DeviceControlNetwork';
		interface: { ipAddress: string | null; macAddress: string };
	} | null;
};

export type IdentifyFeatureFragment = { identify: { __typename: 'DeviceIdentify'; identifying: boolean } | null };

export type IdentifyFeatureChangeFragment = {
	__typename: 'DeviceIdentifyChange';
	identify: { __typename: 'DeviceIdentify'; identifying: boolean } | null;
};

export type LicenseFeatureFragment = {
	license: { __typename: 'DeviceLicense'; info: Array<DeviceLicenseInfoFragment> } | null;
};

export type LicenseFeatureChangeFragment = {
	__typename: 'DeviceLicenseChange';
	license: { __typename: 'DeviceLicense'; info: Array<DeviceLicenseInfoFragment> } | null;
};

export type LicenseV2FeatureFragment = {
	licenseV2: { __typename: 'DeviceLicenseV2'; info: Array<DeviceLicenseInfoV2Fragment> } | null;
};

export type LicenseV2FeatureChangeFragment = {
	__typename: 'DeviceLicenseChangeV2';
	licenseV2: { __typename: 'DeviceLicenseV2'; info: Array<DeviceLicenseInfoV2Fragment> } | null;
};

export type LicenseV3FeatureFragment = {
	__typename: 'DeviceFeatures';
	licenseV3: ({ __typename: 'DeviceLicenseV3' } & DeviceLicenseV3Fragment) | null;
};

export type LicenseV3FeatureChangeFragment = {
	__typename: 'DeviceLicenseChangeV3';
	licenseV3: ({ __typename: 'DeviceLicenseV3' } & DeviceLicenseV3Fragment) | null;
};

export type MicStatusFeatureFragment = { micStatus: { __typename: 'DeviceMicStatus'; status: MicStatus } | null };

export type MicStatusFeatureChangeFragment = {
	__typename: 'DeviceMicStatusChange';
	micStatus: { __typename: 'DeviceMicStatus'; status: MicStatus } | null;
};

export type NameFeatureFragment = { name: { __typename: 'DeviceName'; name: string } | null };

export type NameFeatureChangeFragment = {
	__typename: 'DeviceNameChange';
	name: { __typename: 'DeviceName'; name: string } | null;
};

export type ProxiedDevicesFeatureFragment = {
	proxiedDevices: {
		__typename: 'DeviceProxiedDevices';
		proxiedDevices: Array<{ id: string; isDevice: 'Device' }>;
	} | null;
};

export type ProxiedDevicesFeatureChangeFragment = {
	__typename: 'DeviceProxiedDevicesChange';
	proxiedDevices: {
		__typename: 'DeviceProxiedDevices';
		proxiedDevices: Array<{ id: string; isDevice: 'Device' }>;
	} | null;
};

export type UpdateProgressFragment = {
	deviceProgressPercentage: number;
	firmwarePackageKey: string;
	firmwarePackageVersion: string;
	stageProgressPercentage: number;
	updateStage: FirmwareUpdateStage;
	updateStatus: FirmwareUpdateStatus;
};

export type UpdateProgressFeatureFragment = {
	updateProgress: ({ __typename: 'DeviceUpdateProgress' } & UpdateProgressFragment) | null;
};

export type UpdateProgressFeatureChangeFragment = {
	__typename: 'DeviceUpdateProgressChange';
	updateProgress: ({ __typename: 'DeviceUpdateProgress' } & UpdateProgressFragment) | null;
};

export type RoomFeatureFragment = {
	room: { __typename: 'DeviceRoom'; room: { id: string; name: string } | null } | null;
};

export type RoomFeatureChangeFragment = {
	__typename: 'DeviceRoomChange';
	room: { __typename: 'DeviceRoom'; room: { id: string; name: string } | null } | null;
};

export type TagsFeatureFragment = { tags: { __typename: 'DeviceTags'; tags: Array<string> } | null };

export type TagsFeatureChangeFragment = {
	__typename: 'DeviceTagsChange';
	tags: { __typename: 'DeviceTags'; tags: Array<string> } | null;
};

export type AudioChannelCountFeatureFragment = {
	audioChannelCount: { __typename: 'DeviceAudioChannelCount'; count: number } | null;
};

export type AudioChannelCountChangeFragment = {
	__typename: 'DeviceAudioChannelCountChange';
	audioChannelCount: { __typename: 'DeviceAudioChannelCount'; count: number } | null;
};

export type AudioNetworkFeatureFragment = {
	audioNetwork: {
		__typename: 'DeviceAudioNetwork';
		interface: {
			gateway: string | null;
			ipAddress: string | null;
			ipMode: IpMode | null;
			macAddress: string;
			subnetMask: string | null;
		};
	} | null;
};

export type AudioNetworkChangeFragment = {
	__typename: 'DeviceAudioNetworkChange';
	audioNetwork: {
		__typename: 'DeviceAudioNetwork';
		interface: {
			gateway: string | null;
			ipAddress: string | null;
			ipMode: IpMode | null;
			macAddress: string;
			subnetMask: string | null;
		};
	} | null;
};

export type PropPanelControlNetworkFeatureFragment = {
	controlNetwork: {
		__typename: 'DeviceControlNetwork';
		interface: {
			gateway: string | null;
			ipAddress: string | null;
			ipMode: IpMode | null;
			macAddress: string;
			subnetMask: string | null;
		};
	} | null;
};

export type PropPanelControlNetworkChangeFragment = {
	__typename: 'DeviceControlNetworkChange';
	controlNetwork: {
		__typename: 'DeviceControlNetwork';
		interface: {
			gateway: string | null;
			ipAddress: string | null;
			ipMode: IpMode | null;
			macAddress: string;
			subnetMask: string | null;
		};
	} | null;
};

export type DanteAudioNetworkFeatures = {
	danteAudioNetwork: { __typename: 'DeviceDanteAudioNetwork'; name: string } | null;
};

export type DanteAudioNetworkChangeFragment = {
	__typename: 'DeviceDanteAudioNetworkChange';
	danteAudioNetwork: { __typename: 'DeviceDanteAudioNetwork'; name: string } | null;
};

export type UptimeFeatureFragment = { uptime: { __typename: 'DeviceUptime'; uptime: string } | null };

export type UptimeChangeFragment = {
	__typename: 'DeviceUptimeChange';
	uptime: { __typename: 'DeviceUptime'; uptime: string } | null;
};

export type SerialNumberFragment = { serialNumber: { __typename: 'DeviceSerialNumber'; serialNumber: string } | null };

export type GenerateDetectionTokenVariables = Exact<{ [key: string]: never }>;

export type GenerateDetectionTokenOpResult = {
	generateDetectionToken: { generatedAt: string; expiresAt: string; token: string };
};

export type InventoryDeviceFeaturesFragment = AudioMuteFeatureFragment &
	AvailablePackagesFeatureFragment &
	BatteryLevelFeatureFragment &
	ControlNetworkFeatureFragment &
	FirmwareValidFeatureFragment &
	IdentifyFeatureFragment &
	LicenseV3FeatureFragment &
	MicStatusFeatureFragment &
	NameFeatureFragment &
	ProxiedDevicesFeatureFragment &
	UpdateProgressFeatureFragment &
	RoomFeatureFragment &
	TagsFeatureFragment &
	SerialNumberFragment;

export type InventoryDeviceFragment = {
	id: string;
	hardwareId: string;
	virtual: boolean;
	status: DeviceStatus;
	compatibility: CompatibilityLevel;
	isDevice: 'Device';
	description: { features: DeviceSupportedFeaturesFragment };
	interface: { category: string; model: string | null };
	features: InventoryDeviceFeaturesFragment;
	protocol: { dmp: { ipAddress: string } | null };
};

export type InventoryDeviceUpdateFragment = {
	id: string;
	features:
		| AudioMuteFeatureChangeFragment
		| AvailablePackagesFeatureChangeFragment
		| BatteryLevelFeatureChangeFragment
		| ControlNetworkFeatureChageFragment
		| IdentifyFeatureChangeFragment
		| LicenseV3FeatureChangeFragment
		| MicStatusFeatureChangeFragment
		| NameFeatureChangeFragment
		| ProxiedDevicesFeatureChangeFragment
		| RoomFeatureChangeFragment
		| TagsFeatureChangeFragment
		| UpdateProgressFeatureChangeFragment
		| {};
};

export type InventoryDeviceByIdQueryVariables = Exact<{
	nodeId: Scalars['ID']['input'];
	requestLicenseV3: Scalars['Boolean']['input'];
}>;

export type InventoryDeviceByIdQueryOpResult = { node: InventoryDeviceFragment | {} };

export type InventoryDevicesByIdQueryVariables = Exact<{
	nodeIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
	requestLicenseV3: Scalars['Boolean']['input'];
}>;

export type InventoryDevicesByIdQueryOpResult = { nodes: Array<InventoryDeviceFragment | {} | null> };

export type InventoryDeviceSubscriptionVariables = Exact<{
	id: Scalars['ID']['input'];
	types: Array<NodeChangeType> | NodeChangeType;
	requestLicenseV3: Scalars['Boolean']['input'];
}>;

export type InventoryDeviceSubscriptionOpResult = {
	nodeChanges: InventoryDeviceFragment | InventoryDeviceUpdateFragment | { id: string; status: DeviceStatus } | {};
};

export type DetectedDevicesFragment = {
	id: string;
	hardwareId: string;
	category: string;
	model: string;
	serialNumber: string;
	macAddress: string;
	firmwareVersion: string;
	firmwareValid: boolean;
	name: string;
	ipAddress: string;
	isDetectedDevice: 'DetectedDevice';
	interface: { interfaceId: number; version: string; beta: boolean };
};

export type DetectedDeviceVariables = Exact<{
	tokenId: Scalars['Guid']['input'];
}>;

export type DetectedDeviceOpResult = {
	detectedDevices: Array<{
		detectionToken: string;
		detectedAt: string;
		expiresAt: string;
		claimable: boolean;
		device: DetectedDevicesFragment;
	}>;
};

export const ClaimedDeviceFragment = gql`
	fragment ClaimedDeviceFragment on Device {
		isDevice: __typename
		id
		hardwareId
		status
		description {
			model
		}
	}
`;
export const CloudPropertyPanelRfChannelFragment = gql`
	fragment CloudPropertyPanelRfChannelFragment on RfChannel {
		__typename
		id
		description {
			features {
				linkedTransmitter {
					supported
				}
			}
		}
		features {
			linkedTransmitter {
				linkage {
					device {
						id
					}
				}
			}
		}
	}
`;
export const CloudPropertyPanelRfChannelUpdateFragment = gql`
	fragment CloudPropertyPanelRfChannelUpdateFragment on RfChannelChange {
		__typename
		id
		features {
			... on RfChannelLinkedTransmitterChange {
				linkedTransmitter {
					linkage {
						device {
							id
						}
					}
				}
			}
		}
	}
`;
export const DeviceLicenseInfoFragment = gql`
	fragment DeviceLicenseInfoFragment on DeviceLicenseInfo {
		count
		expirationDate
		featureName
		perpetual
		state
	}
`;
export const DeviceLicenseInfoV2Fragment = gql`
	fragment DeviceLicenseInfoV2Fragment on DeviceLicenseInfoV2 {
		count
		expirationDate
		featureName
		perpetual
		state
	}
`;
export const DeviceLicenseV3Fragment = gql`
	fragment DeviceLicenseV3Fragment on DeviceLicenseV3 {
		info {
			count
			expirationDate
			featureName
			perpetual
			state
		}
		systemStatus {
			communication {
				state
			}
			ownershipMatch
		}
	}
`;
export const PropertyPanelRfChannelLinkedDeviceFragment = gql`
	fragment PropertyPanelRfChannelLinkedDeviceFragment on Device {
		isDeviceNode: __typename
		id
		status
		interface {
			category
			model
		}
		description {
			features {
				audioMute {
					supported
				}
				batteryLevel {
					supported
				}
				identify {
					supported
				}
				micStatus {
					supported
				}
				name {
					supported
				}
			}
		}
		features {
			audioMute {
				muted
			}
			batteryLevel {
				percentage
				status {
					currentState
					targetState
					timeToTargetState
				}
			}
			identify {
				identifying
			}
			micStatus {
				status
			}
			name {
				name
			}
		}
	}
`;
export const PropertyPanelRfChannelFragment = gql`
	fragment PropertyPanelRfChannelFragment on RfChannel {
		id
		description {
			features {
				linkedTransmitter {
					supported
				}
			}
		}
		features {
			linkedTransmitter {
				linkage {
					device {
						...PropertyPanelRfChannelLinkedDeviceFragment
					}
				}
			}
		}
	}
	${PropertyPanelRfChannelLinkedDeviceFragment}
`;
export const PropertyPanelDeviceFragment = gql`
	fragment PropertyPanelDeviceFragment on Device {
		isDeviceNode: __typename
		id
		virtual
		status
		compatibility
		protocol {
			dmp {
				ipAddress
			}
		}
		interface {
			category
			model
		}
		description {
			features {
				audioChannelCount {
					supported
				}
				audioMute {
					supported
				}
				audioNetwork {
					supported
				}
				availablePackages {
					supported
				}
				batteryLevel {
					supported
				}
				controlNetwork {
					supported
				}
				danteAudioNetwork {
					supported
				}
				firmware {
					supported
				}
				identify {
					supported
				}
				license {
					supported
				}
				licenseV2 {
					supported
				}
				licenseV3 @include(if: $requestLicenseV3) {
					supported
				}
				micStatus {
					supported
				}
				reboot {
					supported
				}
				rfChannels {
					supported
				}
				serialNumber {
					supported
				}
				updateProgress {
					supported
				}
				uptime {
					supported
				}
				tags {
					supported
				}
			}
			constraints {
				name {
					name {
						pattern
					}
				}
			}
		}
		features {
			audioChannelCount {
				count
			}
			audioMute {
				muted
			}
			audioNetwork {
				interface {
					gateway
					ipAddress
					ipMode
					macAddress
					subnetMask
				}
			}
			availablePackages {
				primaryPackages {
					key
					version
				}
			}
			batteryLevel {
				percentage
				status {
					currentState
					targetState
					timeToTargetState
				}
			}
			controlNetwork {
				interface {
					gateway
					ipAddress
					ipMode
					macAddress
					subnetMask
				}
			}
			danteAudioNetwork {
				name
			}
			firmware {
				valid
				version
			}
			identify {
				identifying
			}
			license {
				info {
					...DeviceLicenseInfoFragment
				}
			}
			licenseV2 {
				info {
					...DeviceLicenseInfoV2Fragment
				}
			}
			licenseV3 @include(if: $requestLicenseV3) {
				...DeviceLicenseV3Fragment
			}
			micStatus {
				status
			}
			name {
				name
			}
			rfChannels {
				rfChannels {
					...PropertyPanelRfChannelFragment
				}
			}
			serialNumber {
				serialNumber
			}
			updateProgress {
				updateStage
				updateStatus
			}
			uptime {
				uptime
			}
			tags {
				tags
			}
		}
	}
	${DeviceLicenseInfoFragment}
	${DeviceLicenseInfoV2Fragment}
	${DeviceLicenseV3Fragment}
	${PropertyPanelRfChannelFragment}
`;
export const AudioChannelCountChangeFragment = gql`
	fragment AudioChannelCountChangeFragment on DeviceAudioChannelCountChange {
		__typename
		audioChannelCount {
			__typename
			count
		}
	}
`;
export const AudioMuteFeatureChangeFragment = gql`
	fragment AudioMuteFeatureChangeFragment on DeviceAudioMuteChange {
		__typename
		audioMute {
			__typename
			muted
		}
	}
`;
export const AudioNetworkChangeFragment = gql`
	fragment AudioNetworkChangeFragment on DeviceAudioNetworkChange {
		__typename
		audioNetwork {
			__typename
			interface {
				gateway
				ipAddress
				ipMode
				macAddress
				subnetMask
			}
		}
	}
`;
export const AvailablePackagesFeatureChangeFragment = gql`
	fragment AvailablePackagesFeatureChangeFragment on DeviceAvailablePackagesChange {
		__typename
		availablePackages {
			__typename
			primaryPackages {
				key
				version
			}
		}
	}
`;
export const DanteAudioNetworkChangeFragment = gql`
	fragment DanteAudioNetworkChangeFragment on DeviceDanteAudioNetworkChange {
		__typename
		danteAudioNetwork {
			__typename
			name
		}
	}
`;
export const BatteryLevelFeatureChangeFragment = gql`
	fragment BatteryLevelFeatureChangeFragment on DeviceBatteryLevelChange {
		__typename
		batteryLevel {
			__typename
			percentage
			status {
				currentState
				targetState
				timeToTargetState
			}
		}
	}
`;
export const PropPanelControlNetworkChangeFragment = gql`
	fragment PropPanelControlNetworkChangeFragment on DeviceControlNetworkChange {
		__typename
		controlNetwork {
			__typename
			interface {
				gateway
				ipAddress
				ipMode
				macAddress
				subnetMask
			}
		}
	}
`;
export const IdentifyFeatureChangeFragment = gql`
	fragment IdentifyFeatureChangeFragment on DeviceIdentifyChange {
		__typename
		identify {
			__typename
			identifying
		}
	}
`;
export const LicenseFeatureChangeFragment = gql`
	fragment LicenseFeatureChangeFragment on DeviceLicenseChange {
		__typename
		license {
			__typename
			info {
				...DeviceLicenseInfoFragment
			}
		}
	}
	${DeviceLicenseInfoFragment}
`;
export const LicenseV2FeatureChangeFragment = gql`
	fragment LicenseV2FeatureChangeFragment on DeviceLicenseChangeV2 {
		__typename
		licenseV2 {
			__typename
			info {
				...DeviceLicenseInfoV2Fragment
			}
		}
	}
	${DeviceLicenseInfoV2Fragment}
`;
export const LicenseV3FeatureChangeFragment = gql`
	fragment LicenseV3FeatureChangeFragment on DeviceLicenseChangeV3 {
		__typename
		licenseV3 {
			__typename
			...DeviceLicenseV3Fragment
		}
	}
	${DeviceLicenseV3Fragment}
`;
export const MicStatusFeatureChangeFragment = gql`
	fragment MicStatusFeatureChangeFragment on DeviceMicStatusChange {
		__typename
		micStatus {
			__typename
			status
		}
	}
`;
export const NameFeatureChangeFragment = gql`
	fragment NameFeatureChangeFragment on DeviceNameChange {
		__typename
		name {
			__typename
			name
		}
	}
`;
export const TagsFeatureChangeFragment = gql`
	fragment TagsFeatureChangeFragment on DeviceTagsChange {
		__typename
		tags {
			__typename
			tags
		}
	}
`;
export const UptimeChangeFragment = gql`
	fragment UptimeChangeFragment on DeviceUptimeChange {
		__typename
		uptime {
			__typename
			uptime
		}
	}
`;
export const PropertyPanelDeviceUpdateFragment = gql`
	fragment PropertyPanelDeviceUpdateFragment on DeviceChange {
		id
		features {
			...AudioChannelCountChangeFragment
			...AudioMuteFeatureChangeFragment
			...AudioNetworkChangeFragment
			...AvailablePackagesFeatureChangeFragment
			...DanteAudioNetworkChangeFragment
			...BatteryLevelFeatureChangeFragment
			...PropPanelControlNetworkChangeFragment
			...IdentifyFeatureChangeFragment
			...LicenseFeatureChangeFragment
			...LicenseV2FeatureChangeFragment
			...LicenseV3FeatureChangeFragment @include(if: $requestLicenseV3)
			...MicStatusFeatureChangeFragment
			...NameFeatureChangeFragment
			...TagsFeatureChangeFragment
			...UptimeChangeFragment
		}
	}
	${AudioChannelCountChangeFragment}
	${AudioMuteFeatureChangeFragment}
	${AudioNetworkChangeFragment}
	${AvailablePackagesFeatureChangeFragment}
	${DanteAudioNetworkChangeFragment}
	${BatteryLevelFeatureChangeFragment}
	${PropPanelControlNetworkChangeFragment}
	${IdentifyFeatureChangeFragment}
	${LicenseFeatureChangeFragment}
	${LicenseV2FeatureChangeFragment}
	${LicenseV3FeatureChangeFragment}
	${MicStatusFeatureChangeFragment}
	${NameFeatureChangeFragment}
	${TagsFeatureChangeFragment}
	${UptimeChangeFragment}
`;
export const DiscoveryDeviceFragment = gql`
	fragment DiscoveryDeviceFragment on Device {
		isDevice: __typename
		id
	}
`;
export const LicenseFeatureFragment = gql`
	fragment LicenseFeatureFragment on DeviceFeatures {
		license {
			__typename
			info {
				...DeviceLicenseInfoFragment
			}
		}
	}
	${DeviceLicenseInfoFragment}
`;
export const LicenseV2FeatureFragment = gql`
	fragment LicenseV2FeatureFragment on DeviceFeatures {
		licenseV2 {
			__typename
			info {
				...DeviceLicenseInfoV2Fragment
			}
		}
	}
	${DeviceLicenseInfoV2Fragment}
`;
export const AudioChannelCountFeatureFragment = gql`
	fragment AudioChannelCountFeatureFragment on DeviceFeatures {
		audioChannelCount {
			__typename
			count
		}
	}
`;
export const AudioNetworkFeatureFragment = gql`
	fragment AudioNetworkFeatureFragment on DeviceFeatures {
		audioNetwork {
			__typename
			interface {
				gateway
				ipAddress
				ipMode
				macAddress
				subnetMask
			}
		}
	}
`;
export const PropPanelControlNetworkFeatureFragment = gql`
	fragment PropPanelControlNetworkFeatureFragment on DeviceFeatures {
		controlNetwork {
			__typename
			interface {
				gateway
				ipAddress
				ipMode
				macAddress
				subnetMask
			}
		}
	}
`;
export const DanteAudioNetworkFeatures = gql`
	fragment DanteAudioNetworkFeatures on DeviceFeatures {
		danteAudioNetwork {
			__typename
			name
		}
	}
`;
export const UptimeFeatureFragment = gql`
	fragment UptimeFeatureFragment on DeviceFeatures {
		uptime {
			__typename
			uptime
		}
	}
`;
export const DeviceSupportedFeaturesFragment = gql`
	fragment DeviceSupportedFeaturesFragment on DeviceSupportedFeatures {
		audioMute {
			supported
		}
		audioNetwork {
			supported
		}
		availablePackages {
			supported
		}
		batteryLevel {
			supported
		}
		controlNetwork {
			supported
		}
		danteAudioNetwork {
			supported
		}
		firmware {
			supported
		}
		identify {
			supported
		}
		licenseV3 @include(if: $requestLicenseV3) {
			supported
		}
		micStatus {
			supported
		}
		name {
			supported
		}
		proxiedDevices {
			supported
		}
		rfChannels {
			supported
		}
		serialNumber {
			supported
		}
		uptime {
			supported
		}
		updateProgress {
			supported
		}
		room {
			supported
		}
		tags {
			supported
		}
	}
`;
export const AudioMuteFeatureFragment = gql`
	fragment AudioMuteFeatureFragment on DeviceFeatures {
		audioMute {
			__typename
			muted
		}
	}
`;
export const AvailablePackagesFeatureFragment = gql`
	fragment AvailablePackagesFeatureFragment on DeviceFeatures {
		availablePackages {
			__typename
			primaryPackages {
				key
				version
			}
		}
	}
`;
export const BatteryLevelFeatureFragment = gql`
	fragment BatteryLevelFeatureFragment on DeviceFeatures {
		batteryLevel {
			__typename
			percentage
			status {
				currentState
				targetState
				timeToTargetState
			}
		}
	}
`;
export const ControlNetworkFeatureFragment = gql`
	fragment ControlNetworkFeatureFragment on DeviceFeatures {
		controlNetwork {
			__typename
			interface {
				ipAddress
				macAddress
			}
		}
	}
`;
export const FirmwareValidFeatureFragment = gql`
	fragment FirmwareValidFeatureFragment on DeviceFeatures {
		firmware {
			__typename
			valid
			version
		}
	}
`;
export const IdentifyFeatureFragment = gql`
	fragment IdentifyFeatureFragment on DeviceFeatures {
		identify {
			__typename
			identifying
		}
	}
`;
export const LicenseV3FeatureFragment = gql`
	fragment LicenseV3FeatureFragment on DeviceFeatures {
		__typename
		licenseV3 {
			__typename
			...DeviceLicenseV3Fragment
		}
	}
	${DeviceLicenseV3Fragment}
`;
export const MicStatusFeatureFragment = gql`
	fragment MicStatusFeatureFragment on DeviceFeatures {
		micStatus {
			__typename
			status
		}
	}
`;
export const NameFeatureFragment = gql`
	fragment NameFeatureFragment on DeviceFeatures {
		name {
			__typename
			name
		}
	}
`;
export const ProxiedDevicesFeatureFragment = gql`
	fragment ProxiedDevicesFeatureFragment on DeviceFeatures {
		proxiedDevices {
			__typename
			proxiedDevices {
				isDevice: __typename
				id
			}
		}
	}
`;
export const UpdateProgressFragment = gql`
	fragment UpdateProgressFragment on DeviceUpdateProgress {
		deviceProgressPercentage
		firmwarePackageKey
		firmwarePackageVersion
		stageProgressPercentage
		updateStage
		updateStatus
	}
`;
export const UpdateProgressFeatureFragment = gql`
	fragment UpdateProgressFeatureFragment on DeviceFeatures {
		updateProgress {
			__typename
			...UpdateProgressFragment
		}
	}
	${UpdateProgressFragment}
`;
export const RoomFeatureFragment = gql`
	fragment RoomFeatureFragment on DeviceFeatures {
		room {
			__typename
			room {
				id
				name
			}
		}
	}
`;
export const TagsFeatureFragment = gql`
	fragment TagsFeatureFragment on DeviceFeatures {
		tags {
			__typename
			tags
		}
	}
`;
export const SerialNumberFragment = gql`
	fragment SerialNumberFragment on DeviceFeatures {
		serialNumber {
			__typename
			serialNumber
		}
	}
`;
export const InventoryDeviceFeaturesFragment = gql`
	fragment InventoryDeviceFeaturesFragment on DeviceFeatures {
		...AudioMuteFeatureFragment
		...AvailablePackagesFeatureFragment
		...BatteryLevelFeatureFragment
		...ControlNetworkFeatureFragment
		...FirmwareValidFeatureFragment
		...IdentifyFeatureFragment
		...LicenseV3FeatureFragment
		...MicStatusFeatureFragment
		...NameFeatureFragment
		...ProxiedDevicesFeatureFragment
		...UpdateProgressFeatureFragment
		...RoomFeatureFragment
		...TagsFeatureFragment
		...SerialNumberFragment
	}
	${AudioMuteFeatureFragment}
	${AvailablePackagesFeatureFragment}
	${BatteryLevelFeatureFragment}
	${ControlNetworkFeatureFragment}
	${FirmwareValidFeatureFragment}
	${IdentifyFeatureFragment}
	${LicenseV3FeatureFragment}
	${MicStatusFeatureFragment}
	${NameFeatureFragment}
	${ProxiedDevicesFeatureFragment}
	${UpdateProgressFeatureFragment}
	${RoomFeatureFragment}
	${TagsFeatureFragment}
	${SerialNumberFragment}
`;
export const InventoryDeviceFragment = gql`
	fragment InventoryDeviceFragment on Device {
		isDevice: __typename
		id
		hardwareId
		virtual
		status
		compatibility
		description {
			features {
				...DeviceSupportedFeaturesFragment
			}
		}
		interface {
			category
			model
		}
		features {
			...InventoryDeviceFeaturesFragment
		}
		protocol {
			dmp {
				ipAddress
			}
		}
	}
	${DeviceSupportedFeaturesFragment}
	${InventoryDeviceFeaturesFragment}
`;
export const ControlNetworkFeatureChageFragment = gql`
	fragment ControlNetworkFeatureChageFragment on DeviceControlNetworkChange {
		__typename
		controlNetwork {
			__typename
			interface {
				ipAddress
				macAddress
			}
		}
	}
`;
export const ProxiedDevicesFeatureChangeFragment = gql`
	fragment ProxiedDevicesFeatureChangeFragment on DeviceProxiedDevicesChange {
		__typename
		proxiedDevices {
			__typename
			proxiedDevices {
				isDevice: __typename
				id
			}
		}
	}
`;
export const UpdateProgressFeatureChangeFragment = gql`
	fragment UpdateProgressFeatureChangeFragment on DeviceUpdateProgressChange {
		__typename
		updateProgress {
			__typename
			...UpdateProgressFragment
		}
	}
	${UpdateProgressFragment}
`;
export const RoomFeatureChangeFragment = gql`
	fragment RoomFeatureChangeFragment on DeviceRoomChange {
		__typename
		room {
			__typename
			room {
				id
				name
			}
		}
	}
`;
export const InventoryDeviceUpdateFragment = gql`
	fragment InventoryDeviceUpdateFragment on DeviceChange {
		id
		features {
			...AudioMuteFeatureChangeFragment
			...AvailablePackagesFeatureChangeFragment
			...BatteryLevelFeatureChangeFragment
			...ControlNetworkFeatureChageFragment
			...IdentifyFeatureChangeFragment
			...LicenseV3FeatureChangeFragment @include(if: $requestLicenseV3)
			...MicStatusFeatureChangeFragment
			...NameFeatureChangeFragment
			...ProxiedDevicesFeatureChangeFragment
			...UpdateProgressFeatureChangeFragment
			...RoomFeatureChangeFragment
			...TagsFeatureChangeFragment
		}
	}
	${AudioMuteFeatureChangeFragment}
	${AvailablePackagesFeatureChangeFragment}
	${BatteryLevelFeatureChangeFragment}
	${ControlNetworkFeatureChageFragment}
	${IdentifyFeatureChangeFragment}
	${LicenseV3FeatureChangeFragment}
	${MicStatusFeatureChangeFragment}
	${NameFeatureChangeFragment}
	${ProxiedDevicesFeatureChangeFragment}
	${UpdateProgressFeatureChangeFragment}
	${RoomFeatureChangeFragment}
	${TagsFeatureChangeFragment}
`;
export const DetectedDevicesFragment = gql`
	fragment DetectedDevicesFragment on DetectedDevice {
		isDetectedDevice: __typename
		id
		hardwareId
		interface {
			interfaceId
			version
			beta
		}
		category
		model
		serialNumber
		macAddress
		firmwareVersion
		firmwareValid
		name
		ipAddress
	}
`;
export const ClaimDeviceDocument = gql`
	mutation ClaimDevice($deviceNodeId: ID!, $tokenId: Guid!) {
		claimDevice(id: $deviceNodeId, tokenId: $tokenId)
	}
`;

@Injectable({
	providedIn: 'root'
})
export class ClaimDeviceGQL extends Apollo.Mutation<ClaimDeviceOpResult, ClaimDeviceVariables> {
	override document = ClaimDeviceDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const UnclaimDeviceDocument = gql`
	mutation UnclaimDevice($deviceNodeId: ID!) {
		unclaimDevice(id: $deviceNodeId)
	}
`;

@Injectable({
	providedIn: 'root'
})
export class UnclaimDeviceGQL extends Apollo.Mutation<UnclaimDeviceOpResult, UnclaimDeviceVariables> {
	override document = UnclaimDeviceDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const ClaimDeviceByMacSerialNumberDocument = gql`
	mutation ClaimDeviceByMacSerialNumber($claimRequests: [MacAndSerialClaimInput!]!) {
		claimDeviceUsingMacAndSerial(claims: $claimRequests) {
			macAndSerialClaim {
				macAddress
				serialNumber
			}
			error {
				code
				message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class ClaimDeviceByMacSerialNumberGQL extends Apollo.Mutation<
	ClaimDeviceByMacSerialNumberOpResult,
	ClaimDeviceByMacSerialNumberVariables
> {
	override document = ClaimDeviceByMacSerialNumberDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const ClaimedDevicesSubscriptionDocument = gql`
	subscription ClaimedDevicesSubscription {
		claimedDevices {
			... on DeviceClaimed {
				claimed {
					...ClaimedDeviceFragment
				}
			}
			... on DeviceUnclaimed {
				unclaimed
			}
		}
	}
	${ClaimedDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class ClaimedDevicesSubscriptionGQL extends Apollo.Subscription<
	ClaimedDevicesSubscriptionOpResult,
	ClaimedDevicesSubscriptionVariables
> {
	override document = ClaimedDevicesSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeletePendingClaimDocument = gql`
	mutation DeletePendingClaim($pendingClaimId: ID!) {
		deletePendingDeviceClaim(id: $pendingClaimId) {
			id
			error {
				code
				message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeletePendingClaimGQL extends Apollo.Mutation<DeletePendingClaimOpResult, DeletePendingClaimVariables> {
	override document = DeletePendingClaimDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const ListPendingClaimsDocument = gql`
	query ListPendingClaims {
		pendingDeviceClaims {
			id
			macAddress
			serialNumber
			createdAt
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class ListPendingClaimsGQL extends Apollo.Query<ListPendingClaimsOpResult, ListPendingClaimsVariables> {
	override document = ListPendingClaimsDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const PendingClaimStatusDocument = gql`
	subscription PendingClaimStatus {
		pendingClaims {
			id
			serialNumber
			macAddress
			status
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class PendingClaimStatusGQL extends Apollo.Subscription<
	PendingClaimStatusOpResult,
	PendingClaimStatusVariables
> {
	override document = PendingClaimStatusDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const OnDetectedDevicesDocument = gql`
	subscription OnDetectedDevices($tokenId: Guid!) {
		detectedDevices(tokenId: $tokenId) {
			device {
				id
				hardwareId
				interface {
					interfaceId
					version
					beta
				}
				category
				model
				serialNumber
				macAddress
				firmwareVersion
				firmwareValid
				name
				ipAddress
			}
			detectionToken
			detectedAt
			expiresAt
			claimable
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class OnDetectedDevicesGQL extends Apollo.Subscription<OnDetectedDevicesOpResult, OnDetectedDevicesVariables> {
	override document = OnDetectedDevicesDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const OrgTagsQueryDocument = gql`
	query OrgTagsQuery {
		tags
	}
`;

@Injectable({
	providedIn: 'root'
})
export class OrgTagsQueryGQL extends Apollo.Query<OrgTagsQueryOpResult, OrgTagsQueryVariables> {
	override document = OrgTagsQueryDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const OrgTagsSubscriptionDocument = gql`
	subscription OrgTagsSubscription {
		tags {
			__typename
			... on TagAddedEvent {
				__typename
				added
			}
			... on TagRemovedEvent {
				__typename
				removed
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class OrgTagsSubscriptionGQL extends Apollo.Subscription<
	OrgTagsSubscriptionOpResult,
	OrgTagsSubscriptionVariables
> {
	override document = OrgTagsSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const AssociateTagMutationDocument = gql`
	mutation AssociateTagMutation($tagInput: TagAssociationInput!) {
		associateTag(input: $tagInput) {
			... on TagAssociationResult {
				device
				status
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class AssociateTagMutationGQL extends Apollo.Mutation<
	AssociateTagMutationOpResult,
	AssociateTagMutationVariables
> {
	override document = AssociateTagMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DissociateTagMutationDocument = gql`
	mutation DissociateTagMutation($tagInput: TagDissociationInput!) {
		dissociateTag(input: $tagInput) {
			... on TagDissociationResult {
				device
				status
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DissociateTagMutationGQL extends Apollo.Mutation<
	DissociateTagMutationOpResult,
	DissociateTagMutationVariables
> {
	override document = DissociateTagMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionDocument = gql`
	subscription CloudPropertyPanelRfChannelLinkedTransmitterSubscription(
		$rfChannelNodeId: ID!
		$rfChannelNodeChangeType: [NodeChangeType!]!
	) {
		nodeChanges(id: $rfChannelNodeId, types: $rfChannelNodeChangeType) {
			...CloudPropertyPanelRfChannelFragment
			...CloudPropertyPanelRfChannelUpdateFragment
		}
	}
	${CloudPropertyPanelRfChannelFragment}
	${CloudPropertyPanelRfChannelUpdateFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionGQL extends Apollo.Subscription<
	CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionOpResult,
	CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionVariables
> {
	override document = CloudPropertyPanelRfChannelLinkedTransmitterSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const PropertyPanelDeviceQueryDocument = gql`
	query PropertyPanelDeviceQuery($nodeId: ID!, $requestLicenseV3: Boolean!) {
		node(id: $nodeId) {
			...PropertyPanelDeviceFragment
		}
	}
	${PropertyPanelDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class PropertyPanelDeviceQueryGQL extends Apollo.Query<
	PropertyPanelDeviceQueryOpResult,
	PropertyPanelDeviceQueryVariables
> {
	override document = PropertyPanelDeviceQueryDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const PropertyPanelDeviceSubscriptionDocument = gql`
	subscription PropertyPanelDeviceSubscription($id: ID!, $types: [NodeChangeType!]!, $requestLicenseV3: Boolean!) {
		nodeChanges(id: $id, types: $types) {
			... on DeviceStatusChange {
				id
				status
			}
			...PropertyPanelDeviceFragment
			...PropertyPanelDeviceUpdateFragment
		}
	}
	${PropertyPanelDeviceFragment}
	${PropertyPanelDeviceUpdateFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class PropertyPanelDeviceSubscriptionGQL extends Apollo.Subscription<
	PropertyPanelDeviceSubscriptionOpResult,
	PropertyPanelDeviceSubscriptionVariables
> {
	override document = PropertyPanelDeviceSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeviceStartIdentifyMutationDocument = gql`
	mutation DeviceStartIdentifyMutation($startIdentifyId: ID!) {
		startIdentify(id: $startIdentifyId) {
			error {
				code
				message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeviceStartIdentifyMutationGQL extends Apollo.Mutation<
	DeviceStartIdentifyMutationOpResult,
	DeviceStartIdentifyMutationVariables
> {
	override document = DeviceStartIdentifyMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeviceStopIdentifyMutationDocument = gql`
	mutation DeviceStopIdentifyMutation($stopIdentifyId: ID!) {
		stopIdentify(id: $stopIdentifyId) {
			error {
				code
				message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeviceStopIdentifyMutationGQL extends Apollo.Mutation<
	DeviceStopIdentifyMutationOpResult,
	DeviceStopIdentifyMutationVariables
> {
	override document = DeviceStopIdentifyMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeviceRebootMutationDocument = gql`
	mutation DeviceRebootMutation($rebootDeviceId: ID!) {
		rebootDevice(id: $rebootDeviceId) {
			error {
				code
				message
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeviceRebootMutationGQL extends Apollo.Mutation<
	DeviceRebootMutationOpResult,
	DeviceRebootMutationVariables
> {
	override document = DeviceRebootMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeviceUpdateMutationDocument = gql`
	mutation DeviceUpdateMutation($updates: [NodeUpdateInput!]!) {
		updateNodes(updates: $updates) {
			... on DeviceOperationResult {
				error {
					code
					message
				}
			}
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeviceUpdateMutationGQL extends Apollo.Mutation<
	DeviceUpdateMutationOpResult,
	DeviceUpdateMutationVariables
> {
	override document = DeviceUpdateMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DeviceUpdateFirmwareMutationDocument = gql`
	mutation DeviceUpdateFirmwareMutation($input: [UpdateDeviceFirmwareInput!]!) {
		updateFirmware(input: $input) {
			requestId
			state
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class DeviceUpdateFirmwareMutationGQL extends Apollo.Mutation<
	DeviceUpdateFirmwareMutationOpResult,
	DeviceUpdateFirmwareMutationVariables
> {
	override document = DeviceUpdateFirmwareMutationDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DiscoveredDevicesQueryDocument = gql`
	query DiscoveredDevicesQuery($first: Int, $after: String, $deviceModels: [String!]) {
		discoveredDevicesConnection(first: $first, after: $after, deviceModels: $deviceModels) {
			pageInfo {
				hasNextPage
				endCursor
			}
			edges {
				node {
					...DiscoveryDeviceFragment
				}
			}
		}
	}
	${DiscoveryDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class DiscoveredDevicesQueryGQL extends Apollo.Query<
	DiscoveredDevicesQueryOpResult,
	DiscoveredDevicesQueryVariables
> {
	override document = DiscoveredDevicesQueryDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DiscoveredDevicesSubscriptionDocument = gql`
	subscription DiscoveredDevicesSubscription {
		discoveredDevices {
			... on DeviceAdded {
				added {
					...DiscoveryDeviceFragment
				}
			}
			... on DeviceRemoved {
				removed
			}
		}
	}
	${DiscoveryDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class DiscoveredDevicesSubscriptionGQL extends Apollo.Subscription<
	DiscoveredDevicesSubscriptionOpResult,
	DiscoveredDevicesSubscriptionVariables
> {
	override document = DiscoveredDevicesSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DiscoveredDeviceSubscriptionDocument = gql`
	subscription DiscoveredDeviceSubscription($id: ID!, $types: [NodeChangeType!]!) {
		nodeChanges(id: $id, types: $types) {
			...DiscoveryDeviceFragment
		}
	}
	${DiscoveryDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class DiscoveredDeviceSubscriptionGQL extends Apollo.Subscription<
	DiscoveredDeviceSubscriptionOpResult,
	DiscoveredDeviceSubscriptionVariables
> {
	override document = DiscoveredDeviceSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const GenerateDetectionTokenDocument = gql`
	mutation GenerateDetectionToken {
		generateDetectionToken {
			generatedAt
			expiresAt
			token
		}
	}
`;

@Injectable({
	providedIn: 'root'
})
export class GenerateDetectionTokenGQL extends Apollo.Mutation<
	GenerateDetectionTokenOpResult,
	GenerateDetectionTokenVariables
> {
	override document = GenerateDetectionTokenDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const InventoryDeviceByIdQueryDocument = gql`
	query InventoryDeviceByIdQuery($nodeId: ID!, $requestLicenseV3: Boolean!) {
		node(id: $nodeId) {
			...InventoryDeviceFragment
		}
	}
	${InventoryDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class InventoryDeviceByIdQueryGQL extends Apollo.Query<
	InventoryDeviceByIdQueryOpResult,
	InventoryDeviceByIdQueryVariables
> {
	override document = InventoryDeviceByIdQueryDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const InventoryDevicesByIdQueryDocument = gql`
	query InventoryDevicesByIdQuery($nodeIds: [ID!]!, $requestLicenseV3: Boolean!) {
		nodes(ids: $nodeIds) {
			...InventoryDeviceFragment
		}
	}
	${InventoryDeviceFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class InventoryDevicesByIdQueryGQL extends Apollo.Query<
	InventoryDevicesByIdQueryOpResult,
	InventoryDevicesByIdQueryVariables
> {
	override document = InventoryDevicesByIdQueryDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const InventoryDeviceSubscriptionDocument = gql`
	subscription InventoryDeviceSubscription($id: ID!, $types: [NodeChangeType!]!, $requestLicenseV3: Boolean!) {
		nodeChanges(id: $id, types: $types) {
			... on DeviceStatusChange {
				id
				status
			}
			...InventoryDeviceFragment
			...InventoryDeviceUpdateFragment
		}
	}
	${InventoryDeviceFragment}
	${InventoryDeviceUpdateFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class InventoryDeviceSubscriptionGQL extends Apollo.Subscription<
	InventoryDeviceSubscriptionOpResult,
	InventoryDeviceSubscriptionVariables
> {
	override document = InventoryDeviceSubscriptionDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const DetectedDeviceDocument = gql`
	query DetectedDevice($tokenId: Guid!) {
		detectedDevices(tokenId: $tokenId) {
			device {
				...DetectedDevicesFragment
			}
			detectionToken
			detectedAt
			expiresAt
			claimable
		}
	}
	${DetectedDevicesFragment}
`;

@Injectable({
	providedIn: 'root'
})
export class DetectedDeviceGQL extends Apollo.Query<DetectedDeviceOpResult, DetectedDeviceVariables> {
	override document = DetectedDeviceDocument;
	override client = 'sysApi';
	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const ListAllOperations = {
	Query: {
		ListPendingClaims: 'ListPendingClaims',
		OrgTagsQuery: 'OrgTagsQuery',
		PropertyPanelDeviceQuery: 'PropertyPanelDeviceQuery',
		DiscoveredDevicesQuery: 'DiscoveredDevicesQuery',
		InventoryDeviceByIdQuery: 'InventoryDeviceByIdQuery',
		InventoryDevicesByIdQuery: 'InventoryDevicesByIdQuery',
		DetectedDevice: 'DetectedDevice'
	},
	Mutation: {
		ClaimDevice: 'ClaimDevice',
		UnclaimDevice: 'UnclaimDevice',
		ClaimDeviceByMacSerialNumber: 'ClaimDeviceByMacSerialNumber',
		DeletePendingClaim: 'DeletePendingClaim',
		AssociateTagMutation: 'AssociateTagMutation',
		DissociateTagMutation: 'DissociateTagMutation',
		DeviceStartIdentifyMutation: 'DeviceStartIdentifyMutation',
		DeviceStopIdentifyMutation: 'DeviceStopIdentifyMutation',
		DeviceRebootMutation: 'DeviceRebootMutation',
		DeviceUpdateMutation: 'DeviceUpdateMutation',
		DeviceUpdateFirmwareMutation: 'DeviceUpdateFirmwareMutation',
		GenerateDetectionToken: 'GenerateDetectionToken'
	},
	Subscription: {
		ClaimedDevicesSubscription: 'ClaimedDevicesSubscription',
		PendingClaimStatus: 'PendingClaimStatus',
		OnDetectedDevices: 'OnDetectedDevices',
		OrgTagsSubscription: 'OrgTagsSubscription',
		CloudPropertyPanelRfChannelLinkedTransmitterSubscription:
			'CloudPropertyPanelRfChannelLinkedTransmitterSubscription',
		PropertyPanelDeviceSubscription: 'PropertyPanelDeviceSubscription',
		DiscoveredDevicesSubscription: 'DiscoveredDevicesSubscription',
		DiscoveredDeviceSubscription: 'DiscoveredDeviceSubscription',
		InventoryDeviceSubscription: 'InventoryDeviceSubscription'
	},
	Fragment: {
		ClaimedDeviceFragment: 'ClaimedDeviceFragment',
		DeviceLicenseV3Fragment: 'DeviceLicenseV3Fragment',
		CloudPropertyPanelRfChannelFragment: 'CloudPropertyPanelRfChannelFragment',
		CloudPropertyPanelRfChannelUpdateFragment: 'CloudPropertyPanelRfChannelUpdateFragment',
		DeviceLicenseInfoFragment: 'DeviceLicenseInfoFragment',
		DeviceLicenseInfoV2Fragment: 'DeviceLicenseInfoV2Fragment',
		PropertyPanelRfChannelLinkedDeviceFragment: 'PropertyPanelRfChannelLinkedDeviceFragment',
		PropertyPanelRfChannelFragment: 'PropertyPanelRfChannelFragment',
		PropertyPanelDeviceFragment: 'PropertyPanelDeviceFragment',
		PropertyPanelDeviceUpdateFragment: 'PropertyPanelDeviceUpdateFragment',
		DeviceSupportedFeaturesFragment: 'DeviceSupportedFeaturesFragment',
		DiscoveryDeviceFragment: 'DiscoveryDeviceFragment',
		AudioMuteFeatureFragment: 'AudioMuteFeatureFragment',
		AudioMuteFeatureChangeFragment: 'AudioMuteFeatureChangeFragment',
		FirmwareValidFeatureFragment: 'FirmwareValidFeatureFragment',
		AvailablePackagesFeatureFragment: 'AvailablePackagesFeatureFragment',
		AvailablePackagesFeatureChangeFragment: 'AvailablePackagesFeatureChangeFragment',
		BatteryLevelFeatureFragment: 'BatteryLevelFeatureFragment',
		BatteryLevelFeatureChangeFragment: 'BatteryLevelFeatureChangeFragment',
		ControlNetworkFeatureFragment: 'ControlNetworkFeatureFragment',
		ControlNetworkFeatureChageFragment: 'ControlNetworkFeatureChageFragment',
		IdentifyFeatureFragment: 'IdentifyFeatureFragment',
		IdentifyFeatureChangeFragment: 'IdentifyFeatureChangeFragment',
		LicenseFeatureFragment: 'LicenseFeatureFragment',
		LicenseFeatureChangeFragment: 'LicenseFeatureChangeFragment',
		LicenseV2FeatureFragment: 'LicenseV2FeatureFragment',
		LicenseV2FeatureChangeFragment: 'LicenseV2FeatureChangeFragment',
		LicenseV3FeatureFragment: 'LicenseV3FeatureFragment',
		LicenseV3FeatureChangeFragment: 'LicenseV3FeatureChangeFragment',
		MicStatusFeatureFragment: 'MicStatusFeatureFragment',
		MicStatusFeatureChangeFragment: 'MicStatusFeatureChangeFragment',
		NameFeatureFragment: 'NameFeatureFragment',
		NameFeatureChangeFragment: 'NameFeatureChangeFragment',
		ProxiedDevicesFeatureFragment: 'ProxiedDevicesFeatureFragment',
		ProxiedDevicesFeatureChangeFragment: 'ProxiedDevicesFeatureChangeFragment',
		UpdateProgressFragment: 'UpdateProgressFragment',
		UpdateProgressFeatureFragment: 'UpdateProgressFeatureFragment',
		UpdateProgressFeatureChangeFragment: 'UpdateProgressFeatureChangeFragment',
		RoomFeatureFragment: 'RoomFeatureFragment',
		RoomFeatureChangeFragment: 'RoomFeatureChangeFragment',
		TagsFeatureFragment: 'TagsFeatureFragment',
		TagsFeatureChangeFragment: 'TagsFeatureChangeFragment',
		AudioChannelCountFeatureFragment: 'AudioChannelCountFeatureFragment',
		AudioChannelCountChangeFragment: 'AudioChannelCountChangeFragment',
		AudioNetworkFeatureFragment: 'AudioNetworkFeatureFragment',
		AudioNetworkChangeFragment: 'AudioNetworkChangeFragment',
		PropPanelControlNetworkFeatureFragment: 'PropPanelControlNetworkFeatureFragment',
		PropPanelControlNetworkChangeFragment: 'PropPanelControlNetworkChangeFragment',
		DanteAudioNetworkFeatures: 'DanteAudioNetworkFeatures',
		DanteAudioNetworkChangeFragment: 'DanteAudioNetworkChangeFragment',
		UptimeFeatureFragment: 'UptimeFeatureFragment',
		UptimeChangeFragment: 'UptimeChangeFragment',
		SerialNumberFragment: 'SerialNumberFragment',
		InventoryDeviceFeaturesFragment: 'InventoryDeviceFeaturesFragment',
		InventoryDeviceFragment: 'InventoryDeviceFragment',
		InventoryDeviceUpdateFragment: 'InventoryDeviceUpdateFragment',
		DetectedDevicesFragment: 'DetectedDevicesFragment'
	}
};
